import { IAddMerchantSSLRequest, IDeleteCertificationAndPrivateKeyRequest, IDeleteMerchantSSLRequest } from "@/interfaces/Requests/Requests";
import { ISSLBindingDto } from "@/interfaces/Responses/Responses";
import { useAddCertificationAndPrivateKeyApi, useAddMerchantSSLApi, useDeleteCertificationAndPrivateKeyApi, useDeleteDeleteMerchantSSLApi, useGetSSLBindingApi } from "@/lib/api/webSiteSettings";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { UrlHelper } from "@/Templates/lib/UrlHelper";
import { CheckCircleFilled, CloseCircleFilled, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Form, Input, Modal, Popconfirm, Row, Typography, Upload } from "antd";
import FormItem from "antd/es/form/FormItem";
import axios from 'axios';
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { PortalSettingPageContext } from "../Contexts/PortalSettingPageContext";

export interface SSLSettingsProps { }

export interface SSLSettingsRef {
    onRefresh: () => void;
}

const SSLSettings = React.forwardRef((props: SSLSettingsProps, ref: React.ForwardedRef<SSLSettingsRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const abortController = useRef<AbortController | null>(null);
    const urlHelper = new UrlHelper();
    const [addform] = Form.useForm();
    const [sslBindingDtos, setSSLBindingDtos] = useState<ISSLBindingDto[]>([]);
    const [hasUploadedCrt, setHasUploadedCrt] = useState(false);
    const [hasUploadedKey, setHasUploadedKey] = useState(false);
    const [domainVlidating, setDomainVlidating] = React.useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentDomain, setCurrentDomain] = useState<string | null>(null);
    const { mutate: getSSlBindingDtosMutate, isLoading: sslSettingsIsLoading } = useMutation(async () => await useGetSSLBindingApi(abortController.current?.signal), {
        onSuccess: (data) => {
            //clear the form
            addform.resetFields();
            if (data.isSuccess && data.result) {
                setSSLBindingDtos(data.result);
            }
        }
    });
    const { mutate: addSSLBindingMutate } = useMutation(async (request: IAddMerchantSSLRequest) => await useAddMerchantSSLApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                getSSlBindingDtosMutate();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: addCertificationAndPrivateKeyMutate } = useMutation(async (request: FormData) => await useAddCertificationAndPrivateKeyApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                getSSlBindingDtosMutate();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: deleteCertificationAndPrivateKeyMutate } = useMutation(async (request: IDeleteCertificationAndPrivateKeyRequest) => await useDeleteCertificationAndPrivateKeyApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                getSSlBindingDtosMutate();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: deleteSSLBindingMutate } = useMutation(async (request: IDeleteMerchantSSLRequest) => await useDeleteDeleteMerchantSSLApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                getSSlBindingDtosMutate();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: addDomainMutate } = useMutation(async (domain: string) => await useAddMerchantSSLApi({ domain }), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('域名新增成功'));
                getSSlBindingDtosMutate();
            } else {
                messageApi.error(translate(response.message || '操作失敗'));
            }
        }
    });
    useEffect(() => {
        getSSlBindingDtosMutate();
    }, []);

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            getSSlBindingDtosMutate();
        }
    }));

    const handleAddDomain = async (values: { merchantDomain: string }) => {
        addDomainMutate(values.merchantDomain);
    };

    const handleUploadCertificate = async (values: any) => {
        const formData = new FormData();
        formData.append('Domain', currentDomain!);
        if (values.sslCertificate && values.sslCertificate[0]) {
            formData.append('Certification', values.sslCertificate[0].originFileObj);
        }
        if (values.sslPrivateKey && values.sslPrivateKey[0]) {
            formData.append('PrivateKey', values.sslPrivateKey[0].originFileObj);
        }
        addCertificationAndPrivateKeyMutate(formData);
        setIsModalVisible(false);
        setCurrentDomain(null);
    };

    const downloadFile = async (url, filename) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('JWTToken')}`
                }
            });

            // Create a URL for the blob
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', filename); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Clean up and revoke the URL
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(fileURL);
        } catch (error) {
            console.error('Download error:', error);
            messageApi.error(translate('Operation failed'));
        }
    }

    const handleFileChange = (fileList: any[], type: 'crt' | 'key') => {
        const hasFile = fileList.length > 0;
        if (type === 'crt') {
            setHasUploadedCrt(hasFile);
        } else {
            setHasUploadedKey(hasFile);
        }
        return fileList;
    };

    const handleValidateClick = (domain: string) => {
        window.open(`https://${domain}/validateDomain`, '_blank', 'noopener,noreferrer');
    };

    const showUploadModal = (domain: string) => {
        setCurrentDomain(domain);
        setIsModalVisible(true);
    };

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current?.abort();
        };
    }, []);

    if (sslSettingsIsLoading) {
        return <LoadingComponent />;
    }

    return (
        <Card style={{ margin: '10px' }}>
            {sslBindingDtos.map((sslBindingDto, index) => (
                <Card
                    title={`${translate('Domain')} ${index + 1}`}
                    style={{ margin: '10px' }}
                    key={sslBindingDto.merchantDomain}
                >
                    <Form>
                        <FormItem label={translate('Domain')}>
                            <Input disabled value={sslBindingDto.merchantDomain} />
                        </FormItem>
                        <FormItem label={translate('Status')}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {sslBindingDto.isValidated ? (
                                    <>
                                        <Row style={{ width: '100%' }}>
                                            <Col span={12}>
                                                <CheckCircleFilled style={{ color: '#52c41a', fontSize: '16px', marginRight: '8px' }} />
                                                <Typography.Text style={{ color: '#52c41a' }}>
                                                    {translate('Verified')}
                                                </Typography.Text>
                                            </Col>
                                            <Col span={12}>
                                                <Link to={`https://${sslBindingDto.merchantDomain}`} target="_blank">
                                                    {translate('Visit portal website')}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <CloseCircleFilled style={{ color: '#ff4d4f', fontSize: '16px', marginRight: '8px' }} />
                                        <Typography.Text style={{ color: '#ff4d4f' }}>
                                            {translate('Unverified')}
                                        </Typography.Text>
                                    </>
                                )}
                            </div>
                        </FormItem>
                        {
                            sslBindingDto.isValidated && (
                                <Typography.Text style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    margin: '10px 0',
                                    fontSize: '14px',
                                    color: '#666'
                                }} >
                                    {translate('After verification, generating a free certificate may take some time, usually within 24 hours. If you have any questions, please contact technical support.')}
                                </Typography.Text>
                            )
                        }
                        {sslBindingDto.publicKeyFileName && (
                            <FormItem label="SSL Certificate (.crt)">
                                {
                                    <Button
                                        onClick={() => { downloadFile(`${process.env.BASE_API_URL}/api/WebSiteSetting/DownloadCertification/${merchantId}/${encodeURIComponent(sslBindingDto.merchantDomain)}`, sslBindingDto.publicKeyFileName) }}
                                        type="link">
                                        {translate('Download Certificate')}
                                    </Button>
                                }
                            </FormItem>
                        )}
                        {sslBindingDto.privateKeyFilieName && (
                            <FormItem label="SSL Private Key (.key)">
                                {
                                    <Button
                                        type="link"
                                        onClick={() => downloadFile(`${process.env.BASE_API_URL}/api/WebSiteSetting/DownloadPrivateKey/${merchantId}/${encodeURIComponent(sslBindingDto.merchantDomain)}`, sslBindingDto.privateKeyFilieName)}
                                    >
                                        {translate('Download Private Key')}
                                    </Button>
                                }
                            </FormItem>
                        )}
                    </Form>
                    {sslBindingDto.isValidated && !sslBindingDto.publicKeyFileName && (
                        <Flex style={{ margin: '10px' }} justify="center">
                            <Popconfirm
                                title={translate("Confirm")}
                                description={translate("We will provide free Let's Encrypt level SSL certificates. Are you sure you want to use self-uploaded certificates instead?")}
                                onConfirm={() => showUploadModal(sslBindingDto.merchantDomain)}
                                okText={translate("Yes")}
                                cancelText={translate("No")}
                            >
                                <Button>
                                    {translate('Use your own ssl certificate')}
                                </Button>
                            </Popconfirm>
                        </Flex>
                    )}
                    <Flex justify="center">
                        <Popconfirm
                            title={translate(`Delete`)}
                            description={translate(`Are you sure to delete`) + `?`}
                            onConfirm={(e) => {
                                deleteSSLBindingMutate({ domain: sslBindingDto.merchantDomain })
                            }}
                            okText={translate('Yes')}
                            cancelText={translate('No')}
                        >
                            <Button
                                danger
                            >
                                {translate('Delete')}
                            </Button>
                        </Popconfirm>
                    </Flex>
                </Card>
            ))}
            {
                sslBindingDtos.length < 3 && (
                    <Card>
                        <Form onFinish={handleAddDomain}>
                            <FormItem
                                name="merchantDomain"
                                label={translate('Domain')}
                                rules={[
                                    { required: true, message: translate('is required') },
                                    {
                                        pattern: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
                                        message: <>
                                            {translate('Please enter a valid format')}
                                            <br />
                                            {('Example: www.example.com')}
                                        </>

                                    }
                                ]}
                            >
                                <Input placeholder={translate('Domain')} />
                            </FormItem>
                            <Flex justify="center">
                                <Button htmlType="submit">{translate('Add')}</Button>
                            </Flex>
                        </Form>
                    </Card>
                )
            }
            <Modal
                title={translate('Upload')}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form onFinish={handleUploadCertificate}>
                    <FormItem
                        label={translate('SSL Certificate (.crt)')}
                        name="sslCertificate"
                        valuePropName="fileList"
                        getValueFromEvent={e => handleFileChange(e.fileList, 'crt')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Upload
                            beforeUpload={file => {
                                const isValid = file.name.endsWith('.crt');
                                if (!isValid) {
                                    messageApi.error(translate('Please upload a .crt file'));
                                }
                                return isValid || Upload.LIST_IGNORE;
                            }}
                            accept=".crt"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>{translate('Upload Certificate')}</Button>
                        </Upload>
                    </FormItem>
                    <FormItem
                        label={translate('SSL Private Key (.key)')}
                        name="sslPrivateKey"
                        valuePropName="fileList"
                        getValueFromEvent={e => handleFileChange(e.fileList, 'key')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Upload
                            beforeUpload={file => {
                                const isValid = file.name.endsWith('.key');
                                if (!isValid) {
                                    messageApi.error(translate('Please upload a .key file'));
                                }
                                return isValid || Upload.LIST_IGNORE;
                            }}
                            accept=".key"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>{translate('Upload Private Key')}</Button>
                        </Upload>
                    </FormItem>
                    <Flex justify="center">
                        <Button htmlType="submit">{translate('Submit')}</Button>
                    </Flex>
                </Form>
            </Modal>
        </Card>
    );
});

export default SSLSettings;
