import React, { useContext, useEffect } from "react";
import * as antd from "antd";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { DeviceType } from "@/Templates/enums/templateEnums";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";

const ContactUsPage: React.FC = () => {
    const { translate, language, i18nLanguage, supportedLanguages, seti18nLanguage } = useContext(TranslationContext);
    const { deviceType, setIsHideAnnouncement } = useContext(GlobalContext);
    const { Title, Paragraph, Text } = antd.Typography;
    const navigate = useNavigate();

    const contactInfo = {
        zh_TW: {
            title: "聯絡我們",
            companyInfo: {
                title: "公司資訊",
                name: "多米格數位有限公司",
                registrationNo: "00215076",
                address: "新北市板橋區文化路二段285號21樓"
            },
            contactDetails: {
                title: "聯絡方式",
                phone: "09-55275525",
                hours: "週一至週五 09:00-18:00"
            },
            customerService: {
                title: "客戶服務",
                description: "我們致力於提供最優質的服務，如果您有任何問題或建議，歡迎透過以下方式與我們聯繫：",
                methods: [
                    "客服電話諮詢",
                    "營業時間內即時回覆"
                ]
            }
        },
        zh_CN: {
            title: "联系我们",
            companyInfo: {
                title: "公司信息",
                name: "多米格数位有限公司",
                registrationNo: "00215076",
                address: "新北市板桥区文化路二段285号21楼"
            },
            contactDetails: {
                title: "联系方式",
                phone: "09-55275525",
                hours: "周一至周五 09:00-18:00"
            },
            customerService: {
                title: "客户服务",
                description: "我们致力于提供最优质的服务，如果您有任何问题或建议，欢迎通过以下方式与我们联系：",
                methods: [
                    "客服电话咨询",
                    "营业时间内即时回复"
                ]
            }
        },
        en: {
            title: "Contact Us",
            companyInfo: {
                title: "Company Information",
                name: "Domigo Digital Co., Ltd.",
                registrationNo: "00215076",
                address: "21F., No. 285, Sec. 2, Wenhua Rd., Banqiao Dist., New Taipei City"
            },
            contactDetails: {
                title: "Contact Information",
                phone: "09-55275525",
                hours: "Monday to Friday 09:00-18:00"
            },
            customerService: {
                title: "Customer Service",
                description: "We are committed to providing the best service. If you have any questions or suggestions, please contact us through the following methods:",
                methods: [
                    "Customer Service Hotline",
                    "Instant response during business hours"
                ]
            }
        }
    };

    const content = contactInfo[i18nLanguage] || contactInfo.en;
    const languaguesSelectorUI = (): React.ReactNode => {
        const uI = supportedLanguages.map((lang) => (
            <Select.Option key={lang} value={lang}>
                {translate(lang)}
            </Select.Option>
        ));
        return (
            <Select
                style={{ width: 'auto' }}
                defaultValue={language}
                onChange={(value: string) => seti18nLanguage(value)}
            >
                {uI}
            </Select>
        );
    };

    useEffect(() => {
        setIsHideAnnouncement(true);
        return () => {
            setIsHideAnnouncement(false);
        };
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="py-12 px-4 w-full"
        >
            <antd.Row justify="center">
                <antd.Col span={deviceType === DeviceType.Mobile ? 24 : 12}>
                    <antd.Flex justify="center" className="mb-4">
                        <antd.Flex>
                            {languaguesSelectorUI()}
                        </antd.Flex>
                    </antd.Flex>
                    <antd.Card className="shadow-lg">
                        <Title level={2} style={{ textAlign: 'center' }}>{content.title}</Title>
                        <Title level={4}>{content.companyInfo.title}</Title>
                        <Paragraph className="mb-6">
                            <Text strong>公司名稱：</Text>{content.companyInfo.name}<br />
                            <Text strong>統一編號：</Text>{content.companyInfo.registrationNo}<br />
                            <Text strong>公司地址：</Text>{content.companyInfo.address}
                        </Paragraph>

                        <Title level={4}>{content.contactDetails.title}</Title>
                        <Paragraph className="mb-6">
                            <Text strong>電話：</Text>{content.contactDetails.phone}<br />
                            <Text strong>營業時間：</Text>{content.contactDetails.hours}
                        </Paragraph>

                        <Title level={4}>{content.customerService.title}</Title>
                        <Paragraph>{content.customerService.description}</Paragraph>
                        <antd.List
                            dataSource={content.customerService.methods}
                            renderItem={item => (
                                <antd.List.Item>
                                    <Text>{item as string}</Text>
                                </antd.List.Item>
                            )}
                        />

                        <antd.Flex justify="center" align="center">
                            <antd.Button
                                onClick={() => {
                                    navigate('/');
                                }}>
                                {translate('Back to home page')}
                            </antd.Button>
                        </antd.Flex>
                    </antd.Card>
                </antd.Col>
            </antd.Row>
        </motion.div>
    );
};

export default ContactUsPage;

