import { IAddMemberLogisticOptionRequest, IBatchAddItemToShoppingCartRequest, IBatchAddItemToTempShoppingCartRequest, IBatchRemoveItemsFromShoppingCartRequest, IBatchRemoveItemsFromTempShoppingCartRequest, ICheckOutRequest, ICheckOutWithOutRegistRequest, ICreatePaymentOrderRequest, IDeleteMemberLogisticOptionRequest, IFillMemberLogisticOptionWithThirdPartyLogisticRequest, IFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistRequest, IItemSpec, IItemViewModel, IMemberLogisticOption, IOperationResult, IOperationResultT, IPaginationResponse, IPortalMerchantThirdPartyInvoiceSettingViewModel, IPortalMerchantThirdPartyLogisticSettingViewModel, IPortalMerchantThirdPartyPaymentSettingViewModel, IPortalQueryItemByMerchantRequest, IQueryOptionViewModel, IShoppingCartViewModel, ISupportMemberFillAddressResponse, ITempShoppingCartViewModel, IThirdPartyPaymentCreateRespnse } from '@/Templates/interfaces/templatesInterfaces';
import useAPI from '@/Templates/lib/customHooks/useAPI';


// 獲取商戶商品API
export const useGetItemsByMerchantApi = (queryRequest: IPortalQueryItemByMerchantRequest, signal?: AbortSignal) => {
    const url = `${process.env.BASE_API_URL}/api/v1/Portal/GetItemsByMerchant`;
    return useAPI<IOperationResultT<IPaginationResponse<IItemViewModel[]>>>(url, {
        method: 'GET',
        body: queryRequest,
        signal
    });
};

// 獲取支持的國家API
export const useGetSupportedCountriesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetSupportedCountries`, {
    signal
});

// 獲取商戶第三方發票設置視圖模型API
export const useGetMerchantThirdPartyInvoiceSettingViewModes = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IPortalMerchantThirdPartyInvoiceSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetMerchantThirdPartyInvoiceSettingViewModels?merchantId=${merchantId}`, {
    signal
});

// 使用成員物流選項API
export const useGetMemberLogisticOptionsApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMemberLogisticOption[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetMemberLogisticOptions?merchantId=${merchantId}`, {
    signal
});

// 使用匿名物流選項API
export const useGetAnonymousLogisticOptionsApi = (sessionId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMemberLogisticOption[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetAnonymousLogisticOptions?sessionId=${sessionId.toString()}`, {
    signal
});

// 添加成員物流選項API
export const useAddMemberLogisticOptionApi = (params: IAddMemberLogisticOptionRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Portal/AddMemberLogisticOption`, {
    method: 'POST',
    body: params
});

// 獲取商戶第三方支付設置視圖模型API
export const useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IPortalMerchantThirdPartyPaymentSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetPortalMerchantThirdPartyPaymentSettingViewModels?merchantId=${merchantId}`, {
    signal
});

// 獲取商戶第三方物流設置視圖模型API
export const useGetMerchantThirdPartyLogisticSettingViewModes = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IPortalMerchantThirdPartyLogisticSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetMerchantThirdPartyLogisticSettingViewModels?merchantId=${merchantId}`, {
    signal
});

// 填寫成員物流選項與第三方物流API
export const useFillMemberLogisticOptionWithThirdPartyLogisticApi = (params: IFillMemberLogisticOptionWithThirdPartyLogisticRequest) => useAPI<IOperationResultT<ISupportMemberFillAddressResponse>>(`${process.env.BASE_API_URL}/api/v1/Portal/FillMemberLogisticOptionWithThirdPartyLogistic`, {
    method: 'POST',
    body: params
});

// 無註冊填寫成員物流選項與第三方物流API
export const useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi = (params: IFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistRequest) => useAPI<IOperationResultT<ISupportMemberFillAddressResponse>>(`${process.env.BASE_API_URL}/api/v1/Portal/FillMemberLogisticOptionWithThirdPartyLogisticWithNoRegist`, {
    method: 'POST',
    body: params
});

// 獲取物流主類型API
export const useGetLogisticsMainTypesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetLogisticsMainTypes`, {
    signal
});

// 獲取支付主類型API
export const useGetPaymentMainTypesApi = (signal?: AbortSignal) => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetPaymentMainTypes`, {
    signal
});

// 刪除成員物流選項API
export const useDeleteMemberLogisticOptionApi = (params: IDeleteMemberLogisticOptionRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Portal/DeleteMemberLogisticOption`, {
    method: 'DELETE',
    body: params
});

// 獲取購物車API
export const useGetShoppingCartApi = (params: BigInt | undefined, signal?: AbortSignal) => useAPI<IOperationResultT<IShoppingCartViewModel>>(params === undefined ? `${process.env.BASE_API_URL}/api/v1/Portal/GetShoppingCart` : `${process.env.BASE_API_URL}/api/v1/Portal/GetShoppingCart?sessionId=${params}`, {
    signal
});

// 獲取臨時購物車API
export const useGetTempShoppingCartApi = (merchantId: BigInt, sessionId: BigInt | undefined, signal?: AbortSignal) => useAPI<IOperationResultT<ITempShoppingCartViewModel>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetTempShoppingCart?merchantId=${merchantId}&sessionId=${sessionId}`, {
    signal
});

// 創建支付訂單API
export const useCreatePaymentOrder = (params: ICreatePaymentOrderRequest) => useAPI<IOperationResultT<IThirdPartyPaymentCreateRespnse>>(`${process.env.BASE_API_URL}/api/v1/ThirdPartyPayment/CreateOrder`, {
    method: 'POST',
    body: params
});

// 結帳API
export const useCheckOutApi = (params: ICheckOutRequest) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/v1/Portal/CheckOut`, {
    method: 'POST',
    body: params
});

// 允許未註冊結帳API
export const useCheckOutOrderWithAllowNoRegistApi = (params: ICheckOutWithOutRegistRequest, signal?: AbortSignal) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/v1/Portal/CheckOutOrderWithAllowNoRegist`, {
    method: 'POST',
    body: params,
    signal
});

// 從購物車移除商品API
export const useBatchRemoveItemsFromShoppingCartApi = (params: IBatchRemoveItemsFromShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Portal/BatchRemoveItemsFromShoppingCart`, {
    method: 'POST',
    body: params
});

// 從臨時購物車移除商品API
export const useBatchRemoveItemsFromTempShoppingCartApi = (params: IBatchRemoveItemsFromTempShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Portal/BatchRemoveItemsFromTempShoppingCart`, {
    method: 'POST',
    body: params
});

export const useBatchAddItemToTempShoppingCartApi = (params: IBatchAddItemToTempShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Portal/BatchAddItemToTempShoppingCart`, {
    method: 'POST',
    body: params
});

// 批量添加到購物車API
export const useBatchAddItemsToShoppingCartApi = (params: IBatchAddItemToShoppingCartRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Portal/BatchAddItemsToShoppingCart`, {
    method: 'POST',
    body: params
});

export const useGetQueryOptionsByMerchantIdApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IQueryOptionViewModel>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetQueryOptionsByMerchantId?merchantId=${merchantId}`, {
    signal
});

// 獲取商品規格API
export const useGetItemSpecsApi = (merchantId: BigInt, itemId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IItemSpec[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetItemSpecs?merchantId=${merchantId}&itemId=${itemId.toString()}`, {
    method: 'GET',
    signal: signal
});
