import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Flex } from "antd";
import Title from "antd/es/typography/Title";
import { load } from "cheerio";
import htmlEditButton from "quill-html-edit-button";
import ImageResize from 'quill-image-resize-module-react';
import React, { useContext, useEffect } from "react";
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { useMutation } from "react-query";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { useUploadImageApi } from "@/lib/api/images";
import { ImageUseageType } from "@/enums/Enums";
import { CheerioAPI } from "cheerio";

/**
 * @interface IDescriptionProps
 * @說明 描述表單的屬性
 */
export interface IDescriptionProps {
    previewOnly?: boolean;
}

/**
 * @interface IDescriptionRef
 * @說明 描述表單Ref
 */
export interface IDescriptionRef {
    validate: () => Promise<boolean>;
}

// 註冊quill模組
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);

/**
 * @component AlterDescription
 * @說明 用於顯示與編輯描述內容，可以在WYSIWYG與HTML之間切換
 */
const AlterDescription: React.FC<IDescriptionProps> = ({ previewOnly }) => {
    const { itemViewModel } = useContext(ItemContext);
    const { translate } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const quillRef = React.useRef<Quill | null>(null);
    const editorRef = React.useRef<HTMLDivElement>(null);
    const [editorInitialized, setEditorInitialized] = React.useState(false);

    // 添加圖片上傳mutation
    const upLoadImageMutation = useMutation(async (request: FormData) => await useUploadImageApi(request));

    // 處理圖片上傳
    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files?.[0];
            if (file) {
                try {
                    const formData = new FormData();
                    formData.append('UseageType', ImageUseageType.ItemDescription);
                    formData.append('file', file);

                    const response = await upLoadImageMutation.mutateAsync(formData);

                    if (response.isSuccess && response.result) {
                        const quill = quillRef.current;
                        const range = quill?.getSelection(true);

                        // 在游標位置插入圖片
                        if (range) {
                            quill?.insertEmbed(range.index, 'image', response.result);
                        }
                    }
                } catch (error) {
                    messageApi.error('Upload failed');
                }
            }
        };
    };

    /**
     * @function onDescriptionChange
     * @說明 當使用者在編輯器中輸入或變更內容時，自動幫所有元素加上 max-width:100%
     */
    const onDescriptionChange = (value: string) => {
        const $ = load(value);
        $('*').each((index, element) => {
            const existingStyle = $(element).attr('style') || '';
            const newStyle = `${existingStyle} max-width: 100%;`;
            $(element).attr('style', newStyle);
        });
        return $.html();
    };

    /**
     * @function processImageWidths
     * @param {CheerioAPI} $ - Cheerio實例
     * @param {number} editorWidth - 編輯器寬度
     * @說明 處理所有圖片的寬度，將其轉換為相對於編輯器的百分比寬度
     * @returns {string} 處理後的HTML內容
     */
    const processImageWidths = ($: CheerioAPI, editorWidth: number): string => {
        // 找出所有圖片標籤
        $('img').each((index, element) => {
            const imgWidth = $(element).attr('width') || $(element).css('width');
            console.log(`Image ${index} original width:`, imgWidth);

            // 如果有寬度值
            if (imgWidth) {
                // 檢查是否已經是百分比
                if (imgWidth.includes('%')) {
                    // 已經是百分比，直接使用
                    $(element).attr('width', imgWidth);
                } else {
                    // 不是百分比，進行轉換
                    const numericWidth = parseInt(imgWidth);
                    if (!isNaN(numericWidth)) {
                        const percentageWidth = Math.min((numericWidth / editorWidth) * 100, 100);
                        $(element).attr('width', `${percentageWidth.toFixed(1)}%`);
                        console.log(`Image ${index} new width:`, `${percentageWidth.toFixed(1)}%`);
                    }
                }
            } else {
                // 如果沒有寬度值，設置為100%
                $(element).attr('width', '100%');
            }
        });

        return $.html();
    };

    // ReactQuill的模組設定
    var modules: any = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'font': [] }],
                ['clean']
            ],
            handlers: {
                image: handleImageUpload
            }
        },
        htmlEditButton: {
            buttonHTML: 'HTML',
            msg: translate('Edit HTML here'),
            okText: translate('Save'),
            cancelText: translate('Cancel')
        }
    };

    // 如果不是僅預覽，就啟用圖片縮放
    if (!previewOnly) {
        modules = {
            ...modules,
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            }
        };
    }

    // 格式設定
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'size', 'color', 'background', 'font',
        'link', 'image'
    ];

    // 初始化Quill編輯器
    useEffect(() => {
        if (editorRef.current && !quillRef.current) {
            const quill = new Quill(editorRef.current, {
                theme: 'snow',
                modules: modules,
                formats: formats,
                placeholder: translate('Write something amazing...'),
                readOnly: previewOnly
            });

            quill.on('text-change', () => {
                const value = quill.root.innerHTML;
                const editorWidth = editorRef.current?.clientWidth || 0;
                console.log('Editor width:', editorWidth);

                const $ = load(value);
                const processedValue = processImageWidths($, editorWidth);

                // 直接更新 itemViewModel，不再使用 content state
                if (itemViewModel) {
                    itemViewModel.description = processedValue;
                    console.log('Description updated:', processedValue);
                }
            });

            quillRef.current = quill;
            setEditorInitialized(true);
        }
    }, []);

    // 監控 itemViewModel.description 變化，更新編輯器內容
    useEffect(() => {
        // 只在編輯器初始化後處理
        if (quillRef.current && editorInitialized) {
            const currentContent = quillRef.current.root.innerHTML;
            const newContent = itemViewModel.description || '';

            // 防止不必要的更新，只有當內容真正不同時才更新
            if (currentContent !== newContent && newContent) {
                const editorWidth = editorRef.current?.clientWidth || 0;
                const $ = load(newContent);
                const processedContent = processImageWidths($, editorWidth);
                quillRef.current.root.innerHTML = processedContent;
            }
        }
    }, [itemViewModel.description, editorInitialized]);

    // 組件卸載前保存內容
    useEffect(() => {
        return () => {
            if (quillRef.current && itemViewModel) {
                const finalContent = quillRef.current.root.innerHTML;
                itemViewModel.description = finalContent;
            }
        };
    }, [itemViewModel]);

    return (
        <div style={{ width: '100%' }}>
            <Flex justify="center">
                <Title level={3}>{translate('Description')}</Title>
            </Flex>
            <div style={{ padding: '30px', width: '100%' }}>
                <div ref={editorRef} />
            </div>
        </div>
    );
};

export default AlterDescription;
