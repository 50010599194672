import { IItemViewModel, IOperationResult, IOperationResultT } from '@/Templates/interfaces/templatesInterfaces';
import useAPI from '@/Templates/lib/customHooks/useAPI';
import {
    IAlterItemIsOnShelfRequest,
    IAlterItemRequest,
    IAlterItemSpecStockAmountRequest,
    ICreateItemRequest,
    IDeleteItemRequest,
    IQueryItemByMerchantRequest
} from '../../interfaces/Requests/Requests';
import {
    IBackOfficeItemViewModel,
    IItemSpecDto,
    IPaginationResponse
} from '../../interfaces/Responses/Responses';

export const useGetItemByMerchantApi = (itemId: BigInt) => useAPI<IOperationResultT<IItemViewModel>>(`${process.env.BASE_API_URL}/api/v1/Item/GetItemByMerchant?itemId=${itemId.toString()}`);

export const useGetItemsByMerchantApi = (queryRequest: IQueryItemByMerchantRequest, signal?: AbortSignal) => {
    return useAPI<IOperationResultT<IPaginationResponse<IBackOfficeItemViewModel[]>>>(`${process.env.BASE_API_URL}/api/v1/Item/GetItemsByMerchant`, {
        method: 'GET',
        body: queryRequest,
        signal
    });
};

export const useGetItemSpecsApi = (merchantId: BigInt, itemId: BigInt) => useAPI<IOperationResultT<IItemSpecDto[]>>(`${process.env.BASE_API_URL}/api/v1/Item/GetItemSpecs?merchantId=${merchantId}&itemId=${itemId.toString()}`);

export const useCreateItemApi = (createItemRequest: ICreateItemRequest) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/v1/Item/CreateItem`, {
    method: 'POST',
    body: createItemRequest,
});

export const useAlterItemApi = (alterItemRequest: IAlterItemRequest) => useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/v1/Item/AlterItem`, {
    method: 'POST',
    body: alterItemRequest,
});

export const useDeleteItemApi = (params: IDeleteItemRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Item/DeleteItem`, {
    method: 'DELETE',
    body: params
});

export const useAlterItemIsOnShelfApi = (request: IAlterItemIsOnShelfRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Item/AlterItemIsOnShelf`, {
    method: 'POST',
    body: request,
    signal
});

export const useAddItemSpecStockApi = (request: IAlterItemSpecStockAmountRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Item/AddItemSpecStock`, {
    method: 'POST',
    body: request
});

export const useReduceItemSpecStockApi = (request: IAlterItemSpecStockAmountRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Item/ReduceItemSpecStock`, {
    method: 'POST',
    body: request
});

export const useSetItemSpecStockApi = (request: IAlterItemSpecStockAmountRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Item/SetItemSpecStock`, {
    method: 'POST',
    body: request
});

