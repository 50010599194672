import { MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from "@/Templates/enums/templateEnums";
import { IDynamicComponent, IMerchantPortalStyleSetting } from "@/Templates/interfaces/templatesInterfaces";
import { GenereateHelper } from "@/lib/helpers/GenerateHelper";

export const getWebSiteTemplate = (
    translate: (key: string) => string,
    merchantId: BigInt
): IMerchantPortalStyleSetting[] => {
    const generateHelper = new GenereateHelper();
    return [
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('HomePage'),
            style: 1,
            styleSetting: {},
            pagePath: '/',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.Marquee,
                    style: 0,
                    values: {
                    },
                } as IDynamicComponent,
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.ItemList,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('SearchPage'),
            style: 1,
            styleSetting: {},
            pagePath: '/searchPage',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.ItemSearch,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('ShoppingCartPage'),
            style: 1,
            styleSetting: {},
            pagePath: '/shoppingCart',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.ShoppingCart,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('ItemDetailPage'),
            style: 1,
            styleSetting: {},
            pagePath: '/itemDetail',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.ItemDetail,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('LandingPage'),
            style: 1,
            styleSetting: {},
            pagePath: '/landing',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.LandingPage,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('RegisterPage'),
            style: 1,
            styleSetting: {},
            pagePath: '/register',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.RegisterPage,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('ContactUsPage'),
            style: 1,
            styleSetting: {},
            pagePath: '/contactUs',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.ContactUs,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('TermsOfServicePage'),
            style: 1,
            styleSetting: {},
            pagePath: '/termsOfService',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.TermsOfService,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        },
        {
            id: generateHelper.getSnowflakeIdBigInt(),
            name: translate('PrivacyPolicyPage'),
            style: 1,
            styleSetting: {},
            pagePath: '/privacyPolicy',
            seoDescription: '',
            dynamicComponents: [
                {
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: MerchantPortalStyleShopComponents.PrivacyPolicy,
                    style: 0,
                    values: {},
                } as IDynamicComponent,
            ],
            merchantId: merchantId,
            type: MerchantPortalStyleSettingType.Pages
        }
    ];
}


