import { ThirdPartyPlatformServiceProvider } from "@/enums/ThirdPartyPlatformService";
import { IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel } from "@/interfaces/Responses/ThirdPartyLogistic";
import { useGetBackOfficeMerchantThirdPartyPlatformServiceSettingViewModelsApi } from "@/lib/api/thirdPartyPlatformService";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Table } from "antd";
import React, { useContext, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import SendMessageModal from "./Actions/SendMessageModal";
import AddPlatformServiceSetting from "./AddPlatformServiceSetting";
import EditPlatformServiceSetting from "./EditPlatformServiceSetting";

export interface IMessageGroupSettingListProps { }
export interface IMessageGroupSettingListRef {
    onRefresh: () => void;
    cancelQuery: () => void;
}

const useGetPlatformServiceSettings = (signal?: AbortSignal) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const queryClient = useQueryClient();
    const { mutate, isLoading, data } = useMutation(async (signal?: AbortSignal) => {
        return await useGetBackOfficeMerchantThirdPartyPlatformServiceSettingViewModelsApi(signal)
    },
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    queryClient.setQueryData('merchantThirdPartySettings', response.result || []);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            },
        }
    );

    useEffect(() => {
        mutate(signal);
    }, []);

    return {
        settings: data?.result || [],
        isLoading,
        refresh: mutate,
    };
};

const useModal = <T,>() => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [data, setData] = React.useState<T | undefined>(undefined);

    const open = (modalData?: T) => {
        setData(modalData);
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
        setData(undefined);
    };

    return { isOpen, data, open, close };
};

const SettingModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}> = ({ isOpen, onClose, title, children }) => (
    <Modal
        title={title}
        open={isOpen}
        onCancel={onClose}
        footer={null}
    >
        {children}
    </Modal>
);

const PlatformServiceList = React.forwardRef<IMessageGroupSettingListRef, IMessageGroupSettingListProps>((props, ref) => {
    const { translate } = useContext(TranslationContext);
    const { settings, isLoading, refresh } = useGetPlatformServiceSettings();
    const abortController = React.useRef(new AbortController());
    const addModal = useModal();
    const editModal = useModal<IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel>();
    const sendMessageModal = useModal<IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel>();
    const isSupportSendMessage = (record: IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel) => {
        switch (record.provider) {
            case ThirdPartyPlatformServiceProvider.Line:
                return true;
            default:
                return false;
        }
    }

    useEffect(() => {
        return () => {
            abortController.current.abort();
            abortController.current = new AbortController();
        }
    }, []);

    const columns = [
        {
            title: translate('Provider'),
            key: 'provider',
            dataIndex: 'provider',
        },
        {
            title: translate('Operation'),
            key: 'action',
            render: (record: IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel) => (
                <Space>
                    <Button onClick={() => editModal.open(record)}>
                        {translate('Edit')}
                    </Button>
                    {isSupportSendMessage(record) && (
                        <Button onClick={() => sendMessageModal.open(record)}>
                            {translate('Send message')}
                        </Button>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <div style={{ margin: '16px' }}>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={settings}
                pagination={{ position: ['bottomCenter'] }}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={() => addModal.open()}
                        block
                        icon={<PlusOutlined />}
                    >
                        {translate('Add new setting')}
                    </Button>
                )}
                rowKey={(record) => record.id.toString()}
                bordered
            />
            <SettingModal
                isOpen={addModal.isOpen}
                onClose={addModal.close}
                title={translate('Add new setting')}
            >
                <AddPlatformServiceSetting
                    ownRecords={settings}
                    onRefresh={() => refresh(abortController.current.signal)}
                    onClose={addModal.close}
                />
            </SettingModal>
            <SettingModal
                isOpen={editModal.isOpen}
                onClose={editModal.close}
                title={`${translate('Provider')} ${editModal.data?.provider}`}
            >
                <EditPlatformServiceSetting
                    record={editModal.data}
                    onRefresh={() => refresh(abortController.current.signal)}
                    onClose={editModal.close}
                />
            </SettingModal>
            <SettingModal
                isOpen={sendMessageModal.isOpen}
                onClose={sendMessageModal.close}
                title={translate('Send message')}
            >
                {sendMessageModal.data && (
                    <SendMessageModal
                        setting={sendMessageModal.data}
                    />
                )}
            </SettingModal>
        </div>
    );
});

export default PlatformServiceList;