import React, { useContext, useState } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, Select, Switch, Tag, Space, Typography, Radio, InputNumber, Checkbox } from 'antd';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Title } = Typography;
const { RangePicker } = DatePicker;

// 擴展優惠活動類型
export enum PromotionType {
    Discount = 'Discount', // 折扣
    FixedPrice = 'FixedPrice', // 特價
    BuyXGetY = 'BuyXGetY', // 買X送Y
    Bundle = 'Bundle', // 組合優惠
}

// 擴展優惠活動目標群體類型
export enum TargetGroupType {
    All = 'All', // 不限
    AllMembers = 'AllMembers', // 所有登入會員
    SpecificMemberLevels = 'SpecificMemberLevels', // 指定會員等級
}

// 擴展優惠活動商品範圍類型
export enum ProductScopeType {
    All = 'All', // 不限定
    Selected = 'Selected', // 指定商品
}

// 優惠代碼介面
interface IPromoCode {
    code: string;
    isUsed: boolean;
    usedTime?: string;
    usedBy?: string;
}

// 擴展優惠活動介面
interface IPromotion {
    id: string;
    name: string;
    type: PromotionType;
    startTime: string;
    endTime: string;
    isActive: boolean;

    // 折扣設定
    discountType: 'percentage' | 'fixed'; // 百分比或固定金額
    discountValue: number; // 折扣值

    // 使用限制
    targetGroupType: TargetGroupType; // 目標群體
    memberLevels?: string[]; // 指定會員等級
    totalUsageLimit: number | null; // 總使用次數限制 (null 表示不限)
    perMemberUsageLimit: number | null; // 每會員使用次數限制

    // 商品範圍
    productScopeType: ProductScopeType;
    selectedProducts?: string[]; // 指定商品 ID 列表

    // 優惠代碼設定
    promoCodeType: 'auto' | 'manual' | 'none'; // 自動生成/手動輸入/不需要
    promoCodes: IPromoCode[]; // 優惠代碼列表
    promoCodeLength?: number; // 自動生成的代碼長度
    promoCodePrefix?: string; // 優惠代碼前綴
}

const PromotionManagePage: React.FC = () => {
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingPromotion, setEditingPromotion] = useState<IPromotion | null>(null);
    const [promotions, setPromotions] = useState<IPromotion[]>([]);

    // 表格列定義
    const columns = [
        {
            title: '活動名稱',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '活動類型',
            dataIndex: 'type',
            key: 'type',
            render: (type: PromotionType) => {
                const typeMap = {
                    [PromotionType.Discount]: '折扣',
                    [PromotionType.FixedPrice]: '特價',
                    [PromotionType.BuyXGetY]: '買X送Y',
                    [PromotionType.Bundle]: '組合優惠',
                };
                return typeMap[type];
            },
        },
        {
            title: '活動期間',
            key: 'period',
            render: (_, record: IPromotion) => (
                <span>
                    {dayjs(record.startTime).format('YYYY-MM-DD HH:mm')} ~
                    {dayjs(record.endTime).format('YYYY-MM-DD HH:mm')}
                </span>
            ),
        },
        {
            title: '狀態',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive: boolean) => (
                <Tag color={isActive ? 'green' : 'red'}>
                    {isActive ? '啟用中' : '已停用'}
                </Tag>
            ),
        },
        {
            title: '操作',
            key: 'actions',
            render: (_: any, record: IPromotion) => (
                <Space>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        編輯
                    </Button>
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(record.id)}
                    >
                        刪除
                    </Button>
                </Space>
            ),
        },
    ];

    // 處理編輯
    const handleEdit = (promotion: IPromotion) => {
        setEditingPromotion(promotion);
        form.setFieldsValue({
            ...promotion,
            period: [dayjs(promotion.startTime), dayjs(promotion.endTime)],
        });
        setIsModalVisible(true);
    };

    // 處理刪除
    const handleDelete = async (id: string) => {
        try {
            // TODO: 調用刪除API
            setPromotions(promotions.filter(p => p.id !== id));
            messageApi.success(translate('Delete successful'));
        } catch (error) {
            messageApi.error(translate('Delete failed'));
        }
    };

    // 處理表單提交
    const handleSubmit = async (values: any) => {
        try {
            const promotionData = {
                ...values,
                startTime: values.period[0].toISOString(),
                endTime: values.period[1].toISOString(),
                totalUsageLimit: values.totalUsageLimit === 'unlimited' ? null : Number(values.totalUsageLimit),
                perMemberUsageLimit: values.perMemberUsageLimit === 'unlimited' ? null : Number(values.perMemberUsageLimit),
                promoCodes: values.promoCodes || [],
            };

            if (editingPromotion) {
                // TODO: 調用更新API
                setPromotions(promotions.map(p =>
                    p.id === editingPromotion.id ? { ...promotionData, id: p.id } : p
                ));
            } else {
                // TODO: 調用創建API
                setPromotions([...promotions, { ...promotionData, id: Date.now().toString() }]);
            }

            setIsModalVisible(false);
            form.resetFields();
            setEditingPromotion(null);
            messageApi.success(translate('Save successful'));
        } catch (error) {
            messageApi.error(translate('Save failed'));
        }
    };

    // 生成單個隨機優惠代碼
    const generatePromoCode = (length: number, prefix: string = '') => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = prefix;
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };

    // 批量生成優惠代碼
    const generatePromoCodes = (count: number) => {
        const prefix = form.getFieldValue('promoCodePrefix') || '';
        const length = form.getFieldValue('promoCodeLength') || 8;
        const codes: IPromoCode[] = [];

        for (let i = 0; i < count; i++) {
            codes.push({
                code: generatePromoCode(length, prefix),
                isUsed: false
            });
        }

        form.setFieldValue('promoCodes', codes);
    };

    // 處理手動添加優惠代碼
    const handleAddManualCode = (code: string) => {
        const currentCodes = form.getFieldValue('promoCodes') || [];
        if (currentCodes.length >= 30) {
            messageApi.warning('優惠代碼數量已達上限');
            return;
        }

        form.setFieldValue('promoCodes', [
            ...currentCodes,
            { code, isUsed: false }
        ]);
    };

    // 處理刪除優惠代碼
    const handleDeleteCode = (index: number) => {
        const currentCodes = form.getFieldValue('promoCodes') || [];
        const newCodes = [...currentCodes];
        newCodes.splice(index, 1);
        form.setFieldValue('promoCodes', newCodes);
    };

    return (
        <div style={{ padding: '20px' }}>
            <Title level={2}>優惠活動管理</Title>

            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                    setEditingPromotion(null);
                    form.resetFields();
                    setIsModalVisible(true);
                }}
                style={{ marginBottom: '20px' }}
            >
                新增優惠活動
            </Button>

            <Table
                columns={columns}
                dataSource={promotions}
                rowKey="id"
            />

            <Modal
                title={editingPromotion ? '編輯優惠活動' : '新增優惠活動'}
                open={isModalVisible}
                onOk={form.submit}
                onCancel={() => setIsModalVisible(false)}
                width={800}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        promoCodes: [],
                        promoCodeType: 'none',
                        promoCodeLength: 8,
                    }}
                >
                    <Form.Item
                        name="name"
                        label="活動名稱"
                        rules={[{ required: true, message: '請輸入活動名稱' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="period"
                        label="活動期間"
                        rules={[{ required: true, message: '請選擇活動期間' }]}
                    >
                        <RangePicker
                            showTime
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>

                    <Form.Item
                        name="discountType"
                        label="折扣類型"
                        rules={[{ required: true, message: '請選擇折扣類型' }]}
                    >
                        <Radio.Group>
                            <Radio value="percentage">百分比</Radio>
                            <Radio value="fixed">固定金額</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.discountType !== currentValues.discountType}
                    >
                        {({ getFieldValue }) => {
                            const discountType = getFieldValue('discountType');
                            return (
                                <Form.Item
                                    name="discountValue"
                                    label={discountType === 'percentage' ? '折扣百分比' : '折扣金額'}
                                    rules={[{ required: true, message: '請輸入折扣值' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        max={discountType === 'percentage' ? 100 : undefined}
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    <Form.Item
                        name="targetGroupType"
                        label="目標群體"
                        rules={[{ required: true, message: '請選擇目標群體' }]}
                    >
                        <Select>
                            <Select.Option value={TargetGroupType.All}>不限</Select.Option>
                            <Select.Option value={TargetGroupType.AllMembers}>所有會員</Select.Option>
                            <Select.Option value={TargetGroupType.SpecificMemberLevels}>指定會員等級</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.targetGroupType !== currentValues.targetGroupType}
                    >
                        {({ getFieldValue }) => {
                            const targetGroupType = getFieldValue('targetGroupType');
                            return targetGroupType === TargetGroupType.SpecificMemberLevels ? (
                                <Form.Item
                                    name="memberLevels"
                                    label="會員等級"
                                    rules={[{ required: true, message: '請選擇會員等級' }]}
                                >
                                    <Checkbox.Group>
                                        <Checkbox value="new">新會員</Checkbox>
                                        <Checkbox value="regular">一般會員</Checkbox>
                                        <Checkbox value="vip">VIP會員</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            ) : null;
                        }}
                    </Form.Item>

                    <Form.Item
                        name="totalUsageLimit"
                        label="總使用次數限制"
                    >
                        <Radio.Group>
                            <Radio value="unlimited">不限</Radio>
                            <Radio value="limited">
                                <InputNumber min={1} />
                                次
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="perMemberUsageLimit"
                        label="每會員使用次數限制"
                    >
                        <Radio.Group>
                            <Radio value="unlimited">不限</Radio>
                            <Radio value="limited">
                                <InputNumber min={1} />
                                次
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="productScopeType"
                        label="商品範圍"
                        rules={[{ required: true, message: '請選擇商品範圍' }]}
                    >
                        <Radio.Group>
                            <Radio value={ProductScopeType.All}>全部商品</Radio>
                            <Radio value={ProductScopeType.Selected}>指定商品</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="isActive"
                        label="活動狀態"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren="啟用" unCheckedChildren="停用" />
                    </Form.Item>

                    {/* 優惠代碼設定 */}
                    <Form.Item
                        name="promoCodeType"
                        label="優惠代碼"
                        rules={[{ required: true, message: '請選擇優惠代碼設定' }]}
                    >
                        <Radio.Group>
                            <Radio value="none">不需要</Radio>
                            <Radio value="manual">手動輸入</Radio>
                            <Radio value="auto">自動生成</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.promoCodeType !== currentValues.promoCodeType
                        }
                    >
                        {({ getFieldValue }) => {
                            const promoCodeType = getFieldValue('promoCodeType');
                            const promoCodes = getFieldValue('promoCodes') || [];

                            if (promoCodeType === 'manual') {
                                return (
                                    <div>
                                        <Form.Item label="新增優惠代碼">
                                            <Input.Group compact>
                                                <Input
                                                    style={{ width: '200px' }}
                                                    id="manualCode"
                                                />
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        const input = document.getElementById('manualCode') as HTMLInputElement;
                                                        if (input.value) {
                                                            handleAddManualCode(input.value);
                                                            input.value = '';
                                                        }
                                                    }}
                                                    disabled={promoCodes.length >= 30}
                                                >
                                                    新增
                                                </Button>
                                            </Input.Group>
                                        </Form.Item>
                                    </div>
                                );
                            }

                            if (promoCodeType === 'auto') {
                                return (
                                    <>
                                        <Form.Item
                                            name="promoCodePrefix"
                                            label="代碼前綴"
                                        >
                                            <Input
                                                style={{ width: '200px' }}
                                                placeholder="可選"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="promoCodeLength"
                                            label="代碼長度"
                                            initialValue={8}
                                        >
                                            <InputNumber
                                                min={4}
                                                max={20}
                                                style={{ width: '120px' }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="生成數量">
                                            <InputNumber
                                                min={1}
                                                max={30 - promoCodes.length}
                                                defaultValue={1}
                                                style={{ width: '120px' }}
                                                id="codeCount"
                                            />
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    const input = document.getElementById('codeCount') as HTMLInputElement;
                                                    const count = parseInt(input.value) || 1;
                                                    generatePromoCodes(count);
                                                }}
                                                disabled={promoCodes.length >= 30}
                                                style={{ marginLeft: '8px' }}
                                            >
                                                生成
                                            </Button>
                                        </Form.Item>
                                    </>
                                );
                            }

                            return null;
                        }}
                    </Form.Item>

                    {/* 優惠代碼列表 */}
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.promoCodes !== currentValues.promoCodes ||
                            prevValues.promoCodeType !== currentValues.promoCodeType
                        }
                    >
                        {({ getFieldValue }) => {
                            const promoCodeType = getFieldValue('promoCodeType');
                            if (promoCodeType === 'none') return null;

                            const promoCodes = getFieldValue('promoCodes') || [];

                            return (
                                <Form.Item label="優惠代碼列表">
                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        {promoCodes.map((code: IPromoCode, index: number) => (
                                            <div key={index} style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '8px'
                                            }}>
                                                <Tag color={code.isUsed ? 'default' : 'blue'}>
                                                    {code.code}
                                                </Tag>
                                                <span style={{ marginLeft: '8px', color: code.isUsed ? 'red' : 'green' }}>
                                                    {code.isUsed ? '已使用' : '未使用'}
                                                </span>
                                                {!code.isUsed && (
                                                    <Button
                                                        type="link"
                                                        danger
                                                        onClick={() => handleDeleteCode(index)}
                                                    >
                                                        刪除
                                                    </Button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        已生成 {promoCodes.length}/30 個代碼
                                    </div>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>

                    {/* 添加隱藏的表單項來存儲優惠代碼列表 */}
                    <Form.Item
                        name="promoCodes"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default PromotionManagePage; 