import { IAlterMerchantThirdPartyLogisticSettingRequest } from '@/interfaces/Requests/Requests';
import { IBackOfficeMerchantThirdPartyLogisticSettingViewModel } from '@/interfaces/Responses/Responses';
import { IThirdPartyLogisticSetting } from '@/interfaces/Responses/ThirdPartyLogistic';
import { useAlterMerchantThirdPartyLogisticSettingApi, useGetThirdPartyLogisticSettingApi } from '@/lib/api/thirdPartyLogistics';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { Button, Form, Input, Row, Typography, Col } from 'antd';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation } from 'react-query';

const { Title } = Typography;

export interface IEditLogisticSettingProps {
    record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel;
    onRefresh: () => void;
}

export interface IEditLogisticSettingRef {
    onOpen: (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => void;
    onClose: () => void;
}

const EditLogistic = React.forwardRef((props: IEditLogisticSettingProps, ref: React.ForwardedRef<IEditLogisticSettingRef | undefined>) => {
    const [form] = Form.useForm();
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [tpls, setTpls] = useState<IThirdPartyLogisticSetting | undefined>(undefined);
    const [formValues, setFormValues] = useState<Record<string, string>>({});

    const { mutate: fetchTplsMutate } = useMutation(
        async (settingId: BigInt) => await useGetThirdPartyLogisticSettingApi(settingId),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    setTpls(response.result);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const { mutate: alterMutate, isLoading } = useMutation(
        async (request: IAlterMerchantThirdPartyLogisticSettingRequest) => await useAlterMerchantThirdPartyLogisticSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    props.onRefresh();
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    useEffect(() => {
        fetchTplsMutate(props.record.thirdPartySettingId);
    }, [props.record]);

    useEffect(() => {
        if (tpls && props.record && props.record.merchantSettings) {
            const initialValues = tpls.merchantFillFieldsForBasicInfo.reduce((acc: Record<string, string>, field) => {
                acc[field] = props.record.merchantSettings?.[field] || '';
                return acc;
            }, {});
            setFormValues(initialValues);
            form.setFieldsValue(initialValues);
        }
    }, [tpls, props.record, form]);

    const handleInputChange = (field: string, value: string) => {
        setFormValues(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = () => {
        if (!merchantId || !props.record) return;
        const request: IAlterMerchantThirdPartyLogisticSettingRequest = {
            settingId: props.record.thirdPartySettingId,
            setting: formValues,
            isDisabled: props.record.isDisable,
        };
        alterMutate(request);
    };

    if (!tpls || !props.record) return <LoadingComponent></LoadingComponent>;

    return (
        <div>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {tpls.merchantFillFieldsForBasicInfo.map((key) => (
                    <Form.Item
                        key={key}
                        name={key}
                        label={translate(key)}
                    >
                        <Row justify="space-between">
                            <Col span={20}>
                                <Input
                                    value={formValues[key] || ''}
                                    onChange={(e) => handleInputChange(key, e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                ))}
                <Row justify="center">
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {translate('Save')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
});

export default EditLogistic;