import { OrderStatus } from '@/enums/Enums';
import { useGetRecentOrdersByStatusApi } from '@/lib/api/dataAnalysis';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { Column } from '@ant-design/plots';
import { Card, Col, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { DataAnalysisPageContext } from '../Contexts/DataAnalysisPageContext';

const { Option } = Select;
const { Text } = Typography;

const generateDateRange = (days: number) => {
    const today = dayjs();
    return Array.from({ length: days }, (_, i) => today.subtract(i, 'day').format('MM-DD')).reverse();
};

const RecentOrdersChart: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(DataAnalysisPageContext);
    const [days, setDays] = useState<number>(7);
    const [status, setStatus] = useState<OrderStatus>(OrderStatus.Done);
    const [yAxisType, setYAxisType] = useState<'count' | 'totalAmount'>('count');
    const getRecentOrdersByStatusMutation = useMutation(
        async ({ status, days }: { status: OrderStatus, days?: number }) =>
            await useGetRecentOrdersByStatusApi(status, days, abortController.current.signal));
    const dateRange = generateDateRange(days);
    const rawData = getRecentOrdersByStatusMutation.data && getRecentOrdersByStatusMutation.data.result ? getRecentOrdersByStatusMutation.data.result : [];
    const formattedData = dateRange.map(date => {
        const item = rawData.find(d => d.date.endsWith(date));
        return {
            date,
            count: item ? item.count : 0,
            totalAmount: item ? item.totalAmount : 0,
        };
    });
    const config = {
        data: {
            value: formattedData
        },
        xField: 'date',
        yField: yAxisType,
        slider: {
            x: {
                values: [0, 1],
            },
        },
    };

    useEffect(() => {
        getRecentOrdersByStatusMutation.mutate({ status, days });
    }, [status, days]);

    return (
        <Card style={{ marginTop: '20px' }}>
            <h3>{translate('Recent Orders')}</h3>
            {/* 選項區塊不受 loading 影響 */}
            <Row gutter={[16, 16]} align="middle" style={{ marginBottom: 20 }}>
                <Col>
                    <Row align="middle">
                        <Text>{translate('Order Status')}:</Text>
                        <Select
                            value={status}
                            onChange={value => setStatus(value)}
                            style={{ width: 200, marginLeft: 8 }}
                        >
                            {Object.keys(OrderStatus).map(key => (
                                <Option key={key} value={OrderStatus[key]}>
                                    {translate(OrderStatus[key])}
                                </Option>
                            ))}
                        </Select>
                    </Row>
                </Col>
                <Col>
                    <Row align="middle">
                        <Text>{translate('Date')}:</Text>
                        <Select
                            value={days}
                            onChange={value => setDays(value)}
                            style={{ width: 200, marginLeft: 8 }}
                        >
                            <Option value={7}>{translate('Last 7 days')}</Option>
                            <Option value={30}>{translate('Last 30 days')}</Option>
                            <Option value={90}>{translate('Last 90 days')}</Option>
                        </Select>
                    </Row>
                </Col>
                <Col>
                    <Row align="middle">
                        <Text>{translate('Observed Value')}:</Text>
                        <Select
                            value={yAxisType}
                            onChange={value => setYAxisType(value)}
                            style={{ width: 200, marginLeft: 8 }}
                        >
                            <Option value="count">{translate('Count')}</Option>
                            <Option value="totalAmount">{translate('Total Amount')}</Option>
                        </Select>
                    </Row>
                </Col>
            </Row>
            {/* 只有圖表區域顯示 loading */}
            <div style={{ position: 'relative', minHeight: '300px' }}>
                <Column {...config} loading={getRecentOrdersByStatusMutation.isLoading} />
            </div>
        </Card>
    );
};

export default RecentOrdersChart;
