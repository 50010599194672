import { IThirdPartyInvoiceOrderViewModel } from "@/interfaces/Responses/ThirdPartyInvoice";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { TaiwanInvoiceElectronicType, TaiwanInvoiceType } from "@/Templates/Shop/Components/ShoppingCart/InvoiceTemplates/TaiwanInvoiceFields";
import { Flex, Typography } from "antd";
import { useContext, useEffect } from "react";

const { Text } = Typography;

export interface TaiwanInvoiceFieldProps {
    thirdPartyOrder: IThirdPartyInvoiceOrderViewModel;
}

// "AppKey": "sHeq7t8G1wiQvhAuIM27",
//                     "OrderId": "1876524337427779584",
//                     "DonateTo": "",
//                     "BuyerEmail": "tf00604940@gmail.com",
//                     "MerchantId": "1864527967787552768",
//                     "OrderItems": "[{\"ItemId\":1875240700770127872,\"ItemName\":\"Domigo-1\",\"ItemIcon\":\"https://cdn.domigo-digital.com/normal-images/1875240700770127872_ItemCover_7047ef53-e7a0-45ba-a478-b6de7d850fe0.svg\",\"ItemDescription\":\"<html style=\\\" max-width: 100%;\\\"><head style=\\\" max-width: 100%;\\\"></head><body style=\\\" max-width: 100%;\\\"><p style=\\\" max-width: 100%;\\\"><LazyLoadImage effect=\\\"blur\\\" src=\\\"https://cdn.domigo-digital.com/normal-images/1875240700770127872_ItemDescription_23e79101-8fbf-44a6-a28b-3c528d7cb18b.jpg\\\" style=\\\" max-width: 100%;\\\"></p></body></html>\",\"ItemSpecId\":1875240701176975360,\"ItemSpecName\":\"Domigo-1\",\"Currency\":\"TWD\",\"ItemSpecPrice\":9.0,\"BuyAmount\":62,\"LogisticOrderLimitType\":0}]",
//                     "CreatedTime": "1736233240",
//                     "InvoiceType": "Paper",
//                     "CompanyTitle": "",
//                     "IsCollection": "True",
//                     "receiverName": "蕭竣聰",
//                     "receiverEmail": "tf00604940@gmail.com",
//                     "receiverPhone": "0955275525",
//                     "CompanyDetails": "false",
//                     "ElectronicType": "",
//                     "IsNoRegistOrder": "False",
//                     "MerchantInvoice": "12345678",
//                     "TotalFinalPrice": "558",
//                     "BuyerPhoneNumber": "",
//                     "ThirdPartyOrderId": "1879015733351026688",
//                     "PersonalDeviceInput": "",
//                     "ThirdPartySettingId": "1853698871264415744",
//                     "CompanyUnifiedNumber": "",
//                     "CitizenCertificateInput": ""
//                 },
const TaiwanInvoiceField = (props: TaiwanInvoiceFieldProps) => {
    const { merchantId, merchantPortalOptionSettingMutation } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    useEffect(() => {
        console.log(merchantPortalOptionSettingMutation.data?.result?.country, props.thirdPartyOrder.values['InvoiceType'], props.thirdPartyOrder.values['CompanyDetails']);
    }, [merchantPortalOptionSettingMutation.data?.result?.country, props.thirdPartyOrder.values['InvoiceType']]);

    switch (props.thirdPartyOrder.values['InvoiceType']) {
        case TaiwanInvoiceType.Paper:
            if (props.thirdPartyOrder.values['CompanyDetails'].toLocaleLowerCase() === 'true') {
                return (
                    <>
                        <Flex align="center" gap="middle">
                            <Text type="secondary">
                                {translate('Invoice.CompanyTitle')}:
                            </Text>
                            <Text>
                                {props.thirdPartyOrder.values['CompanyTitle']}
                            </Text>
                        </Flex>
                        <Flex align="center" gap="middle">
                            <Text type="secondary" >
                                {translate('Invoice.CompanyUnifiedNumber')}:
                            </Text>
                            <Text>
                                {props.thirdPartyOrder.values['CompanyUnifiedNumber']}
                            </Text>
                        </Flex>
                    </>
                );
            }
            return null;
        case TaiwanInvoiceType.Electronic:
            switch (props.thirdPartyOrder.values['ElectronicType']) {
                case TaiwanInvoiceElectronicType.None:
                    return null;
                case TaiwanInvoiceElectronicType.PersonalDevice:
                    return (
                        <Flex align="center" gap="middle">
                            <Text type="secondary" >
                                {translate('Invoice.PersonalDeviceInput')}:
                            </Text>
                            <Text>
                                {props.thirdPartyOrder.values['PersonalDeviceInput']}
                            </Text>
                        </Flex>
                    );
                case TaiwanInvoiceElectronicType.CitizenCertificate:
                    return (
                        <Flex align="center" gap="middle">
                            <Text type="secondary" >
                                {translate('Invoice.CitizenCertificateInput')}:
                            </Text>
                            <Text>
                                {props.thirdPartyOrder.values['CitizenCertificateInput']}
                            </Text>
                        </Flex>
                    );
                default:
                    return null;
            }
        default:
            return null;
    }
};

export default TaiwanInvoiceField;
