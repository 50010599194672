import { IGetMemberInfoViewModelByMerchantRequest, IRegistRequest } from "@/interfaces/Requests/Requests";
import { IMemberInfoViewModel, IPaginationResponse } from "@/interfaces/Responses/Responses";
import { IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';


export const useMemberRegistApi = (params: IRegistRequest) => useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/v1/Member/MemberRegist`, {
    method: 'POST',
    body: params,
});

export const useGetMemberInfoViewModelByMerchantApi = (request: IGetMemberInfoViewModelByMerchantRequest, signal?: AbortSignal) => useAPI<IOperationResultT<IPaginationResponse<IMemberInfoViewModel[]>>>(`${process.env.BASE_API_URL}/api/v1/Member/GetMemberInfoViewModelByMerchant`, {
    method: 'GET',
    body: request,
    signal
});