import { ImageUseageType } from "@/enums/Enums";
import { IAlterItemRequest } from "@/interfaces/Requests/Requests";
import { useAlterItemApi, useGetItemByMerchantApi, useGetItemSpecsApi } from "@/lib/api/items";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Divider, Flex, Modal, Steps, Tooltip, UploadFile } from "antd";
import { load } from "cheerio";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import AlterDescription from "../AddItemPages/Components/AlterDescription";
import BasicInformation, { IBasicInformationRef } from "../AddItemPages/Components/BasicInformation";
import PreviewItem from "../AddItemPages/Components/PreviewItem";
import SpecsForm from "../AddItemPages/Components/SpecsForm";
import { IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";

export interface EditItemPageProps { }

export enum EditItemStep {
    FillInfo = 0,
    Preview = 1
}

const EditItemPage: React.FC<EditItemPageProps> = () => {
    const { merchantId, setIsPageLoading, messageApi } = useContext(GlobalContext);
    const { itemViewModel, setItemViewModel, itemSpecs, setItemSpecs, coverImages, setCoverImages } = useContext(ItemContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const basicInfoRef = useRef<IBasicInformationRef>();
    const queryParams = new URLSearchParams(location.search);
    const itemId = BigInt(queryParams.get('itemId') || '0');
    const previewOnly = queryParams.get('previewOnly') === 'true';
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState<EditItemStep>(EditItemStep.FillInfo);
    const [isBasicInfoValid, setIsBasicInfoValid] = useState<boolean>(false);
    const [isSpecsAllValid, setIsSpecsAllValid] = useState<boolean>(false);
    const { modal } = useContext(GlobalContext);

    const { data: itemData, isLoading: itemLoading, isFetched: itemIsFetched } = useQuery(
        ['getItem', itemId.toString()],
        async () => await useGetItemByMerchantApi(itemId),
        {
            enabled: !!itemId && itemId !== BigInt(0),
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    setItemViewModel(response.result);
                    setCoverImages(response.result.coverImages);
                } else {
                    navigate('/not-found');
                }
            },
            onError: (error) => {
                console.error(error);
            }
        }
    );

    const { data: specsData, isLoading: specsLoading, isFetched: specsIsFetched } = useQuery(
        ['getSpecs', merchantId?.toString(), itemId.toString()],
        async () => await useGetItemSpecsApi(merchantId!, itemId),
        {
            enabled: !!merchantId && !!itemId && itemId !== BigInt(0),
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    setItemSpecs(response.result);
                } else {
                    setItemSpecs([]);
                }
            },
            onError: (error) => {
                console.error(error);
            }
        }
    );

    const { mutateAsync } = useMutation("alterItem",
        async () => {
            if (!itemViewModel || !merchantId) {
                return;
            }
            var alterRequest: IAlterItemRequest = {
                coverImages: coverImages,
                itemId: itemViewModel.id,
                itemSpecs: itemSpecs,
                name: itemViewModel.name,
                title: itemViewModel.title,
                briefly: itemViewModel.briefly,
                description: itemViewModel.description,
                finalTagNames: itemViewModel.itemTags.map((tag) => tag.name)
            };
            console.log(alterRequest);

            return await useAlterItemApi(alterRequest);
        },
        {
            onSuccess: (response: IOperationResultT<boolean> | undefined) => {
                if (response?.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    navigate('/itemManage');
                } else {
                    messageApi.error(translate('Operation failed'));
                }
            },
        }
    );

    const editItemAsync = () => {
        modal.confirm({
            title: translate('Confirm'),
            content: translate('Are you sure to edit this item') + '?',
            okText: translate('Yes'),
            okButtonProps: {
                id: 'submit-confirm-btn'
            },
            async onOk() {
                await mutateAsync();
            },
            cancelText: translate('No'),
        });
    }

    const handleNextStep = async () => {
        if (currentStep === EditItemStep.FillInfo) {
            const isBasicInfoValid = await basicInfoRef.current?.validate();
            const isSpecsAllValid = true; // Perform validation for specs as needed

            if (isBasicInfoValid && isSpecsAllValid) {
                setCurrentStep(EditItemStep.Preview);
            } else {
                messageApi.error(translate('Please complete all required fields.'));
            }
        } else {
            setCurrentStep(EditItemStep.Preview);
        }
    };

    const previewDisabledTooltip = i18nLanguage === 'zh_TW' ? '部分資料填寫未齊全' : 'Some required fields are incomplete';

    useEffect(() => {
        if (itemId === BigInt(0)) {
            navigate('/not-found');
        }
    }, [itemId]);

    useEffect(() => {
        setIsPageLoading(itemLoading || specsLoading);
    }, [itemLoading, specsLoading]);

    useEffect(() => {
        if (basicInfoRef.current) {
            basicInfoRef.current?.validate();
        }
    }, [basicInfoRef.current]);

    return (
        itemViewModel && itemSpecs &&
        <div>
            <div>
                <Steps style={{ padding: '30px' }} current={currentStep}>
                    <Steps.Step title={previewOnly ? translate('Detail') : translate('Edit item')} />
                    <Steps.Step title={translate('Preview')} />
                </Steps>
                {currentStep === EditItemStep.FillInfo && (
                    <>
                        <div>
                            <Flex style={{ margin: '30px' }} justify="center">
                                <BasicInformation
                                    ref={basicInfoRef}
                                    previewOnly={previewOnly}
                                    onIsAllValid={(isAllValid) => setIsBasicInfoValid(isAllValid)}
                                />
                            </Flex>
                        </div>
                        <Divider style={{ padding: '10px' }}></Divider>
                        <SpecsForm
                            previewOnly={previewOnly}
                            onIsAllValid={(isAllValid) => setIsSpecsAllValid(isAllValid)}
                        />
                        <Divider style={{ padding: '10px' }}></Divider>
                        <AlterDescription previewOnly={previewOnly} />
                        <Flex justify="center" gap="middle" style={{ padding: '30px' }}>
                            <Tooltip title={isBasicInfoValid && isSpecsAllValid ? '' : previewDisabledTooltip}>
                                <Button
                                    id="preview-button"
                                    shape="round"
                                    type="primary"
                                    onClick={handleNextStep}
                                    disabled={!isBasicInfoValid || !isSpecsAllValid}
                                >
                                    {translate('Preview')}
                                </Button>
                            </Tooltip>
                        </Flex>
                    </>
                )}
                {currentStep === EditItemStep.Preview && (
                    <div>
                        <PreviewItem />
                        <Flex justify="center" gap="middle" style={{ padding: '30px' }}>
                            <Button
                                id="previous-button"
                                shape="round"
                                type="primary"
                                onClick={() => setCurrentStep(EditItemStep.FillInfo)}
                            >
                                {translate('Previous Step')}
                            </Button>
                            <Button
                                id="submit-button"
                                shape="round"
                                hidden={previewOnly}
                                type="primary"
                                onClick={editItemAsync}
                            >
                                {translate('Submit')}
                            </Button>
                        </Flex>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EditItemPage;
