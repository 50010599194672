import { IItemSpecDto } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from "@/Templates/enums/templateEnums";
import { IItemViewModel, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import { PreviewItemDetailPageTemplateProps, TemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useContext, useMemo, useState } from "react";
import './ItemDetail.css';
import { useMutation } from "react-query";

export interface ItemDetailProps extends TemplateProps {
    item: IItemViewModel;
    itemSpecs: IItemSpecDto[];
}
const ItemDetail: React.FC<ItemDetailProps> = ({
    ...props
}) => {
    const {
        merchantId,
        deviceType,
        messageApi,
        merchantPortalStyleSettingsMutation,
        merchantPortalOptionSettingMutation,
        notificationApi
    } = useContext(GlobalContext);
    const { i18nLanguage, translate } = useContext(TranslationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpecDto | null>(props.itemSpecs?.[0] || null);
    const [purchaseQuantity, setPurchaseQuantity] = useState(1);
    const portalStyle = merchantPortalStyleSettingsMutation.data?.result?.filter(settings => settings.type === MerchantPortalStyleSettingType.Pages && settings.name === MerchantPortalStyleShopPages.ItemDetailPage)?.[0];


    const TemplateComponent = useMemo(() => dynamic<TemplateProps>(() =>
        import(`@/Templates/Shop/Components/ItemDetail/Template_${portalStyle?.style || '0'}.tsx`),
        {
            loading: () => <LoadingComponent />,
            ssr: false
        }
    ), []);

    const handleAddToShoppingCartMutation = useMutation(async () => {

    });

    return (
        <TemplateComponent
            {...props}
            isPreviewMode={true}
            previewItemDetailPageProps={{
                item: props.item,
                itemSpecs: props.itemSpecs,
                handleAddToShoppingCartMutation: handleAddToShoppingCartMutation,
                shoppingCartItemCount: 0
            }}
        />
    );
}

export default ItemDetail;
