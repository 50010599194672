import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { IDynamicComponent, IMerchantPortalStyleSetting } from '@/Templates/interfaces/templatesInterfaces';
import { TemplateProps } from '@/Templates/TemplateProps';
import dynamic from 'next/dynamic';
import React, { Suspense } from 'react';

interface GlobalComponentTemplateProps extends TemplateProps {
    portalStyleSetting: IMerchantPortalStyleSetting;
}

const GlobalComponentTemplate: React.FC<GlobalComponentTemplateProps> = ({ ...props }) => {
    const ComponentTemplate = dynamic<TemplateProps>(() =>
        import(`@/Templates/Shop/Components/${props.portalStyleSetting.name}/Template_${props.portalStyleSetting.style}.tsx`),
        {
            loading: () => <LoadingComponent />,
            ssr: false
        }
    );
    return (
        <div
            key={`${props.portalStyleSetting.id.toString()}-${props.portalStyleSetting.name}`} style={{ width: '100%' }}>
            <Suspense fallback={<></>}>
                <ComponentTemplate
                    {...props}
                    config={{ ...props.portalStyleSetting.styleSetting }}
                />
            </Suspense>
        </div>
    );
};

export default GlobalComponentTemplate; 