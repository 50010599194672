import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Tabs, TabsProps } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderList from "./OrderList";
import PaymentSettingList from "./PaymentList";

type TabKey = 'paymentSettingList' | 'orderList';

const PaymentModule = () => {
    const { deviceType } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [activeTabKey, setActiveTabKey] = useState<TabKey>('paymentSettingList');

    useEffect(() => {
        // 從 URL 讀取初始 tab
        const tabFromUrl = searchParams.get('tab') as TabKey;
        if (tabFromUrl && ['paymentSettingList', 'orderList'].includes(tabFromUrl)) {
            setActiveTabKey(tabFromUrl);
        }
    }, []);

    const handleTabChange = (key: string) => {
        setActiveTabKey(key as TabKey);
        // 更新 URL，保留 module 參數
        navigate(`?module=payment&tab=${key}`);
    };

    const items: TabsProps['items'] = [
        {
            key: 'paymentSettingList',
            label: `${translate('Provider')} ${translate('List')}`,
            children: <PaymentSettingList />,
        },
        {
            key: 'orderList',
            label: `${translate('Order')} ${translate('List')}`,
            children: <OrderList />
        }
    ];

    return (
        <Tabs
            type="card"
            destroyInactiveTabPane={true}
            activeKey={activeTabKey}
            items={items}
            tabPosition='top'
            onChange={handleTabChange}
        />
    );
}

export default PaymentModule;