import { addDynamicPagePortalStyleApi, useDeletePortalStyleApi, useUpsertPagesPortalStylesApi } from '@/lib/api/merchants';
import { useGetMerchantPortalStyleSettingsApi } from '@/lib/api/portals';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from '@/Templates/enums/templateEnums';
import { SignHelper } from '@/Templates/helpers/SignHelper';
import { IAddMemberLogisticOptionRequest, IBatchAddItemToTempShoppingCartRequest, IDeleteMemberLogisticOptionRequest, IDynamicComponent, IMerchantPortalStyleSetting, IOperationResult, IPortalMerchantThirdPartyPaymentSettingViewModel, IShoppingCartItemViewModel } from '@/Templates/interfaces/templatesInterfaces';
import {
    useAddMemberLogisticOptionApi,
    useBatchAddItemToTempShoppingCartApi,
    useBatchRemoveItemsFromShoppingCartApi,
    useCreatePaymentOrder,
    useDeleteMemberLogisticOptionApi,
    useGetAnonymousLogisticOptionsApi,
    useGetMemberLogisticOptionsApi,
    useGetPaymentMainTypesApi,
    useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi,
    useGetShoppingCartApi,
    useGetTempShoppingCartApi
} from '@/Templates/lib/apis';
import GlobalComponentTemplate from '@/Templates/Shop/GlobalComponents/GlobalComponentTemplate';
import DynamicPageTemplate from '@/Templates/Shop/Pages/DynamicPage/DynamicPageTemplate';
import { ShoppingCartPageTemplateProps, TemplateProps } from '@/Templates/TemplateProps';
import { DeleteOutlined, LaptopOutlined, MobileOutlined, PlusOutlined, TabletOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Empty, Flex, Form, Input, Modal, Row, Space } from 'antd';
import JSONBig from 'json-bigint';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { NavigateOptions, To, useNavigate, useSearchParams } from 'react-router-dom';
import { getWebSiteTemplate } from '../WebSiteTemplates/NormalShop';
import DynamicStyleConfigSelector, { DynamicStyleConfigSelectorRef } from './DynamicStyleConfigSelector';
import PreviewContainer from './PreviewContainer';
import { BasePreviewProps } from './types';

const DynamicPagePreview: React.FC<BasePreviewProps> = ({
    containerwidth,
    previewdevicetype,
    windowdevicetype,
    previewContainerRef,
    handlePreviewDeviceChange,
    getPreviewWidth,
    translate,
    showSeleceTestDataDrawer
}) => {
    const navigate = useNavigate();
    const signHelper = new SignHelper();
    const { generateHelper,
        merchantId, messageApi, notificationApi, modal, merchantPortalOptionSettingMutation } = useContext(GlobalContext);
    const { i18nLanguage } = useContext(TranslationContext);
    const defaultNavBarObj = {
        id: generateHelper.getSnowflakeIdBigInt(),
        name: MerchantPortalStyleShopComponents.NavBar,
        type: MerchantPortalStyleSettingType.Components,
        style: 0,
        styleSetting: {
        },
        merchantId: merchantId!,
        pagePath: '',
        seoDescription: '',
        dynamicComponents: []
    }
    const defaultFooterObj = {
        id: generateHelper.getSnowflakeIdBigInt(),
        name: MerchantPortalStyleShopComponents.Footer,
        type: MerchantPortalStyleSettingType.Components,
        style: 0,
        styleSetting: {
        },
        merchantId: merchantId!,
        pagePath: '',
        seoDescription: '',
        dynamicComponents: []
    }
    const defaultMobileBottomNavigationObj = {
        id: generateHelper.getSnowflakeIdBigInt(),
        name: MerchantPortalStyleShopComponents.MobileBottomNavigation,
        type: MerchantPortalStyleSettingType.Components,
        style: 0,
        styleSetting: {},
        merchantId: merchantId!,
        pagePath: '',
        seoDescription: '',
        dynamicComponents: []
    }
    // 先定義這些狀態
    const [normalPages, setNormalPages] = useState<IMerchantPortalStyleSetting[]>([]);
    const [dynamicPages, setDynamicPages] = useState<IMerchantPortalStyleSetting[]>([]);
    const [navBarPageSetting, setNavBarPageSetting] = useState<IMerchantPortalStyleSetting>(defaultNavBarObj);
    const [footerPageSetting, setFooterPageSetting] = useState<IMerchantPortalStyleSetting>(defaultFooterObj);
    const [mobileBottomNavigationPageSetting, setMobileBottomNavigationPageSetting] = useState<IMerchantPortalStyleSetting>(defaultMobileBottomNavigationObj);
    const [searchParams, setSearchParams] = useSearchParams();
    // 新增狀態追蹤是否有頁面資料
    const [hasNoPageData, setHasNoPageData] = useState<boolean>(false);
    // 然後再定義 selectedPageSetting
    const [selectedPageSetting, setSelectedPageSetting] = useState<IMerchantPortalStyleSetting | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const configSelectorRef = useRef<DynamicStyleConfigSelectorRef>(null);
    const merchantPortalStyleSettingsMutation = useMutation("merchantPortalStyleSettingsMutation", async ({ merchantId, signal }: { merchantId: BigInt, signal?: AbortSignal }) => {
        return await useGetMerchantPortalStyleSettingsApi(merchantId, signal);
    });
    const [sessionId, setSessionId] = useState<BigInt>(generateHelper.getSnowflakeIdBigInt());
    // API調用設置
    const shoppingCartDataMutation = useMutation(["getShoppingCart"], async ({ isLogin }: { isLogin: boolean }) => {
        if (isLogin) {
            return await useGetShoppingCartApi(sessionId);
        }
        else {
            return await useGetTempShoppingCartApi(merchantId!, sessionId);
        }
    });

    const batchDeleteShoppingCartItemsMutation = useMutation(["batchDeleteShoppingCartItems"],
        async (items: IShoppingCartItemViewModel[]) => {
            return await useBatchRemoveItemsFromShoppingCartApi({
                requests: items.map(item => ({
                    shoppingCartItems: [item]
                }))
            });
        }
    );

    const batchAddShoppingCartItemsMutation = useMutation(["batchAddShoppingCartItems"],
        async (items: IShoppingCartItemViewModel[]) => {
            var request: IBatchAddItemToTempShoppingCartRequest = {
                merchantId: merchantId!,
                sessionId: sessionId,
                hash: '',
                addItemToShoppingCartItems: items
            }
            // 調用加入臨時購物車 API
            const st = `${merchantId}:${sessionId.toString()}:${JSONBig.stringify(items)}`;
            const hash = await signHelper.generateDynamicTimeSignAsync(st.toLowerCase(), new Date());
            // 未登錄情況 - 使用臨時購物車API
            return await useBatchAddItemToTempShoppingCartApi({
                ...request,
                hash: hash
            });
        }
    );

    const paymentMainTypesMutation = useMutation(["getPaymentMainTypes"],
        async ({ signal }: { signal?: AbortSignal }) => {
            return await useGetPaymentMainTypesApi(signal);
        }
    );

    const merchantPortalPaymentSettingsMutation = useMutation(
        ["getMerchantPaymentSettings"],
        async ({ merchantId, signal }: { merchantId: BigInt, signal?: AbortSignal }) => {
            return await useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi(merchantId, signal);
        }
    );

    const sendPaymentRequest = useMutation(
        ["createPaymentOrder"],
        async ({ orderId, paymentValues, selectedPayment }: {
            orderId: BigInt,
            paymentValues: Record<string, string>,
            selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined
        }) => {
            if (!selectedPayment) return undefined;
            return await useCreatePaymentOrder({
                orderId: orderId,
                settingId: selectedPayment.thirdPartySettingId,
                frontEndValues: paymentValues
            });
        }
    );

    const memberLogisticOptionsMutation = useMutation(
        ["getMemberLogisticOptions"],
        async ({ isLogin, signal }: { isLogin: boolean, signal?: AbortSignal }) => {
            if (isLogin) {
                return await useGetMemberLogisticOptionsApi(merchantId!, signal);
            }
            else {
                return await useGetAnonymousLogisticOptionsApi(BigInt(0), signal);
            }
        }
    );

    const handleAddMemberLogisticOption = useMutation(
        ["addMemberLogisticOption"],
        async (request: IAddMemberLogisticOptionRequest) => {
            return await useAddMemberLogisticOptionApi(request);
        }
    );

    const handleDeleteMemberLogisticOption = useMutation(
        ["deleteMemberLogisticOption"],
        async (request: IDeleteMemberLogisticOptionRequest) => {
            return await useDeleteMemberLogisticOptionApi(request);
        }
    );

    const shoppingCartPageProps: ShoppingCartPageTemplateProps = {
        shoppingCartDataMutation: shoppingCartDataMutation,
        shoppingCartItemCount: 0,
        batchDeleteShoppingCartItemsMutation: batchDeleteShoppingCartItemsMutation,
        batchAddShoppingCartItemsMutation: batchAddShoppingCartItemsMutation,
        sendGAEvent: function (eventName: string, eventData: any): void {
            throw new Error('Function not implemented.');
        },
        getCountrySelectionDisplayName: function (country: string): string {
            throw new Error('Function not implemented.');
        },
        handleAddMemberLogisticOption: handleAddMemberLogisticOption,
        handleDeleteMemberLogisticOption: handleDeleteMemberLogisticOption,
    };
    const dynamicNavigate = React.useCallback(
        async (to: To | number, options: NavigateOptions = {}) => {
            //if is home path  '/'
            if (to.toString() === '/') {
                var home = [...normalPages, ...dynamicPages].find(page => page.pagePath === '/');
                if (home) {
                    setSelectedPageSetting(home);
                }
                return;
            }
            var destinationSetting = [...normalPages, ...dynamicPages].filter(page => page.pagePath !== '/').find(page => to.toString().includes(page.pagePath));
            if (destinationSetting) {
                //if is /itemDetail need to get query params
                if (to.toString().includes('/itemDetail')) {
                    const url = new URL(to.toString(), 'http://preview.com');
                    const itemId = url.searchParams.get('itemId');

                    if (itemId) {
                        destinationSetting.pagePath = `/itemDetail?itemId=${itemId}`;
                    }
                    var previewItemDetailPageProps = {
                        itemId: itemId ?? undefined,
                        item: undefined,
                        itemSpecs: undefined,
                        handleAddToShoppingCartMutation: undefined,
                        shoppingCartItemCount: 0
                    }
                    //set template props
                    setTemplateProps({
                        ...templateProps,
                        previewItemDetailPageProps: previewItemDetailPageProps,
                    });
                }
                setSelectedPageSetting(destinationSetting);
            }
        }, [normalPages, dynamicPages]
    );
    const [templateProps, setTemplateProps] = useState<TemplateProps>({
        isLogin: false,
        tryGetSessionId: () => sessionId,
        id: selectedPageSetting?.id.toString() ?? '',
        merchantId: merchantId!,
        navBarHeight: 0,
        navBarGap: 0,
        deviceType: previewdevicetype,
        BASE_PATH: '',
        i18nLanguage: i18nLanguage,
        translate: translate,
        merchantPortalOptionSettingMutation: merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation: merchantPortalStyleSettingsMutation,
        messageApi: messageApi,
        notificationApi: notificationApi,
        isPreviewMode: true,
        navigate: dynamicNavigate,
        shoppingCartPageProps: shoppingCartPageProps
    });

    const NavBarComponent = useMemo(() => {
        return <GlobalComponentTemplate
            {...templateProps}
            deviceType={previewdevicetype}
            navigate={dynamicNavigate}
            portalStyleSetting={navBarPageSetting}
            previewContainer={previewContainerRef.current}
        />;
    }, [navBarPageSetting, previewdevicetype, previewContainerRef.current]);

    const DynamicPageTemplateComponent = useMemo(() => {
        if (!selectedPageSetting?.dynamicComponents) {
            return <></>;
        }

        switch (selectedPageSetting.type) {
            case MerchantPortalStyleSettingType.Pages:
            case MerchantPortalStyleSettingType.DynamicPages:
                return <DynamicPageTemplate
                    {...templateProps}
                    deviceType={previewdevicetype}
                    navigate={dynamicNavigate}
                    components={selectedPageSetting.dynamicComponents}
                />;
            default:
                return <></>;
        }
    }, [selectedPageSetting?.dynamicComponents, previewdevicetype, previewContainerRef.current]);

    const FooterComponent = useMemo(() => {
        return <GlobalComponentTemplate
            {...templateProps}
            deviceType={previewdevicetype}
            navigate={dynamicNavigate}
            portalStyleSetting={footerPageSetting}
            previewContainer={previewContainerRef.current}
        />;
    }, [footerPageSetting, previewdevicetype, previewContainerRef.current]);

    const MobileBottomNavigationComponent = useMemo(() => {
        return <GlobalComponentTemplate
            {...templateProps}
            deviceType={previewdevicetype}
            navigate={dynamicNavigate}
            portalStyleSetting={mobileBottomNavigationPageSetting}
            previewContainer={previewContainerRef.current}
        />;
    }, [mobileBottomNavigationPageSetting, previewdevicetype, previewContainerRef.current]);

    const [editForm] = Form.useForm();
    const [createForm] = Form.useForm();

    const editFormValues = Form.useWatch([], editForm);
    const createFormValues = Form.useWatch([], createForm);

    const [editFormValid, setEditFormValid] = useState(false);
    const [createFormValid, setCreateFormValid] = useState(false);

    // 添加編輯模式狀態
    const [isEditingSeo, setIsEditingSeo] = useState(false);

    /**
     * 選擇預設頁面
     * 選擇順序：1. URL 參數指定的頁面 2. 首頁（pagePath="/"） 3. 第一個頁面
     */
    const selectDefaultPage = useCallback((pages: IMerchantPortalStyleSetting[], pageIdFromUrl: string | null) => {
        if (pages.length === 0) return null;

        // 優先選擇 URL 參數指定的頁面
        const pageToSelect = pageIdFromUrl
            ? pages.find(page => page.id.toString() === pageIdFromUrl)
            // 其次尋找首頁（pagePath="/"）
            : pages.find(page => page.pagePath === '/')
            // 最後選擇第一個頁面作為備選
            || pages[0];

        return pageToSelect;
    }, []);

    /**
     * 依據頁面名稱選擇頁面
     * 用於保存後恢復之前選中的頁面
     */
    const selectPageByName = useCallback((pages: IMerchantPortalStyleSetting[], pageName: string) => {
        if (!pageName || pages.length === 0) return null;
        return pages.find(page => page.name === pageName) || null;
    }, []);

    /**
     * 更新 URL 參數並設置選中的頁面
     */
    const updateSelectedPage = useCallback((page: IMerchantPortalStyleSetting | null) => {
        if (!page) return;

        setSelectedPageSetting(page);
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('dynamicPageId', page.id.toString());
            return newParams;
        });
    }, [setSearchParams]);

    useEffect(() => {
        merchantPortalStyleSettingsMutation.mutate({ merchantId: merchantId! });
    }, []);

    useEffect(() => {
        if (merchantPortalStyleSettingsMutation.data?.result) {
            const pages = merchantPortalStyleSettingsMutation.data.result;

            // 檢查是否為空陣列
            if (pages.length === 0) {
                setHasNoPageData(true);
                return;
            }

            setHasNoPageData(false);
            setNormalPages(pages.filter(page => page.type === MerchantPortalStyleSettingType.Pages));
            setDynamicPages(pages.filter(page => page.type === MerchantPortalStyleSettingType.DynamicPages));
            setNavBarPageSetting(pages.find(page => page.name === MerchantPortalStyleShopComponents.NavBar && page.type === MerchantPortalStyleSettingType.Components) ?? defaultNavBarObj);
            setFooterPageSetting(pages.find(page => page.name === MerchantPortalStyleShopComponents.Footer && page.type === MerchantPortalStyleSettingType.Components) ?? defaultFooterObj);

            // 使用選擇預設頁面的函數
            if (!selectedPageSetting && pages.length > 0) {
                const pageId = searchParams.get('dynamicPageId');
                const pageToSelect = selectDefaultPage(pages, pageId);
                updateSelectedPage(pageToSelect ?? null);
            }
        }
    }, [merchantPortalStyleSettingsMutation.data?.result, selectDefaultPage, updateSelectedPage]);

    useEffect(() => {
        if (selectedPageSetting) {
            editForm.setFieldsValue({
                name: selectedPageSetting.name,
                pagePath: selectedPageSetting.pagePath,
                seoDescription: selectedPageSetting.seoDescription || ''
            });
        }
    }, [selectedPageSetting]);

    useEffect(() => {
        createForm.validateFields({ validateOnly: true })
            .then(() => setCreateFormValid(true))
            .catch(() => setCreateFormValid(false));
    }, [createForm, createFormValues]);

    useEffect(() => {
        editForm
            .validateFields({ validateOnly: true })
            .then((result) => {
                setEditFormValid(true)
            })
            .catch((error) => {
                setEditFormValid(false);
            });
    }, [editForm, editFormValues]);

    // 當 API 數據更新時，更新臨時狀態
    useEffect(() => {
        if (merchantPortalStyleSettingsMutation.data?.result) {
            const dynamicPages = merchantPortalStyleSettingsMutation.data.result
                .filter(page => page.type === MerchantPortalStyleSettingType.DynamicPages);
            setDynamicPages(dynamicPages);
        }
    }, [merchantPortalStyleSettingsMutation.data?.result]);

    const handleConfigUpdate = (updatedComponents: IDynamicComponent[]) => {
        if (!selectedPageSetting) return;
        const updatedPageSetting = {
            ...selectedPageSetting,
            dynamicComponents: updatedComponents
        };

        // 根據頁面類型更新對應的陣列
        if (selectedPageSetting.type === MerchantPortalStyleSettingType.Pages) {
            const updatedNormalPages = normalPages.map(page =>
                page.id === selectedPageSetting.id ? updatedPageSetting : page
            );
            setNormalPages(updatedNormalPages);
        } else if (selectedPageSetting.type === MerchantPortalStyleSettingType.DynamicPages) {
            const updatedDynamicPages = dynamicPages.map(page =>
                page.id === selectedPageSetting.id ? updatedPageSetting : page
            );
            setDynamicPages(updatedDynamicPages);
        }

        setSelectedPageSetting(updatedPageSetting);
    };

    const handleNavBarConfigUpdate = (config: Record<string, string>) => {
        setNavBarPageSetting({
            ...navBarPageSetting,
            styleSetting: config
        });
    };

    const handleFooterConfigUpdate = (config: Record<string, string>) => {
        setFooterPageSetting({
            ...footerPageSetting,
            styleSetting: config
        });
    };

    const handleDeleteDynamicPage = useMutation("handleDeleteDynamicPage", async () => {
        return new Promise<IOperationResult | null>((resolve, reject) => {
            modal.confirm({
                title: translate('Are you sure you want to delete this page?'),
                onOk: async () => {
                    try {
                        if (!selectedPageSetting) {
                            reject(new Error('No page selected'));
                            return;
                        }
                        const response = await useDeletePortalStyleApi({
                            id: selectedPageSetting.id,
                        });
                        resolve(response);
                    } catch (error) {
                        reject(error);
                    }
                },
                onCancel: () => {
                    reject(new Error('Operation cancelled'));
                }
            });
        });
    }, {
        onSuccess: (response: IOperationResult | null) => {
            if (response?.isSuccess) {
                messageApi.success(translate('Operation success'));
                merchantPortalStyleSettingsMutation.mutate({ merchantId: merchantId! });
            }
            else {
                messageApi.error(translate(response?.message ?? 'Operation failed'));
            }
        },
        onError: (error: any) => {
            if (error.message !== 'Operation cancelled') {
                messageApi.error(translate(error.message ?? 'Operation failed'));
            }
        }
    });

    const configSelectorComponent = useMemo(() => (
        <DynamicStyleConfigSelector
            ref={configSelectorRef}
            pageSetting={selectedPageSetting}
            isMobile={windowdevicetype !== DeviceType.Desktop}
            onConfigUpdate={handleConfigUpdate}
            onNavBarConfigUpdate={handleNavBarConfigUpdate}
            onFooterConfigUpdate={handleFooterConfigUpdate}
            navBarPageSettingProps={navBarPageSetting}
            footerPageSettingProps={footerPageSetting}
        />
    ), [selectedPageSetting, windowdevicetype]);

    const addDynamicPageMutation = useMutation({
        mutationKey: ["addDynamicPage"],
        mutationFn: async (values: { name: string, pagePath: string, seoDescription: string }) => {
            return await addDynamicPagePortalStyleApi({
                id: generateHelper.getSnowflakeIdBigInt(),
                type: MerchantPortalStyleSettingType.DynamicPages,
                name: values.name,
                style: 0,
                styleSetting: {},
                pagePath: values.pagePath,
                seoDescription: values.seoDescription,
                dynamicComponents: [],
            });
        },
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                merchantPortalStyleSettingsMutation.mutate({ merchantId: merchantId! }, {
                    onSuccess: (response) => {
                        if (response.isSuccess) {
                            setSelectedPageSetting(response.result?.at(-1) ?? null);
                        } else {
                            messageApi.error(translate(response?.message ?? 'Operation failed'));
                        }
                    }
                });
                setIsModalOpen(false);
                createForm.resetFields();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        },
        onError: (error: any) => {
            messageApi.error(translate(error.message || 'Operation failed'));
        }
    });

    const handleAddDynamicPage = async () => {
        try {
            await createForm.validateFields();
            await addDynamicPageMutation.mutateAsync({
                name: createForm.getFieldValue('name'),
                pagePath: createForm.getFieldValue('newPagePath'),
                seoDescription: createForm.getFieldValue('newSeoDescription')
            });
        } catch (error) {
            if (error instanceof Error) {
                messageApi.error(error.message);
            }
        }
    };

    const handleSave = async () => {
        if (!selectedPageSetting) return;

        try {
            const response = await useUpsertPagesPortalStylesApi({
                requests: [
                    ...dynamicPages,
                    ...normalPages,
                    navBarPageSetting,
                    footerPageSetting,
                    mobileBottomNavigationPageSetting
                ],
            });

            if (response.isSuccess) {
                messageApi.success(translate('Operation successful'));

                // 保存當前選中頁面的 name
                const currentSelectedPageName = selectedPageSetting.name;

                // 重新加載數據
                merchantPortalStyleSettingsMutation.mutate({ merchantId: merchantId! }, {
                    onSuccess: (response) => {
                        if (response.isSuccess && response.result) {
                            // 使用選擇頁面函數
                            const allPages = response.result;
                            const pageToSelect = selectPageByName(allPages, currentSelectedPageName);
                            updateSelectedPage(pageToSelect);
                        }
                    }
                });
            } else {
                messageApi.error(response.message || translate('Operation failed'));
            }
        } catch (error) {
            messageApi.error(translate('Operation failed'));
        }
    };

    const handlePageSelect = (value: string) => {
        // 從所有頁面中查找選中的頁面
        const selectedPage = [...normalPages, ...dynamicPages].find(
            (page) => page.id.toString() === value
        ) || null;

        setSelectedPageSetting(selectedPage);
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('dynamicPageId', value);
            return newParams;
        });
    };

    const renderDeviceTypeButtons = () => (
        <Flex justify="center" align="center">
            <Button
                icon={<MobileOutlined />}
                onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                type={previewdevicetype === DeviceType.Mobile ? 'primary' : 'default'}
            >
                {translate('Mobile')}
            </Button>
            <Button
                icon={<TabletOutlined />}
                onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                type={previewdevicetype === DeviceType.LapTop ? 'primary' : 'default'}
            >
                {translate('Laptop')}
            </Button>
            <Button
                icon={<LaptopOutlined />}
                onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                type={previewdevicetype === DeviceType.Desktop ? 'primary' : 'default'}
            >
                {translate('Desktop')}
            </Button>
        </Flex>
    );

    const renderPreviewContainer = () => (
        <Flex justify="center" align="center">
            <PreviewContainer
                containerwidth={containerwidth}
                previewwidth={getPreviewWidth()}
                previewdevicetype={previewdevicetype}
                windowdevicetype={windowdevicetype}
            >
                <div style={{
                    width: `${getPreviewWidth()}px`,
                    minHeight: '100%',
                    position: 'relative',
                    transform: 'none',
                    left: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    overflow: 'auto',
                    height: '100%',
                    paddingBottom: previewdevicetype === DeviceType.Mobile ? '56px' : '0'
                }}>

                    <div ref={previewContainerRef} style={{ flex: 1, overflowY: 'auto' }}>
                        <div style={{ position: 'sticky', top: 0, width: '100%', zIndex: 30 }}>
                            {NavBarComponent}
                        </div>
                        {DynamicPageTemplateComponent}
                        {FooterComponent}
                        {MobileBottomNavigationComponent}
                    </div>
                </div>
            </PreviewContainer>
        </Flex>
    );

    const renderConfiguratorForDesktop = () => (
        <Col span={8}>
            <Flex justify="center" align="center" style={{ padding: '10px' }} gap="20px">
                {configSelectorComponent}
            </Flex>
        </Col>
    );

    const renderConfiguratorForMobile = () => (
        <>
            <Button
                type="primary"
                onClick={() => setIsDrawerOpen(true)}
                style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}
            >
                {translate('Configure Components')}
            </Button>
            <Drawer
                title={translate('Component Settings')}
                placement="right"
                width="100%"
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
                style={{ position: 'absolute' }}
                contentWrapperStyle={{ position: 'absolute' }}
                mask={false}
                bodyStyle={{
                    overflow: 'visible',
                    height: '100vh',
                    padding: 0
                }}
            >
                <div className="h-full overflow-auto p-4">
                    {configSelectorComponent}
                </div>
            </Drawer>
        </>
    );

    const renderSelectedPageContent = () => (
        <>
            <Col span={24} style={{ marginBottom: 16 }}>
                {renderDeviceTypeButtons()}
            </Col>
            <Col span={windowdevicetype === DeviceType.Desktop ? 16 : 24} style={{ marginBottom: windowdevicetype === DeviceType.Desktop ? 0 : 16 }}>
                {renderPreviewContainer()}
            </Col>
            {windowdevicetype === DeviceType.Desktop ? renderConfiguratorForDesktop() : renderConfiguratorForMobile()}
            <Col span={24} style={{ marginTop: 16 }}>
                <Flex justify="center" align="center">
                    <Button
                        type="primary"
                        onClick={handleSave}
                        disabled={!editFormValid}
                    >
                        {translate('Save')}
                    </Button>
                </Flex>
            </Col>
        </>
    );

    const validatePagePath = (_: any, value: string) => {
        if (!value.startsWith('/')) {
            return Promise.reject(new Error(translate('Page path must start with /')));
        }
        return Promise.resolve();
    };

    const handleFormValueChange = (changedValues: any) => {
        console.log(`changedValues: ${changedValues}`);
        if (!selectedPageSetting) return;

        const updatedPageSetting = {
            ...selectedPageSetting,
            ...changedValues
        };


        // 根據頁面類型更新對應的陣列
        if (selectedPageSetting.type === MerchantPortalStyleSettingType.Pages) {
            setNormalPages(prevPages =>
                prevPages.map(page =>
                    page.id === selectedPageSetting.id ? updatedPageSetting : page
                )
            );
        } else if (selectedPageSetting.type === MerchantPortalStyleSettingType.DynamicPages) {
            setDynamicPages(prevPages =>
                prevPages.map(page =>
                    page.id === selectedPageSetting.id ? updatedPageSetting : page
                )
            );
        }

        setSelectedPageSetting(updatedPageSetting);
    };

    const renderEditForm = () => {
        const isDynamicPage = selectedPageSetting?.type === MerchantPortalStyleSettingType.DynamicPages;
        const baseUrl = window.location.origin;

        return (
            <Form
                form={editForm}
                layout="vertical"
                initialValues={{
                    pagePath: editFormValues.pagePath,
                    name: editFormValues.name,
                    seoDescription: selectedPageSetting?.seoDescription || ''
                }}
                onValuesChange={handleFormValueChange}
            >
                <Form.Item
                    name="name"
                    label={translate('Page Name')}
                    rules={[{ required: true, message: translate('Name is required') }]}
                >
                    <Input
                        placeholder={translate('Enter page name')}
                        disabled={!isDynamicPage}
                    />
                </Form.Item>

                {/* SEO 預覽與編輯區域 - 只在動態頁面時顯示 */}
                {isDynamicPage && (
                    <Card
                        type='inner'
                        title={
                            <Flex justify="space-between" align="center">
                                <span>{translate('搜尋引擎優化')}</span>
                                <Button
                                    type="link"
                                    onClick={() => setIsEditingSeo(!isEditingSeo)}
                                >
                                    {isEditingSeo ? translate('隱藏') : translate('編輯 SEO 內容')}
                                </Button>
                            </Flex>
                        }
                        style={{ marginBottom: '20px' }}
                    >
                        <div style={{ color: '#666', fontSize: '14px', marginBottom: '15px' }}>
                            {translate('在此編輯網址及網頁內容敘述，提升您品牌搜尋排名！編輯完後您可以在此直接預覽。')}
                        </div>

                        {/* 搜尋引擎預覽 - 始終顯示 */}
                        <Card bordered={false} className="search-preview-card" style={{ borderRadius: '8px', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', marginBottom: isEditingSeo ? '20px' : '0' }}>
                            <div style={{ marginBottom: '8px' }}>
                                <div style={{ color: '#1a0dab', fontSize: '18px', fontWeight: 'normal', marginBottom: '3px', cursor: 'pointer' }}>
                                    {editForm.getFieldValue('name') || translate('Page Title')}
                                </div>
                                <div style={{ color: '#006621', fontSize: '14px', marginBottom: '3px' }}>
                                    {baseUrl}{editForm.getFieldValue('pagePath') || '/pagepath'}
                                </div>
                            </div>
                            <div style={{ color: '#545454', fontSize: '14px', lineHeight: '1.4' }}>
                                {editForm.getFieldValue('seoDescription') || translate('這裡將會顯示您的SEO描述，幫助提高您網站在搜尋引擎的可見度。')}
                            </div>
                        </Card>

                        {/* 編輯區域 - 僅在編輯模式顯示 */}
                        {isEditingSeo && (
                            <div style={{ padding: '15px 0' }}>
                                <Form.Item
                                    name="pagePath"
                                    label={translate('編輯 URL')}
                                    rules={[
                                        { required: true, message: translate('Page path is required') },
                                        { validator: validatePagePath }
                                    ]}
                                >
                                    <Input
                                        addonBefore={baseUrl}
                                        placeholder={translate('Enter page path')}
                                        disabled={!isDynamicPage}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="seoDescription"
                                    label={translate('網頁敘述')}
                                >
                                    <Input.TextArea
                                        maxLength={250}
                                        placeholder={translate('Enter SEO Description')}
                                        disabled={!isDynamicPage}
                                        rows={4}
                                    />
                                </Form.Item>
                                <div style={{ textAlign: 'right', fontSize: '12px', color: '#999' }}>
                                    {editForm.getFieldValue('seoDescription')?.length || 0} / 250
                                </div>
                            </div>
                        )}
                    </Card>
                )}
            </Form>
        );
    };

    const applyDefaultValuesToComponents = useCallback(async (components: IDynamicComponent[]) => {
        const processedComponents = await Promise.all(components.map(async (component) => {
            try {
                // 動態導入模板
                const componentModule = await import(
                    `@/Templates/Shop/Components/${component.name}/Template_${component.style}.tsx`
                );


                // 獲取配置字段
                const configFields = componentModule.getConfigFields ?
                    componentModule.getConfigFields(
                        merchantPortalOptionSettingMutation?.data?.result?.merchantName || '',
                        translate,
                        i18nLanguage
                    ) : [];

                // 設置默認值
                const defaultStyleConfig: Record<string, string> = {};
                configFields.forEach((field) => {
                    if (field.defaultValue !== undefined) {
                        defaultStyleConfig[field.key] = field.defaultValue.toString();
                    }
                });

                return {
                    ...component,
                    styleConfig: defaultStyleConfig,
                    values: defaultStyleConfig
                };
            } catch (error) {
                console.error(`Failed to load template for component ${component.name}:`, error);
                return {
                    ...component,
                };
            }
        }));

        console.log('processedComponents', processedComponents);

        return processedComponents;
    }, [merchantPortalOptionSettingMutation?.data?.result?.merchantName, translate, i18nLanguage]);

    const handleApplyTemplate = useMutation("handleApplyTemplate", async () => {
        return new Promise<IOperationResult | null>((resolve, reject) => {
            modal.confirm({
                title: translate('Apply Website Template'),
                content: translate('This will overwrite all current pages. Are you sure you want to continue?'),
                okText: translate('Yes'),
                cancelText: translate('No'),
                onOk: async () => {
                    try {
                        const templatePages = getWebSiteTemplate(translate, merchantId!);

                        // 為每個頁面的組件套用預設值，保留原有的 ID
                        const pagesWithDefaults = await Promise.all(templatePages.map(async page => ({
                            ...page,
                            dynamicComponents: await applyDefaultValuesToComponents(page.dynamicComponents || [])
                        })));

                        // 更新 normalPages
                        setNormalPages(pagesWithDefaults);

                        if (pagesWithDefaults.length > 0) {
                            setSelectedPageSetting(pagesWithDefaults[0]);
                            setSearchParams(prev => {
                                const newParams = new URLSearchParams(prev);
                                newParams.set('dynamicPageId', pagesWithDefaults[0].id.toString());
                                return newParams;
                            });
                        }

                        resolve({ isSuccess: true });
                    } catch (error) {
                        reject(error);
                    }
                },
                onCancel: () => {
                    reject(new Error('Operation cancelled'));
                }
            });
        });
    }, {
        onSuccess: (response: IOperationResult | null) => {
            if (response?.isSuccess) {
                messageApi.success(translate('Template applied successfully'));
            } else {
                messageApi.error(translate(response?.message ?? 'Failed to apply template'));
            }
        },
        onError: (error: any) => {
            if (error.message !== 'Operation cancelled') {
                messageApi.error(translate(error.message ?? 'Failed to apply template'));
            }
        }
    });

    // 修改頁面選擇器的渲染部分
    const renderPageSelector = () => (
        <Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Card
                        title={translate('Template Pages')}
                        size="small"
                        style={{ height: '100%' }}
                        extra={
                            <Button
                                type="primary"
                                onClick={() => handleApplyTemplate.mutate()}
                            >
                                {translate('Apply Template')}
                            </Button>
                        }
                    >
                        {normalPages.length > 0 ? (
                            <Space direction="vertical" style={{ width: '100%' }}>
                                {normalPages.map((page) => (
                                    <Button
                                        key={page.id.toString()}
                                        type={selectedPageSetting?.id === page.id ? 'primary' : 'link'}
                                        onClick={() => handlePageSelect(page.id.toString())}
                                        style={{ width: '100%', textAlign: 'left' }}
                                    >
                                        {translate(page.name)}
                                    </Button>
                                ))}
                            </Space>
                        ) : (
                            <Empty description={translate('No template pages')} />
                        )}
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        title={translate('Custom Pages')}
                        size="small"
                        style={{ height: '100%' }}
                        extra={
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setIsModalOpen(true)}
                                loading={addDynamicPageMutation.isLoading}
                            >
                                {translate('Add')}
                            </Button>
                        }
                    >
                        {dynamicPages.length > 0 ? (
                            <Space direction="vertical" style={{ width: '100%' }}>
                                {dynamicPages.map((page) => (
                                    <Flex key={page.id.toString()} justify="space-between" align="center">
                                        <Button
                                            type={selectedPageSetting?.id === page.id ? 'primary' : 'link'}
                                            onClick={() => handlePageSelect(page.id.toString())}
                                            style={{ textAlign: 'left' }}
                                        >
                                            {translate(page.name)}
                                        </Button>
                                        {selectedPageSetting?.id === page.id && (
                                            <Button
                                                type="text"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => handleDeleteDynamicPage.mutate()}
                                                loading={handleDeleteDynamicPage.isLoading}
                                            />
                                        )}
                                    </Flex>
                                ))}
                            </Space>
                        ) : (
                            <Empty description={translate('No custom pages')} />
                        )}
                    </Card>
                </Col>
            </Row>
        </Form.Item>
    );

    // 新增無頁面資料提示組件
    const renderNoTemplatePrompt = () => (
        <Col span={24}>
            <Card
                style={{
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: 16,
                    padding: 24
                }}
            >
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <div style={{ fontSize: 18, fontWeight: 'bold', color: '#404040' }}>
                            {i18nLanguage === 'zh_TW'
                                ? '尚未套用任何版型'
                                : 'No template applied yet'}
                        </div>
                    }
                >
                    <div style={{ marginBottom: 24, color: '#666' }}>
                        {i18nLanguage === 'zh_TW'
                            ? '請先套用版型以開始編輯您的網站'
                            : 'Please apply a template to start editing your website'}
                    </div>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        onClick={() => handleApplyTemplate.mutate()}
                    >
                        {i18nLanguage === 'zh_TW'
                            ? '套用版型'
                            : 'Apply Template'}
                    </Button>
                </Empty>
            </Card>
        </Col>
    );

    return (
        <div>
            {hasNoPageData ? (
                <Row>
                    {renderNoTemplatePrompt()}
                </Row>
            ) : (
                <Row style={{ marginBottom: 16 }}>
                    <Col span={24} style={{ marginBottom: 16 }}>
                        <Flex justify="center" align="center">
                            <Card style={{ width: '100%', maxWidth: 800 }}>
                                {renderPageSelector()}
                                {selectedPageSetting && renderEditForm()}
                            </Card>
                        </Flex>
                    </Col>
                    {!selectedPageSetting && (
                        <Col span={24} style={{ marginBottom: 16 }}>
                            <Empty description={translate('Please select a page')} />
                        </Col>
                    )}
                    {selectedPageSetting && renderSelectedPageContent()}
                </Row>
            )}

            <Modal
                title={translate('Add New Page')}
                open={isModalOpen}
                onOk={() => {
                    createForm.validateFields()
                        .then(() => handleAddDynamicPage())
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    createForm.resetFields();
                    setCreateFormValid(false);
                }}
                confirmLoading={addDynamicPageMutation.isLoading}
                okButtonProps={{ disabled: !createFormValid }}
            >
                <Form
                    form={createForm}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label={translate('Page Name')}
                        rules={[{ required: true, message: translate('is required') }]}
                    >
                        <Input
                            placeholder={translate('Page Name')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="newPagePath"
                        label={translate('Page Path')}
                        rules={[
                            { required: true, message: translate('is required') },
                            { validator: validatePagePath }
                        ]}
                    >
                        <Input
                            placeholder={translate('Page Path')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="newSeoDescription"
                        label={translate('SEO Description')}
                    >
                        <Input
                            placeholder={translate('SEO Description')}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default DynamicPagePreview;