import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { FrontEndDisplayType } from "@/Templates/enums/templateEnums";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { Empty, Flex, Popover } from "antd";
import { useContext } from "react";

export interface IPrinterPopoverProps {
    style?: React.CSSProperties;
    displayType: FrontEndDisplayType;
}
const PrinterPopover = (props: IPrinterPopoverProps) => {
    const { translate } = useContext(TranslationContext);
    const supportedFrontEndDisplayType = [
        FrontEndDisplayType.PrinterEscPos,
        FrontEndDisplayType.PrinterStarMicronics
    ];
    const content = (displayType: FrontEndDisplayType) => {
        switch (displayType) {
            case FrontEndDisplayType.PrinterEscPos:
                return <div>Printer</div>;
            default:
                return <Empty description={translate('No printer information')} />
        }
    }

    //if displayType not in PrinterEscPos  PrinterStarMicronics 
    if (!supportedFrontEndDisplayType.includes(props.displayType))
        return null;

    return (
        <Flex style={{ ...props.style, alignItems: 'center' }}>
            <Popover
                content={content(props.displayType)}
                title={translate('Printer Information')}
                style={props.style}>
                <InfoCircleTwoTone />
            </Popover>
        </Flex>
    );
};

export default PrinterPopover;
