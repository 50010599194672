import { ICreateMerchantRequest } from "@/interfaces/Requests/Requests";
import { IMerchant } from "@/interfaces/Responses/Responses";
import { useCreateMerchantApi } from "@/lib/api/merchants";
import { useGetSupportedCountriesApi } from "@/lib/api/portals";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Modal, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

interface ApplyNewMerchantProps {
    accessibleMerchants: IMerchant[] | undefined;
}

const ApplyNewMerchant: React.FC<ApplyNewMerchantProps> = ({ accessibleMerchants }) => {
    const { modal } = useContext(GlobalContext);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();
    const { messageApi, getUserInfoMutation, getAccessibleMerchants } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    // 获取支持的国家列表
    const { data: supportedCountry, mutate: supportedCountryMutate, isLoading: supportedCountryLoading } = useMutation(useGetSupportedCountriesApi);

    // 创建新商户的请求
    const { mutate: createMerchant, isLoading: isCreateMerchantLoading } = useMutation(useCreateMerchantApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success('success');
                setIsModalVisible(false);
                form.resetFields();
                getAccessibleMerchants.mutate();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });


    const showModal = () => {
        supportedCountryMutate();
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                const selectedCountry = values.country;
                const merchantName = values.merchantName;
                modal.confirm({
                    title: translate('Confirm'),
                    content: (
                        <div>
                            <p>{`${translate('Country')}: ${translate(`Country.${selectedCountry}`)}`}</p>
                            <p>{`${translate('Merchant Name')}: ${merchantName}`}</p>
                            <p>{translate('Is this correct?')}</p>
                        </div>
                    ),
                    onOk() {
                        createMerchant(values as ICreateMerchantRequest);
                    },
                    onCancel() { },
                    okText: translate('Yes'),
                    cancelText: translate('No'),
                });
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    useEffect(() => {
        getUserInfoMutation.mutate();
    }, []);


    if (!getUserInfoMutation.data?.result?.memberId) {
        return null;
    }

    // 检查 accessibleMerchants 是否存在
    const ownedMerchants = accessibleMerchants?.filter(
        r => r.ownerBackOfficeMemberId?.toString() === getUserInfoMutation.data?.result?.memberId?.toString()
    ) || [];

    const canCreateMerchant = ownedMerchants.length < 5;

    if (!canCreateMerchant) {
        return null;
    }

    return (
        <>
            <Flex justify="center" align="center">
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModal}
                    style={{ marginLeft: '8px' }}
                >
                    {translate('Create new merchant')}
                </Button>
            </Flex>

            <Modal
                title={translate('Create new merchant')}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        label={translate('Country')}
                        name="country"
                        rules={[{ required: true, message: translate('Please select country') }]}
                    >
                        <Select loading={supportedCountryLoading}>
                            {supportedCountry?.result?.filter(r => r !== 'Unset')?.map(country => (
                                <Select.Option key={country} value={country}>
                                    {translate(`Country.${country}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={translate('Merchant Name')}
                        name="merchantName"
                        rules={[{ required: true, message: translate('Please input merchant name') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                        <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                            {translate('Cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isCreateMerchantLoading}>
                            {translate('Submit')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ApplyNewMerchant;
