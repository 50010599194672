import { Button, Flex, Form, Typography } from "antd";
import { ProTable } from '@ant-design/pro-components';
import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { ProColumns } from '@ant-design/pro-components';
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { IMemberInfoViewModel } from "@/interfaces/Responses/Responses";
import { IGetMemberInfoViewModelByMerchantRequest } from "@/interfaces/Requests/Requests";
import { LoginTypeEnum } from "@/Templates/enums/templateEnums";

const { Text } = Typography;

interface MemberListTabProps {
    memberInfoVMs: IMemberInfoViewModel[];
    totalCount: number;
    queryRequest: IGetMemberInfoViewModelByMerchantRequest;
    initialQueryRequest: IGetMemberInfoViewModelByMerchantRequest;
    isLoading: boolean;
    expandedRowKeys: string[];
    actionRef: any;
    formRef: any;
    handleSearch: () => void;
    handleTableChange: (pagination: any) => void;
    updateQueryStringWithoutSearch: (values: any) => void;
    setExpandedRowKeys: (keys: string[]) => void;
}

const MemberListTab: React.FC<MemberListTabProps> = ({
    memberInfoVMs,
    totalCount,
    queryRequest,
    initialQueryRequest,
    isLoading,
    expandedRowKeys,
    actionRef,
    formRef,
    handleSearch,
    handleTableChange,
    updateQueryStringWithoutSearch,
    setExpandedRowKeys
}) => {
    const { translate } = useContext(TranslationContext);
    const abortController = useRef<AbortController | null>(null);
    const navigate = useNavigate();
    const columns: ProColumns<IMemberInfoViewModel>[] = [
        {
            title: translate('Member ID'),
            dataIndex: 'memberId',
            width: 'auto',
            align: 'center',
            render: (text, record) => record.id.toString(),
            fixed: 'left',
        },
        {
            title: translate('UserName'),
            dataIndex: 'userName',
            width: 'auto',
            align: 'center',
            ellipsis: true,
        },
    ];

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current?.abort();
        };
    }, []);

    return (
        <ProTable<IMemberInfoViewModel, IGetMemberInfoViewModelByMerchantRequest>
            columns={columns}
            actionRef={actionRef}
            formRef={formRef}
            ghost={true}
            cardBordered
            dataSource={memberInfoVMs}
            rowKey="id"
            pagination={{
                total: totalCount,
                pageSize: queryRequest.pageSize,
                current: queryRequest.page,
                showQuickJumper: true,
                showSizeChanger: true,
            }}
            search={{
                labelWidth: 'auto',
                defaultCollapsed: false,
                optionRender: (searchConfig, formProps, dom) => [...dom.reverse()],
            }}
            onSubmit={handleSearch}
            onChange={handleTableChange}
            form={{
                initialValues: initialQueryRequest,
                onValuesChange: (changedValues, allValues) => {
                    updateQueryStringWithoutSearch(allValues);
                },
            }}
            toolBarRender={false}
            loading={isLoading}
            expandable={{
                expandedRowKeys: expandedRowKeys,
                onExpandedRowsChange: (expandedRows) => {
                    setExpandedRowKeys(expandedRows as string[]);
                },
                expandedRowRender: (record: IMemberInfoViewModel) => (
                    <div style={{ padding: '16px 24px', background: '#fafafa' }}>
                        {/* 基本資訊區塊 */}
                        <div style={{
                            marginBottom: 16,
                            padding: 20,
                            background: '#fff',
                            borderRadius: 8,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.06)'
                        }}>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                gap: 32
                            }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                                    <Text strong style={{ fontSize: '16px', marginBottom: '8px' }}>
                                        {translate('Basic Information')}
                                    </Text>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Email')}:</span>
                                        <span>{record.email}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Login Type')}:</span>
                                        <span>{record.loginType ? translate(record.loginType) : translate('Not Set')}</span>
                                    </div>
                                    {record.loginType === LoginTypeEnum.ThirdPartyLogin && (
                                        <div style={{ display: 'flex', gap: 8 }}>
                                            <span style={{ color: '#666', width: 120 }}>{translate('Third Party Login Providers')}:</span>
                                            <span>{record.thirdPartyLoginProviders}</span>
                                        </div>
                                    )}
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Phone number')}:</span>
                                        <span>{record.phoneNumber}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Gender')}:</span>
                                        <span>{record.gender ? translate(record.gender) : translate('Not Set')}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Birthday')}:</span>
                                        <span>{record.birthday.toLocaleString()}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Registration Date')}:</span>
                                        <span>{record.registDate.toLocaleString()}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Total Consumption')}:</span>
                                        <span>{record.totalConsumption}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Total Order Count')}:</span>
                                        <span>{record.totalOrderCount}</span>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        <span style={{ color: '#666', width: 120 }}>{translate('Banned')}:</span>
                                        <span>{record.isBanned ? translate("Yes") : translate("No")}</span>
                                    </div>
                                </div>
                            </div>

                            <Flex justify="center" style={{ marginTop: '20px' }}>
                                <Button
                                    type="primary"
                                    onClick={() => navigate(`/orderManage?memberId=${record.id}`)}
                                >
                                    {translate('View Orders')}
                                </Button>
                            </Flex>
                        </div>

                        {/* 會員等級區塊 */}
                        <div style={{
                            padding: 20,
                            background: '#fff',
                            borderRadius: 8,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.06)'
                        }}>
                            <Text strong style={{ fontSize: '16px', marginBottom: '8px', display: 'block' }}>
                                {translate('Member Level')}
                            </Text>
                            <div style={{ marginTop: '12px' }}>
                                {"Member Consumption Level"}
                            </div>
                        </div>
                    </div>
                ),
            }}
        />
    );
};

export default MemberListTab; 