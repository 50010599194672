import React, { memo, useCallback, useEffect } from 'react';
import { Handle, Position, useNodeId, useNodes, useNodesData, useNodesState } from '@xyflow/react';
import { INodeData } from '../../AIFlowDesignPage';

type StickyNodeProps = {
  data: INodeData;
};

const StickyNode = memo(({ data }: StickyNodeProps) => {

  return (
    <div
      className="sticky-node"
      style={{
        backgroundColor: '#fff9c4',
        borderRadius: '8px',
        padding: '16px',
        minWidth: '200px',
        minHeight: '150px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        transition: 'all 0.2s ease',
      }}
    >
      <Handle type="target" position={Position.Top} />
      <textarea
        defaultValue={data?.label as string || ''}
        onChange={(e) => {
          data.onChange(data.id, 'label', e.target.value);
        }}
        placeholder="輸入便利貼內容"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          background: 'transparent',
          resize: 'none',
          fontFamily: 'inherit',
          fontSize: '14px',
          outline: 'none',
        }}
      />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
});

export default StickyNode; 