import { useState, useCallback } from 'react';

export interface AIConfig {
    apiKey?: string;
    model?: string;
    systemMessage?: string;
}

export interface AIResponse {
    loading: boolean;
    error: string | null;
    response: string;
    sendRequest: (prompt: string) => Promise<void>;
}

const useAIService = ({
    apiKey = '',
    model = 'deepseek-chat',
    systemMessage = '你是一個有用的助手'
}: AIConfig = {}): AIResponse => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [response, setResponse] = useState('');

    const sendRequest = useCallback(async (prompt: string) => {
        setLoading(true);
        setError(null);
        setResponse('');

        try {
            const response = await fetch('https://api.deepseek.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify({
                    model,
                    messages: [
                        { role: 'system', content: systemMessage },
                        { role: 'user', content: prompt }
                    ],
                    stream: true
                })
            });

            if (!response.ok) {
                throw new Error(`API請求失敗: ${response.status} ${response.statusText}`);
            }

            const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader();
            if (!reader) throw new Error('無法創建響應流讀取器');

            let fullResponse = '';
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const lines = value.split('\n');
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const data = line.slice(6);
                        if (data === '[DONE]') continue;

                        try {
                            const json = JSON.parse(data);
                            const content = json.choices[0]?.delta?.content || '';
                            fullResponse += content;
                            setResponse(prev => prev + content);
                        } catch (e) {
                            console.error('解析響應數據失敗:', e);
                        }
                    }
                }
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : '未知錯誤');
        } finally {
            setLoading(false);
        }
    }, [apiKey, model, systemMessage]);

    return { loading, error, response, sendRequest };
};

export default useAIService;