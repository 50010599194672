import React, { useState, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import {
    ReactFlow,
    Background,
    Controls,
    MiniMap,
    addEdge,
    Connection,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    ReactFlowProvider,
    applyEdgeChanges,
    applyNodeChanges,
    Handle,
    Position,
    useReactFlow,
    useNodes,
    useEdges,
    useViewport,
    Panel,
    useEdgesState,
    useNodesState,
    NodeTypes
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Layout, Menu, Button, Flex, Modal, Badge, Dropdown, Card } from 'antd';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PlusOutlined,
    FileTextOutlined,
    PictureOutlined,
    VideoCameraOutlined,
    AudioOutlined,
    BarChartOutlined,
    PieChartOutlined,
    LineChartOutlined,
    FileOutlined,
    LinkOutlined,
    CopyOutlined,
    DeleteOutlined,
    FormOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { createRoot } from 'react-dom/client';
import { memo } from 'react';
import { DeviceType } from '@/Templates/enums/templateEnums';
import StickyNode from './Components/Nodes/StickyNode';
import './AIFlowDesignPage.css';
import DeepSeekChat from './Components/DeepSeekChat';

const { Sider, Content } = Layout;

export interface INodeData {
    id: string;
    label: string;
    onChange: (nodeId: string, key: string, value: any) => void;
}

const initialNodes: Node[] = [
    {
        id: '1',
        type: 'input',
        data: { label: '開始' },
        position: {
            x: isNaN(250) ? 0 : 250,
            y: isNaN(0) ? 0 : 0
        },
    },
    {
        id: '2',
        data: { label: 'AI 處理節點' },
        position: {
            x: isNaN(100) ? 0 : 100,
            y: isNaN(100) ? 0 : 100
        },
    },
    {
        id: '3',
        data: { label: '資料輸出' },
        position: {
            x: isNaN(400) ? 0 : 400,
            y: isNaN(100) ? 0 : 100
        },
    },
];

const initialEdges: Edge[] = [];

// 定義 DOM Node 的類型別名
type DOMNode = globalThis.Node;

// 修改模板定義為陣列
const projectTemplates = [
    {
        id: 'empty',
        name: 'empty_project',
        label: '空專案',
        description: 'empty_project_description',
        thumbnail: 'https://placehold.co/600x400?text=Empty+Project',
        data: {
            nodes: [],
            edges: []
        }
    },
    {
        id: 'default-flow',
        name: 'default_flow',
        label: '預設流程',
        description: 'default_flow_description',
        thumbnail: 'https://placehold.co/600x400?text=Default+Flow',
        data: {
            nodes: initialNodes,
            edges: initialEdges
        }
    }
    // 可以繼續添加更多模板...
];

const AIFlowDesignPage: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { deviceType } = useContext(GlobalContext);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const [flowText, setFlowText] = useState<string>('');
    const [collapsed, setCollapsed] = useState(false);
    const [activeTab, setActiveTab] = useState<'components' | 'templates'>('components');
    const { modal } = useContext(GlobalContext);

    // 使用 useRef 來存儲不需要觸發重渲染的值
    const dragRef = useRef<boolean>(false);

    // 使用 useMemo 緩存樣式對象，避免重複創建
    const defaultEdgeStyle = useMemo(() => ({
        stroke: '#b1b1b7',
        strokeWidth: 2,
        transition: 'all 0.2s ease',
    }), []);

    const selectedEdgeStyle = useMemo(() => ({
        ...defaultEdgeStyle,
        stroke: '#1890ff',
        strokeWidth: 3,
        animated: true,
    }), [defaultEdgeStyle]);

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [],
    );

    // 新增 nodeTypes 配置
    const nodeTypes: NodeTypes = useMemo(() => ({
        sticky: StickyNode as any
    }), []);

    const onDragStart = (event: React.DragEvent<HTMLElement>, nodeType: string) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    const onChange = (nodeId: string, key: string, value: any) => {
        setNodes((prevNodes) => {
            return prevNodes.map((node) => {
                if (node.id === nodeId) {
                    return { ...node, data: { ...node.data, [key]: value } };
                }
                return node;
            });
        });
    };

    // 在組件內新增拖拽處理邏輯
    const onDragOver = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();

            const type = event.dataTransfer.getData('application/reactflow');
            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            const id = Date.now().toString();
            const newNode = {
                id,
                type,
                position,
                data: { id, label: `${type} node`, onChange: onChange } as INodeData,
            };

            setNodes((nds) => nds.concat(newNode as any));
        },
        [reactFlowInstance]
    );

    // 計算選中項目數量
    const selectedCount = useMemo(() => {
        return nodes.filter(n => n.selected).length + edges.filter(e => e.selected).length
    }, [nodes, edges]);

    // 刪除選中項目
    const handleDeleteSelected = useCallback(() => {
        modal.confirm({
            title: translate('確認刪除'),
            content: translate('確定要刪除選中的節點和連線嗎？'),
            okText: translate('確認'),
            cancelText: translate('取消'),
            onOk: () => {
                setNodes(nds => nds.filter(n => !n.selected));
                setEdges(eds => eds.filter(e => !e.selected));
            }
        });
    }, [translate]);

    // 修改後的導出流程資料功能
    const handleExportFlow = useCallback(() => {
        const flowData = {
            nodes,
            edges,
            viewport: reactFlowInstance?.getViewport() // 獲取當前視圖位置和縮放級別
        };

        const jsonData = JSON.stringify(flowData, null, 2);
        console.log('Current flow data:', jsonData);

        modal.info({
            title: translate('當前流程資料'),
            content: <pre style={{
                maxHeight: '60vh',
                overflow: 'auto',
                fontSize: 12,
                lineHeight: '1.5',
                padding: 8,
                backgroundColor: '#f5f5f5',
                borderRadius: 4
            }}>{jsonData}</pre>,
            width: 800,
            okText: translate('關閉'),
        });
    }, [nodes, edges, reactFlowInstance, translate]);

    // 在 handleExportFlow 函數附近添加新的導入函數
    const handleImportFlow = useCallback(() => {
        modal.confirm({
            title: translate('導入流程'),
            content: (
                <div>
                    <p>{translate('請輸入流程 JSON 數據：')}</p>
                    <textarea
                        style={{
                            width: '100%',
                            height: '300px',
                            padding: '8px',
                            fontSize: '12px',
                            lineHeight: '1.5',
                            backgroundColor: '#f5f5f5',
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px',
                            resize: 'vertical'
                        }}
                        id="jsonInput"
                    />
                </div>
            ),
            width: 800,
            okText: translate('導入'),
            cancelText: translate('取消'),
            onOk: () => {
                const jsonInput = document.getElementById('jsonInput') as HTMLTextAreaElement;
                try {
                    const jsonData = JSON.parse(jsonInput.value);

                    // 驗證導入的數據結構
                    if (jsonData.nodes && jsonData.edges) {
                        modal.confirm({
                            title: translate('確認導入'),
                            content: translate('這將替換當前的所有節點和連線，確定要繼續嗎？'),
                            okText: translate('確認'),
                            cancelText: translate('取消'),
                            onOk: () => {
                                setNodes(jsonData.nodes);
                                setEdges(jsonData.edges);
                                if (jsonData.viewport && reactFlowInstance) {
                                    reactFlowInstance.setViewport(jsonData.viewport);
                                }
                            }
                        });
                    } else {
                        modal.error({
                            title: translate('錯誤'),
                            content: translate('無效的流程數據格式')
                        });
                    }
                } catch (error) {
                    modal.error({
                        title: translate('錯誤'),
                        content: translate('解析 JSON 數據時發生錯誤')
                    });
                }
            }
        });
    }, [reactFlowInstance, translate]);

    const handleUseTemplate = (templateId: string): void => {
        const template = projectTemplates.find(t => t.id === templateId);
        if (!template) return;

        modal.confirm({
            title: translate('套用樣板'),
            content: translate('這將置換當前專案內所有節點，確定要繼續嗎？'),
            okText: translate('確認'),
            cancelText: translate('取消'),
            onOk: () => {
                setNodes(template.data.nodes);
                setEdges(template.data.edges);
                setTimeout(() => reactFlowInstance?.fitView(), 100);
            }
        });
    };
    return (
        <>
            <DeepSeekChat
                hideLayout={false}
                apiKey={`${process.env.DEEPSEEK_API_KEY}`}
                model="deepseek-chat"
                onResponseStreamReceived={(response) => setFlowText(response)}
            />
            <Layout style={{ height: '100vh' }}>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={setCollapsed}
                    trigger={null}
                    collapsedWidth={0}
                    breakpoint="md"
                    width={200}
                    theme="light"
                    style={{
                        borderRight: '1px solid #f0f0f0',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    onTouchStart={(e) => {
                        const touch = e.touches[0];
                        dragRef.current = touch.clientX < 20;
                    }}
                    onTouchMove={(e) => {
                        if (!dragRef.current) return;
                        const touch = e.touches[0];
                        if (touch.clientX > 200) {
                            setCollapsed(false);
                        } else if (touch.clientX < 50) {
                            setCollapsed(true);
                        }
                    }}
                >
                    {/* 添加頂部切換按鈕 */}
                    <Flex style={{ padding: '8px', borderBottom: '1px solid #f0f0f0' }}>
                        <Button
                            type={activeTab === 'components' ? 'primary' : 'default'}
                            onClick={() => setActiveTab('components')}
                            style={{ flex: 1, marginRight: 4 }}
                        >
                            {translate('加入元件')}
                        </Button>
                        <Button
                            type={activeTab === 'templates' ? 'primary' : 'default'}
                            onClick={() => setActiveTab('templates')}
                            style={{ flex: 1, marginLeft: 4 }}
                        >
                            {translate('套用樣板')}
                        </Button>
                    </Flex>

                    {/* 根據 activeTab 顯示不同內容 */}
                    {activeTab === 'components' ? (
                        <Menu
                            mode="inline"
                            style={{ height: '100%', borderRight: 0 }}
                        >
                            <Menu.SubMenu key="basic" title={translate('基礎')}>
                                <Menu.Item
                                    key="add-node"
                                    draggable
                                    onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'default')}
                                >
                                    <PlusOutlined /> {!collapsed && translate('按鈕')}
                                </Menu.Item>
                                <Menu.Item
                                    key="text"
                                    draggable
                                    onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'text')}
                                >
                                    <FileTextOutlined /> {!collapsed && translate('文本')}
                                </Menu.Item>
                                <Menu.Item
                                    key="image"
                                    draggable
                                    onDragStart={(e) => e.dataTransfer.setData('application/reactflow', 'image')}
                                >
                                    <PictureOutlined /> {!collapsed && translate('圖片')}
                                </Menu.Item>
                            </Menu.SubMenu>

                            <Menu.SubMenu key="media" title={translate('媒體')}>
                                <Menu.Item
                                    key="sticky"
                                    draggable
                                    onDragStart={(e) => onDragStart(e, 'sticky')}
                                    style={{ padding: '8px 16px' }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormOutlined style={{ marginRight: 8 }} />
                                        {!collapsed && translate('便利貼')}
                                    </div>
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    ) : (
                        <div style={{ padding: 8, overflowY: 'auto' }}>
                            {projectTemplates.map(template => (
                                <Card
                                    key={template.id}
                                    onClick={() => handleUseTemplate(template.id)}
                                    hoverable
                                    style={{ padding: '10px', margin: '10px', position: 'relative' }}
                                    cover={<img src={template.thumbnail} alt={translate(template.label)} />}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'rgba(0, 0, 0, 0)',
                                            transition: 'all 0.3s ease',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            opacity: 0
                                        }}
                                        className="template-overlay"
                                    >
                                        <Button type="primary">
                                            {translate('套用樣板')}
                                        </Button>
                                    </div>
                                    <Flex justify="center" align="center" style={{ height: '100%' }}>
                                        {translate(template.label)}
                                    </Flex>
                                </Card>
                            ))}
                        </div>
                    )}

                    {/* 手機版觸發按鈕 */}
                    {deviceType === DeviceType.Mobile && (
                        <Button
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                position: 'fixed',
                                bottom: 20,
                                left: 20,
                                zIndex: 1000
                            }}
                        />
                    )}
                </Sider>

                <Layout>
                    <Content style={{ padding: deviceType === DeviceType.Mobile ? 8 : 16 }}>
                        <Flex gap="small" style={{ marginBottom: 16 }}>
                            {deviceType !== DeviceType.Mobile && (
                                <Button
                                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                            )}

                            <Badge
                                count={selectedCount}
                                color="red"
                                offset={[0, 0]}
                            >
                                <Button
                                    onClick={handleDeleteSelected}
                                    disabled={selectedCount === 0}
                                    danger
                                >
                                    {translate('刪除選中項目')}
                                </Button>
                            </Badge>

                            {/* 修改後的導出按鈕 */}
                            <Button
                                onClick={handleExportFlow}
                                type="primary"
                            >
                                {translate('導出流程資料')}
                            </Button>

                            {/* 在導入按鈕 */}
                            <Button
                                onClick={handleImportFlow}
                                style={{ marginLeft: 8 }}
                            >
                                {translate('導入流程')}
                            </Button>
                        </Flex>

                        {/* 手機版隱藏預覽框 */}
                        {flowText && deviceType !== DeviceType.Mobile && (
                            <div style={{
                                position: 'fixed',
                                right: 20,
                                top: 100,
                                background: 'white',
                                padding: 16,
                                zIndex: 10,
                                maxWidth: 400,
                                maxHeight: '60vh',
                                overflow: 'auto',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                borderRadius: 8,
                                border: '1px solid #f0f0f0'
                            }}>
                                <pre style={{
                                    whiteSpace: 'pre-wrap',
                                    fontSize: 12,
                                    lineHeight: '1.5',
                                    margin: 0
                                }}>{flowText}</pre>
                            </div>
                        )}

                        <div style={{
                            height: deviceType === DeviceType.Mobile
                                ? 'calc(100vh - 100px)'
                                : 'calc(100vh - 150px)',
                            width: '100%'
                        }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                nodeTypes={nodeTypes}
                                onInit={setReactFlowInstance}
                                onDragOver={onDragOver}
                                onDrop={onDrop}
                                fitView
                                attributionPosition="top-right"
                                style={{ backgroundColor: "#F7F9FB" }}
                            >
                                <MiniMap zoomable pannable />
                                <Controls />
                                <Background />
                            </ReactFlow>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

// 在組件外部定義靜態樣式
const nodeStyle = {
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    backgroundColor: 'white',
};

const selectedNodeStyle = {
    ...nodeStyle,
    border: '2px solid #1890ff',
    boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
};

export default memo(AIFlowDesignPage); // 使用 memo 包裹組件

