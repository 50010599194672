import { CurrencyCode } from '@/enums/CurrecnyCode';
import { useGetItemSpecsApi } from '@/lib/api/items';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import {
    DeviceType,
    MerchantPortalStyleSettingType,
    MerchantPortalStyleShopComponents,
    MerchantPortalStyleShopPages
} from '@/Templates/enums/templateEnums';
import { TimeHelper } from '@/Templates/helpers/TimeHelper';
import {
    IItemSpec,
    IItemViewModel,
    IMemberLogisticOption,
    IMerchantPortalStyleSetting,
    IOperationResultT,
    IPaginationResponse,
    IPortalMerchantThirdPartyPaymentSettingViewModel,
    IPortalQueryItemByMerchantRequest,
    IShoppingCartItemViewModel,
    IShoppingCartViewModel,
    ITempShoppingCartViewModel
} from '@/Templates/interfaces/templatesInterfaces';
import { useGetItemsByMerchantApi, useGetQueryOptionsByMerchantIdApi } from '@/Templates/lib/apis';
import { useCheckOut } from '@/Templates/Shop/Components/ShoppingCart/Contexts/ICheckOutContext';
import {
    TemplateProps,
} from '@/Templates/TemplateProps';
import { Button, Col, Drawer, Form, List, Row, Select, Typography } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DynamicPagePreview from './DynamicPagePreview';

const { Title, Text } = Typography;

export interface ShopPreviewProps {
    merchantId: BigInt;
    messageApi: MessageInstance;
    translate: (key: string) => string;
    navBarHeight: number;
    navBarGap: number;
    i18nLanguage: string;
}

export interface IShopStyleSelectorRef {
    onRefresh: () => void;
}

// 預設測試商品
const defaultTestItem = (translate: (key: string) => string): IItemViewModel => {
    return {
        id: BigInt(1),
        name: `${translate('Test')} ${translate('Item')} - 1`,
        title: `${translate('Test')} ${translate('Item')} ${translate('Title')}`,
        briefly: `${translate('Test')} ${translate('Item')} ${translate('Briefly')}`,
        description: `${translate('Test')} ${translate('Item')} ${translate('Description')}`,
        createdDate: new Date().toISOString(),
        alterDate: new Date().toISOString(),
        isDelete: false,
        coverImages: [
            'https://cdn.domigo-digital.com/system/TestItem.png',
            'https://cdn.domigo-digital.com/system/BackgroundImage1.jpg',
            'https://cdn.domigo-digital.com/system/None.png',
        ],
        itemTags: [
            {
                id: BigInt(1),
                merchantId: BigInt(1),
                name: '测试标签',
                isDeleted: false,
            },
        ],
        minPrice: 99.99,
        maxPrice: 199.99,
        isOnShelf: true,
        isSoldOut: false
    }
}
// 預設測試規格
const defaultTestItemSpecs = (translate: (key: string) => string): IItemSpec[] => [
    {
        id: BigInt(1),
        name: `${translate('Test')} ${translate('Spec')} ${translate('Unmanaged')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 100,
        fixedPrice: 100,
        costPrice: 100,
        isManageStockAmount: false,
        stockAmount: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 0,
    },
    {
        id: BigInt(2),
        name: `${translate('Test')} ${translate('Spec')} ${translate('Normal')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 200,
        fixedPrice: 200,
        costPrice: 100,
        isManageStockAmount: true,
        stockAmount: 100,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 1,
    },
    {
        id: BigInt(3),
        name: `${translate('Test')} ${translate('Spec')} ${translate('SoldOut')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 200,
        fixedPrice: 200,
        costPrice: 200,
        isManageStockAmount: true,
        stockAmount: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 2,
    }
];

interface StyleState {
    style: number;
    configFields: Array<any>;
    styleConfig: Record<string, string>;
}

const ShopPreview = forwardRef<IShopStyleSelectorRef, ShopPreviewProps>(({
    merchantId,
    messageApi,
    translate,
    navBarHeight,
    navBarGap,
    i18nLanguage
}, ref) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [seleceTestDataDrawerVisible, setSeleceTestDataDrawerVisible] = useState(false);
    const [merchantItems, setMerchantItems] = useState<IItemViewModel[]>([]);
    const [testItem, setTestItem] = useState<IItemViewModel>(defaultTestItem(translate));
    const [testItemSpecs, setTestItemSpecs] = useState<IItemSpec[]>(defaultTestItemSpecs(translate));
    const abortController = useRef<AbortController | null>(null);
    const [newDynamicPageName, setNewDynamicPageName] = useState('');
    var queryRequest: IPortalQueryItemByMerchantRequest = {
        merchantId,
        categoryIds: [],
        isOnShelf: true
    };
    const [testPaginationItems, setTestPaginationItems] = useState<IPaginationResponse<IItemViewModel[]>>({
        data: [testItem],
        totalCount: 1,
        totalPage: 1
    });
    const queryOptionsByMerchantIdApiMutation = useMutation("queryOptionsByMerchantIdApiMutation", async (merchantId: BigInt) => {
        return await useGetQueryOptionsByMerchantIdApi(merchantId);
    });

    const getItemsByMerchantApiMutation = useMutation("getItemsByMerchantApiMutation", async (queryRequest: IPortalQueryItemByMerchantRequest) => {
        return await useGetItemsByMerchantApi(queryRequest);
    });

    const {
        generateHelper,
        deviceType: windowdevicetype,
        notificationApi,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation
    } = useContext(GlobalContext);
    const {
        selectedItems,
        setSelectedItems,
        currentStep,
        setCurrentStep,
        userWantReservePay,
        setSelectedLogistic,
        setUserWantReservePay,
        logisticValues,
        setLogisticValues,
        receiverInfos,
        setReceiverInfos,
        nextStep,
        prevStep,
        handleSelectAll,
        handleSelectItem,
        isOnlinePayment,
        setIsOnlinePayment,
        paymentValues,
        setPaymentValues,
        note,
        setNote,
        isAddingStoreAddress,
        isDeletingLogisticOption,
        invoiceValues,
        invoiceValidate,
        setInvoiceValues,
        setInvoiceValidate,
        handleAddMemberLogisticOption,
        handleDeleteMemberLogisticOption,
        handleAddStoreAddressOption,
        sendPaymentRequest
    } = useCheckOut();
    const [previewdevicetype, setPreviewDeviceType] = useState<DeviceType>(
        DeviceType.Desktop
    );
    const previewContainerRef = useRef<HTMLDivElement>(null);
    const [containerwidth, setContainerWidth] = useState<number>(0);
    const [ComponentTemplateComponent, setComponentTemplateComponent] =
        useState<React.ComponentType<TemplateProps> | null>(null);
    const [PageTemplateComponent, setPageTemplateComponent] =
        useState<React.ComponentType<TemplateProps> | null>(null);
    const [mockComponentProps, setMockComponentProps] = useState<any>(null);
    const [mockPageProps, setMockPageProps] = useState<any>(null);
    const [mockDynamicPageProps, setMockDynamicPageProps] = useState<any>(null);
    const [selectedPageType, setSelectedPageType] =
        useState<MerchantPortalStyleShopPages>(
            MerchantPortalStyleShopPages.LandingPage
        );
    const [selectedComponentType, setSelectedComponentType] =
        useState<MerchantPortalStyleShopComponents>(
            MerchantPortalStyleShopComponents.NavBar
        );
    const [selectedDynamicPageType, setSelectedDynamicPageType] =
        useState<IMerchantPortalStyleSetting>();
    const [refreshComponentCount, setRefreshComponentCount] = useState(0);
    const [refreshPageCount, setRefreshPageCount] = useState(0);
    const [styleState, setStyleState] = useState<{
        component: StyleState;
        page: StyleState;
    }>({
        component: {
            style: 0,
            configFields: [],
            styleConfig: {},
        },
        page: {
            style: 0,
            configFields: [],
            styleConfig: {},
        },
    });
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpec | null>(
        testItemSpecs[0]
    );
    const [testshoppingCartData, setTestshoppingCartData] = useState<IShoppingCartViewModel | ITempShoppingCartViewModel | undefined>(undefined);
    const [purchaseQuantity, setPurchaseQuantity] = useState<number>(0);
    const [form] = Form.useForm();
    const [selectedItemId, setSelectedItemId] = useState<string>('default');
    const shoppingCartDataMutation = useMutation(
        ["GetShoppingCartApi", merchantId.toString(), true],
        async () => {
            return {
                isSuccess: true,
                result: testshoppingCartData
            } as IOperationResultT<IShoppingCartViewModel>;
        }
    );
    const batchDeleteShoppingCartItemsMutation = useMutation({
        mutationFn: async (items: IShoppingCartItemViewModel[]) => {
            try {
                return {
                    isSuccess: true,
                    result: [

                    ] as any
                } as IOperationResultT<IShoppingCartViewModel>;
            } catch (error) {
                console.error('購物車項目刪除失敗:', error);
                throw error;
            }
        },
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Delete Success'));
            } else {
                messageApi.error(translate('Delete Failed'));
            }
        },
        onError: (error) => {
            messageApi.error(translate('Delete Failed'));
            console.error('購物車項目刪除錯誤:', error);
        }
    });
    const batchAddShoppingCartItemsMutation = useMutation({
        mutationFn: async (items: IShoppingCartItemViewModel[]) => {
            return {
                isSuccess: true,
                result: [] as any
            } as IOperationResultT<IShoppingCartViewModel>;
        }
    });
    const merchantPortalPaymentSettingsMutation = useMutation(
        ['getMerchantThirdPartyPaymentSettingViewModels', merchantId],
        async (params: { merchantId: BigInt; signal?: AbortSignal | undefined }) => {
            return {
                isSuccess: true,
                result: [] as any
            } as IOperationResultT<IPortalMerchantThirdPartyPaymentSettingViewModel[]>;
        }
    );
    const paymentMainTypesMutation = useMutation(
        'getPaymentMainTypes',
        async (params: { signal?: AbortSignal | undefined }) => {
            return {
                isSuccess: true,
                result: [] as string[]
            } as IOperationResultT<string[]>;
        }
    );
    const memberLogisticOptionsMutation = useMutation(
        async (params: { isLogin: boolean, signal?: AbortSignal | undefined }) => {
            return {
                isSuccess: true,
                result: [] as IMemberLogisticOption[]
            } as IOperationResultT<IMemberLogisticOption[]>;
        }
    );
    const handleAddToShoppingCartMutation = useMutation(
        async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    );

    // 將 baseMockTemplateProps 改為 useState
    const [baseMockTemplateProps, setBaseMockTemplateProps] = useState<TemplateProps>({
        isLogin: false,
        tryGetSessionId: () => {
            return generateHelper.getSnowflakeIdBigInt();
        },
        navBarHeight,
        navBarGap,
        deviceType: previewdevicetype,
        isPreviewMode: true,
        merchantId,
        i18nLanguage,
        translate,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        messageApi,
        notificationApi,
        BASE_PATH: '/base-path',
        config: {},
        navigate: navigate,
    });

    const loadComponentTemplate = useCallback(async () => {
        try {
            const componentModule = await import(
                `@/Templates/Shop/Components/${selectedComponentType}/Template_${styleState.component.style}.tsx`
            );
            setComponentTemplateComponent(() => componentModule.default);
            let newFields = [];
            if (componentModule.getConfigFields) {
                newFields = componentModule.getConfigFields(merchantPortalOptionSettingMutation?.data?.result?.merchantName || '', translate, i18nLanguage);
            }
            const merchantAlreadyFilledConfigs = merchantPortalStyleSettingsMutation.data?.result?.find(
                (x) =>
                    x.type === MerchantPortalStyleSettingType.Components &&
                    x.name === selectedComponentType &&
                    x.style === styleState.component.style
            );
            setStyleState((prevState) => ({
                ...prevState,
                component: {
                    ...prevState.component,
                    configFields: newFields,
                    styleConfig: merchantAlreadyFilledConfigs?.styleSetting || {},
                },
            }));
            setBaseMockTemplateProps(prev => ({
                ...prev,
                config: merchantAlreadyFilledConfigs?.styleSetting || {},
            }));
            setRefreshComponentCount((prev) => prev + 1);
        } catch (error) {
            console.error('加载组件模板失败:', error);
            setComponentTemplateComponent(null);
            setStyleState((prevState) => ({
                ...prevState,
                component: {
                    ...prevState.component,
                    configFields: [],
                    styleConfig: {},
                },
            }));
            setBaseMockTemplateProps(prev => ({
                ...prev,
                config: {},
            }));
        }
    }, [
        selectedComponentType,
        styleState.component.style,
        merchantPortalStyleSettingsMutation,
    ]);

    const loadMockComponentProps = useCallback(() => {
        try {
            switch (selectedComponentType) {
                case MerchantPortalStyleShopComponents.NavBar:
                    const navBarProps: TemplateProps = {
                        ...baseMockTemplateProps,
                    };
                    setMockComponentProps({ ...navBarProps, isCentered: false });
                    break;

                case MerchantPortalStyleShopComponents.Marquee:
                    setMockComponentProps({
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                        isCentered: false
                    });
                    break;
                case MerchantPortalStyleShopComponents.FullPageLoading:
                    setMockComponentProps({
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                        isFullPageLoading: true,
                        isCentered: false
                    });
                    break;
                case MerchantPortalStyleShopComponents.Footer:
                    const footerProps: TemplateProps = {
                        ...baseMockTemplateProps,
                        footerProps: {
                            isHideFooter: false,
                            buildTime: TimeHelper.formatUtcStringToLocal(new Date().toISOString()),
                        },
                    };
                    setMockComponentProps({ ...footerProps, isCentered: false });
                    break;
                case MerchantPortalStyleShopComponents.ContactUs:
                    const contactUsProps: TemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                    };
                    setMockComponentProps(contactUsProps);
                    break;
                case MerchantPortalStyleShopComponents.PrivacyPolicy:
                    const privacyPolicyProps: TemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                    };
                    setMockComponentProps(privacyPolicyProps);
                    break;
                case MerchantPortalStyleShopComponents.ItemList:
                    const itemListProps: TemplateProps = {
                        ...baseMockTemplateProps,
                        config: styleState.component.styleConfig,
                        deviceType: previewdevicetype,
                    };
                    setMockComponentProps(itemListProps);
                    break;
                default:
                    setMockComponentProps(null);
            }
        } catch (error) {
            console.error('加载组件属性失败:', error);
            setMockComponentProps(null);
        }
    }, [
        selectedComponentType,
        previewContainerRef,
        previewdevicetype,
        styleState.component.styleConfig,
    ]);

    const loadMerchantItems = useCallback(async () => {
        const res = await useGetItemsByMerchantApi(queryRequest);
        if (res.result) {
            setMerchantItems([...res.result.data]);
        }
    }, [queryRequest]);

    const loadItemSpecs = useCallback(async (itemId: string) => {
        if (itemId === 'default') {
            setTestItemSpecs(defaultTestItemSpecs(translate));
        } else {
            const res = await useGetItemSpecsApi(merchantId, BigInt(itemId));
            if (res.result) {
                setTestItemSpecs(res.result);
            }
        }
    }, [merchantId, translate]);

    const handleTestItemSelect = async (value: string) => {
        setSelectedItemId(value);
        if (value === 'default') {
            setTestItem(defaultTestItem(translate));
            setTestItemSpecs(defaultTestItemSpecs(translate));
        } else {
            const selectedItem = merchantItems.find(item => item.id.toString() === value);

            if (selectedItem) {
                setTestItem(selectedItem);
                await loadItemSpecs(value);
            }
        }
    };

    const showSeleceTestDataDrawer = () => {
        return (
            <div >
                <Button onClick={() => setSeleceTestDataDrawerVisible(true)}>
                    {translate('Select Test Data')}
                </Button>
                <Drawer
                    placement='left'
                    title={translate("Select Test Data")}
                    onClose={() => setSeleceTestDataDrawerVisible(false)}
                    open={seleceTestDataDrawerVisible}
                    width={400}
                >
                    <Form form={form} layout="vertical">
                        <Form.Item name="testItem" label={translate("Item")}>
                            <Select
                                style={{ width: '100%' }}
                                placeholder={translate("Select")}
                                onChange={handleTestItemSelect}
                                value={selectedItemId}
                                defaultValue={'default'}
                            >
                                <Select.Option value="default">
                                    {translate(defaultTestItem(translate).name)} (Default)
                                </Select.Option>
                                {merchantItems.map(item => (
                                    <Select.Option key={item.id.toString()} value={item.id.toString()}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                    <Title level={5}>{translate("Specs")}</Title>
                    <List
                        dataSource={testItemSpecs}
                        renderItem={spec => (
                            <List.Item>
                                <List.Item.Meta
                                    title={spec.name}
                                    description={
                                        <>
                                            <Text>{`${translate("Price")}: ${spec.sellPrice} ${spec.currency}`}</Text>
                                            <br />
                                            <Text>{`${translate("Stock")}: ${spec.isManageStockAmount ? spec.stockAmount : translate("Not Managed")}`}</Text>
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Drawer>
            </div>
        );
    };

    const updateWidth = useCallback(() => {
        // 使用 requestAnimationFrame 確保 DOM 已更新
        requestAnimationFrame(() => {
            if (previewContainerRef.current) {
                const rect = previewContainerRef.current.getBoundingClientRect();
                if (rect.width > 0) {  // 添加寬度檢查
                    setContainerWidth(rect.width);
                }
            }
        });
    }, [previewdevicetype]);

    const handleStyleChange = (type: 'component' | 'page', value: number) => {
        setStyleState((prevState) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                style: value,
            },
        }));
    };

    const handleConfigChange = (
        type: 'component' | 'page',
        key: string,
        value: string
    ) => {
        setStyleState((prevState) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                styleConfig: {
                    ...prevState[type].styleConfig,
                    [key]: value.toString(),
                },
            },
        }));
    };

    // 预览设备类型切换
    const handlePreviewDeviceChange = (type: DeviceType) => {
        setPreviewDeviceType(type);
    };

    const getPreviewWidth = () => {
        switch (previewdevicetype) {
            case DeviceType.Mobile:
                return '375px';
            case DeviceType.LapTop:
                return '768px';
            case DeviceType.Desktop:
            default:
                return '1024px';
        }
    };

    useEffect(() => {
        setBaseMockTemplateProps(prev => ({
            ...prev,
            deviceType: previewdevicetype,
        }));
    }, [
        previewdevicetype
    ]);

    useEffect(() => {
        loadComponentTemplate();
    }, [
        selectedComponentType,
        styleState.component.style,
        loadComponentTemplate,
    ]);

    useEffect(() => {
        loadMockComponentProps();
    }, [
        selectedComponentType,
        styleState.component.style,
        previewdevicetype,
        styleState.component.styleConfig,
        refreshComponentCount,
        testItemSpecs,
        testItem,
        loadMockComponentProps,
    ]);

    useEffect(() => {
    }, [
        selectedPageType,
        styleState.page.style,
    ]);


    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            loadComponentTemplate();
            loadMockComponentProps();
            loadMerchantItems();
        },
    }));

    useEffect(() => {
        // 初始化時延遲執行一次
        const initTimer = setTimeout(updateWidth, 100);

        // 添加 resize 監聽
        window.addEventListener('resize', updateWidth);

        // 創建 ResizeObserver
        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        // 延遲觀察元素
        const observeTimer = setTimeout(() => {
            if (previewContainerRef.current) {
                resizeObserver.observe(previewContainerRef.current);
            }
        }, 100);

        loadMerchantItems();

        return () => {
            clearTimeout(initTimer);
            clearTimeout(observeTimer);
            window.removeEventListener('resize', updateWidth);
            resizeObserver.disconnect();
        };
    }, [updateWidth]); // 移除 activeTab 作為依賴

    useEffect(() => {
        setSelectedItemId('default');
        setTestItem(defaultTestItem(translate));
        setTestItemSpecs(defaultTestItemSpecs(translate));
    }, [translate]);

    // 監聽 merchantPortalStyleSettings 變化
    useEffect(() => {
        // 重新加載組件模板
        loadComponentTemplate();
        // 重新加載組件屬性
        loadMockComponentProps();
    }, [merchantPortalStyleSettingsMutation]);

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current?.abort();
        };
    }, []);

    if (merchantPortalStyleSettingsMutation.isLoading) {
        return <LoadingComponent />;
    }

    return (
        <Row>
            <Col span={24}>
                <DynamicPagePreview
                    containerwidth={containerwidth}
                    previewdevicetype={previewdevicetype}
                    windowdevicetype={windowdevicetype}
                    previewContainerRef={previewContainerRef}
                    handlePreviewDeviceChange={handlePreviewDeviceChange}
                    getPreviewWidth={getPreviewWidth}
                    translate={translate}
                    showSeleceTestDataDrawer={showSeleceTestDataDrawer}
                />
            </Col>
        </Row>
    );
});

export default ShopPreview;
