import { IBatchOrderCancelRequest, IBatchOrderRequest, IGetBackOfficeOrderViewModelRequest, IManualAlterOrderRequest } from '@/interfaces/Requests/Requests';
import { IOrder, IOrderOperationHistory } from '@/interfaces/Responses/Responses';
import { IOperationResult, IOperationResultT, IPaginationResponse } from '@/Templates/interfaces/templatesInterfaces';
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useGetMerchantOrdersApi = (request: IGetBackOfficeOrderViewModelRequest, signal?: AbortSignal) => useAPI<IOperationResultT<IPaginationResponse<IOrder[]>>>(`${process.env.BASE_API_URL}/api/v1/Order/GetMerchantOrders`, {
    method: 'GET',
    body: request,
    signal: signal
});

export const useManualAlterOrderApi = (request: IManualAlterOrderRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Order/ManualAlterOrder`, {
    method: 'POST',
    body: request,
    signal: signal
});

export const useGetOrderOperationHistoriesApi = (orderId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IOrderOperationHistory[]>>(`${process.env.BASE_API_URL}/api/v1/Order/GetOrderOperationHistories?orderId=${orderId.toString()}`, {
    signal: signal
});

export const useBatchOrderCancelApi = (request: IBatchOrderCancelRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Order/BatchOrderCancel`, {
    method: 'POST',
    body: request,
    signal: signal
});

export const useBatchOrderConfirmApi = (request: IBatchOrderRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Order/BatchOrderConfirm`, {
    method: 'POST',
    body: request,
    signal: signal
});