import React from 'react';
import { Row, Col, Divider, Typography } from 'antd';
import { IBackOfficeOrderViewModel } from '@/interfaces/Responses/Responses';
import dayjs from 'dayjs';

const { Text } = Typography;

interface PickingListProps {
    order: IBackOfficeOrderViewModel;
    translate: (key: string) => string;
}

const PickingList = React.forwardRef<HTMLDivElement, PickingListProps>(({ order, translate }, ref) => {
    const currentDate = dayjs().format('YYYY/MM/DD HH:mm');

    return (
        <div ref={ref} style={{ padding: '20px', backgroundColor: 'white' }}>
            {/* 標題區域 */}
            <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
                <Col>
                    <h2 style={{ margin: 0 }}>{translate('Picking list')}</h2> ( {translate('Order ID')}: {order.id.toString()} )
                </Col>
                <Col>
                    <Text>{translate('Date')}: {currentDate}</Text>
                </Col>
            </Row>
            <Divider />
            {/* 表格標題 */}
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                    <tr style={{ borderBottom: '2px solid #000' }}>
                        <th style={{ padding: '8px', textAlign: 'left', width: '15%' }}>{translate('Item Name')}</th>
                        <th style={{ padding: '8px', textAlign: 'left', width: '10%' }}>{translate('Spec Name')}</th>
                        <th style={{ padding: '8px', textAlign: 'left', width: '15%' }}>{translate('Buy Amount')}</th>
                        <th style={{ padding: '8px', textAlign: 'left', width: '10%' }}>{translate('Picked')}</th>
                    </tr>
                </thead>
                <tbody>
                    {order.items.map((item, index) => (
                        <tr key={index} style={{ borderBottom: '1px dotted #ccc' }}>
                            <td style={{ padding: '8px' }}>
                                {item.itemName || '-'}
                            </td>
                            <td style={{ padding: '8px' }}>
                                {item.itemSpecName}
                            </td>
                            <td style={{ padding: '8px' }}>
                                {item.buyAmount}
                            </td>
                            <td style={{ padding: '8px' }}>
                                <div style={{
                                    width: '20px',
                                    height: '20px',
                                    border: '1px solid #000',
                                    display: 'inline-block'
                                }}></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Divider />
            {/* 訂單備註 */}
            {order.note && (
                <div>
                    <h3>{translate('Notes For the Buyer')}</h3>
                    <p>{order.note}</p>
                    <Divider />
                </div>
            )}

            {/* 訂單概況 */}
            <Row style={{ marginBottom: '20px' }}>
                <Col span={24}>
                    <Text>{translate('Total Specification Count')}: {order.items.length}</Text>
                    <Text style={{ marginLeft: '20px' }}>{translate('Total Item Count')}: {order.items.reduce((acc, item) => acc + item.buyAmount, 0)}</Text>
                </Col>
            </Row>
        </div>
    );
});

export default PickingList; 