import { FrontEndDisplayType } from "@/Templates/enums/templateEnums";
import {
    LogisticOrderStatus,
    LogisticOrderType
} from "@/enums/ThirdPartyLogistic";
import { IGetThirdPartyOrdersByPlatformOrderRequest, IQueryThirdPartyOrderStatusRequest } from "@/interfaces/Requests/Requests";
import { IBatchCreateThirdPartyLogisticOrderRequest, ICreateThirdPartyLogisticOrderRequest } from "@/interfaces/Requests/ThirdPartyLogistic";
import { IOrder } from "@/interfaces/Responses/Responses";
import { IThirdPartyLogisticOrderViewModel } from "@/interfaces/Responses/ThirdPartyLogistic";
import {
    useCreateStickerInfoApi,
    useBatchCreateThirdPartyLogisticOrderApi,
    useGetMemberLogisticOptionApi,
    useGetThirdPartyLogisticSettingApi,
    useGetThirdPartyOrdersByPlatformOrderApi,
    useQueryLogisticOrderStatusApi
} from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { UrlHelper } from "@/Templates/lib/UrlHelper";
import { Button, Card, Descriptions, Divider, Flex, Modal, Tag } from "antd";
import JSONBig from 'json-bigint';
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import LogisticSelector, { ILogisticSelectorRef } from "./LogisticSelector";

export interface ILogisticOrderProps {
    order: IOrder;
}

export interface ILogisticOrderRef {
    onOpen: () => void;
}

const LogisticOrder = React.forwardRef((props: ILogisticOrderProps, ref: React.ForwardedRef<ILogisticOrderRef | undefined>) => {
    const urlHelper = new UrlHelper();
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();

    const [logisticOrders, setLogisticOrders] = useState<IThirdPartyLogisticOrderViewModel[]>([]);
    const [addLogisticOrderVisible, setAddLogisticOrderVisible] = useState<boolean>(false);

    const logisticSelectorRef = useRef<ILogisticSelectorRef>();

    // Get member logistic option
    const { data: memberLogisticOption, isLoading: isLoadingMemberLogisticOption } = useQuery(
        ['memberLogisticOption', props.order.memberLogisticOptionId],
        () => useGetMemberLogisticOptionApi(props.order.memberLogisticOptionId),
        {
            enabled: !!props.order.memberLogisticOptionId,
        }
    );

    // Get logistic setting
    const { data: logisticSetting, isLoading: isLoadingLogisticSetting } = useQuery(
        ['logisticSetting', memberLogisticOption?.result?.thirdPartyLogisticId],
        () => useGetThirdPartyLogisticSettingApi(memberLogisticOption!.result!.thirdPartyLogisticId),
        {
            enabled: !!memberLogisticOption?.result?.thirdPartyLogisticId,
        }
    );

    // Get logistic orders
    const { mutate: mutateLogisticOrders, isLoading: isLoadingLogisticOrders } = useMutation(
        async (request: IGetThirdPartyOrdersByPlatformOrderRequest) => await useGetThirdPartyOrdersByPlatformOrderApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    setLogisticOrders(response.result);
                }
            }
        }
    );

    // Query logistic order status
    const { mutate: queryLogisticOrderStatusMutate, isLoading: queryLogisticOrderStatusLoading } = useMutation(
        async (request: IQueryThirdPartyOrderStatusRequest) => await useQueryLogisticOrderStatusApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    const data = JSONBig.parse(response.result?.data || '{}');
                    switch (response.result?.type) {
                        case FrontEndDisplayType.None:
                            mutateLogisticOrders({ orderId: props.order.id });
                            break;
                        case FrontEndDisplayType.PageFormPost:
                            urlHelper.pageFormPost(response.result?.url, data);
                            break;
                        case FrontEndDisplayType.PageRedirectURL:
                            urlHelper.openRedirect(response.result?.url, data);
                            break;
                        case FrontEndDisplayType.OpenNewPage:
                            urlHelper.openInPopup(response.result?.url, data);
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    );

    const createStickerInfoApi = useMutation(async (thirdPartyOrderId: BigInt) => await useCreateStickerInfoApi(thirdPartyOrderId), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                const data = JSONBig.parse(response.result?.data || '{}');
                switch (response.result?.type) {
                    case FrontEndDisplayType.PageFormPost:
                        urlHelper.pageFormPost(response.result?.url, data, '_blank');
                        break;
                    case FrontEndDisplayType.PageRedirectURL:
                        urlHelper.openRedirect(response.result?.url, data);
                        break;
                    case FrontEndDisplayType.OpenNewPage:
                        urlHelper.openInPopup(response.result?.url, data);
                        break;
                    default:
                        break;
                }
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    })

    // Create logistic order
    const batchCreateLogisticOrderMutate = useMutation(
        async (request: IBatchCreateThirdPartyLogisticOrderRequest) => await useBatchCreateThirdPartyLogisticOrderApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate("Logistic order created successfully"));
                    setAddLogisticOrderVisible(false);
                    mutateLogisticOrders({ orderId: props.order.id });
                } else {
                    messageApi.error(translate(response.message || "Failed to create logistic order"));
                }
            }
        }
    );

    useEffect(() => {
        if (merchantId) {
            mutateLogisticOrders({ orderId: props.order.id });
        }
    }, [merchantId]);

    // 定義新的 canCreateLogisticOrder 邏輯
    const canCreateLogisticOrder = props.order.isCollection
        ? (logisticOrders.length === 0 || logisticOrders.some(order => !order.isSuccessCreateFromProvider))
        : (props.order.isPaid && (logisticOrders.length === 0 || logisticOrders.some(order => !order.isSuccessCreateFromProvider)));

    // 更新 handleCreateLogisticOrder 函式
    const handleCreateLogisticOrder = () => {
        // 如果不是貨到付款且未付款，則先提示用戶需要付款
        if (!props.order.isCollection && !props.order.isPaid) {
            messageApi.error(translate('Please complete payment before generating logistic order'));
            return;
        }
        // 重新檢查是否滿足生成物流訂單條件
        const canGenerate = props.order.isCollection
            ? (logisticOrders.length === 0 || logisticOrders.some(order => !order.isSuccessCreateFromProvider))
            : (props.order.isPaid && (logisticOrders.length === 0 || logisticOrders.some(order => !order.isSuccessCreateFromProvider)));

        if (!canGenerate) {
            if (props.order.isCollection) {
                messageApi.error(translate('Cannot generate logistic order for cash on delivery orders'));
            } else {
                messageApi.error(translate('Logistic order already created or cannot be generated'));
            }
            return;
        }
        // 若滿足則進行生成
        if (memberLogisticOption?.result?.thirdPartyLogisticId) {
            const request: IBatchCreateThirdPartyLogisticOrderRequest = {
                requests: [
                    {
                        orderId: props.order.id,
                        settingId: memberLogisticOption.result.thirdPartyLogisticId,
                        frontEndValues: {},
                        logisticOrderType: LogisticOrderType.Forward
                    }
                ]
            };
            batchCreateLogisticOrderMutate.mutate(request);
        }
    };

    const handleQueryLogisticOrderStatus = (thirdPartyLogisticOrderId: BigInt) => {
        queryLogisticOrderStatusMutate({ thirdPartyOrderId: thirdPartyLogisticOrderId });
    }

    const handleGetSticker = (thirdPartyLogisticOrderId: BigInt) => {
        createStickerInfoApi.mutate(thirdPartyLogisticOrderId);
    }

    const getLogisticOrderStatusColor = (status: LogisticOrderStatus) => {
        switch (status) {
            case LogisticOrderStatus.Created:
            case LogisticOrderStatus.DuringShipping:
            case LogisticOrderStatus.ArrivalWaitForPickup:
                return 'blue';
            case LogisticOrderStatus.Done:
                return 'green';
            case LogisticOrderStatus.ExpiredNotPickup:
            case LogisticOrderStatus.Cancel:
                return 'red';
            case LogisticOrderStatus.Unknow:
                return 'gray'
            default:
                return 'default';
        }
    };

    const renderMemberLogisticOption = () => {
        if (isLoadingLogisticSetting || isLoadingMemberLogisticOption) return (
            <LoadingComponent />
        );

        if (!memberLogisticOption?.result || !logisticSetting?.result) return null;

        return (
            <Card title={translate('Buyer Logistic Option')} style={{ marginBottom: 16 }}>
                <Descriptions column={1}>
                    <Descriptions.Item label={translate('Provider Name')}>
                        {logisticSetting.result.providerName}
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('Type')}>
                        {translate(memberLogisticOption.result.logisticsMainType)}
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('LogisticsSubType')}>
                        {translate(`${logisticSetting.result.providerName}.${memberLogisticOption.result.values['LogisticsSubType']}`, 'LogisticsSubType')}
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('IsCollection')}>
                        <Tag color={props.order.isCollection ? 'green' : 'red'}>
                            {translate(`${props.order.isCollection ? 'Yes' : 'No'}`)}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('Store')}>
                        {memberLogisticOption.result.displayName}
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('Address')}>
                        {memberLogisticOption.result.displayAddress}
                    </Descriptions.Item>
                </Descriptions>
                {canCreateLogisticOrder ? (
                    <>
                        <Divider>{translate('Third Party Shipping')}</Divider>
                        <Flex justify="center" gap="large">
                            <Button
                                disabled={batchCreateLogisticOrderMutate.isLoading}
                                onClick={handleCreateLogisticOrder}
                            >
                                {translate('User specified provider')}
                            </Button>
                            <Button onClick={() => setAddLogisticOrderVisible(true)}>
                                {translate('Other logistics providers')}
                            </Button>
                        </Flex>
                    </>
                ) : (
                    <div style={{ marginTop: 16, textAlign: 'center', color: 'red' }}>
                        <ol style={{ paddingLeft: '20px', margin: 0 }}>
                            <li>{translate('Non-cash on delivery orders, please complete payment first')}</li>
                        </ol>
                    </div>
                )}
            </Card>
        );
    };

    const renderLogisticOrder = (logisticOrder: IThirdPartyLogisticOrderViewModel) => {
        return (
            <Card title={`${translate('Logistic')} ${translate('Order')}`} key={logisticOrder.id.toString()}>
                <Descriptions column={1}>
                    <Descriptions.Item label={translate('Order ID')}>{logisticOrder.id.toString()}</Descriptions.Item>
                    <Descriptions.Item label={translate('Provider Name')}>{logisticOrder.providerName}</Descriptions.Item>
                    {logisticOrder.providerOrderId && (
                        <Descriptions.Item label="Provider Order ID">{logisticOrder.providerOrderId}</Descriptions.Item>
                    )}
                    <Descriptions.Item label={translate('Type')}>{translate(`LogisticOrderType.${logisticOrder.type}`, 'Logistic')}</Descriptions.Item>
                    <Descriptions.Item label={translate('Is Success Create From Provider')}>
                        <span style={{ color: logisticOrder.isSuccessCreateFromProvider ? 'inherit' : 'red' }}>
                            {translate(logisticOrder.isSuccessCreateFromProvider ? 'Yes' : 'No')}
                        </span>
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('Status')}>
                        <Tag color={getLogisticOrderStatusColor(logisticOrder.status)}>
                            {translate(logisticOrder.status)}
                        </Tag>
                    </Descriptions.Item>
                </Descriptions>
                <Flex justify="center" style={{ marginTop: 16 }} gap="large">
                    {logisticOrder.isSuccessCreateFromProvider && logisticOrder.status !== LogisticOrderStatus.Done && (
                        <>
                            <Button
                                type="primary"
                                loading={queryLogisticOrderStatusLoading}
                                onClick={() => handleQueryLogisticOrderStatus(logisticOrder.id)}
                            >
                                {`${translate('Query')} ${translate('Status')}`}
                            </Button>
                            <Button
                                type="primary"
                                loading={createStickerInfoApi.isLoading}
                                onClick={() => handleGetSticker(logisticOrder.id)}
                            >
                                {`${translate('Get Sticker')}`}
                            </Button>
                        </>
                    )}
                </Flex>
            </Card>
        );
    };

    const renderLogisticOrders = () => {
        const sendOrder = logisticOrders.find(order => order.type === LogisticOrderType.Forward);
        if (sendOrder) {
            return renderLogisticOrder(sendOrder);
        }
        return (
            <></>
        );
    };

    return (
        <>
            {renderMemberLogisticOption()}
            <Divider />
            {renderLogisticOrders()}
            <Modal
                open={addLogisticOrderVisible}
                onCancel={() => setAddLogisticOrderVisible(false)}
                footer={null}
                afterOpenChange={(visible) => {
                    if (visible) logisticSelectorRef.current?.onOpen();
                    else logisticSelectorRef.current?.onClose();
                }}
            >
                <LogisticSelector
                    ref={logisticSelectorRef}
                    order={props.order}
                    limitSettingIds={
                        memberLogisticOption?.result?.thirdPartyLogisticId
                            ? [memberLogisticOption.result.thirdPartyLogisticId]
                            : []
                    }
                    onLogisticOrderCreated={(request) => {
                        batchCreateLogisticOrderMutate.mutate(request);
                    }}
                />
            </Modal>
        </>
    );
});

export default LogisticOrder;
