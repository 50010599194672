import DomigoLogo from '@/assets/Domigo-Logo-removebg-preview.png';
import { IBackOfficeRegisterRequest } from "@/interfaces/Requests/Requests";
import { useMerchantRegistApi } from "@/lib/api/merchants";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { LoginTypeEnum } from '@/Templates/enums/templateEnums';
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Image, Input, InputRef, Tabs } from "antd";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
    const {
        messageApi,
        merchantId,
        deviceType,
        setIsHideAnnouncement } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const emailInputRef = useRef<InputRef>(null);
    const phoneInputRef = useRef<InputRef>(null);

    // 註冊類狀態
    const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.Email);

    const { mutate: registerMutate, isLoading } = useMutation(
        async (request: IBackOfficeRegisterRequest) => useMerchantRegistApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'))
                        .then(() => navigate(`/login`));
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const validateConfirmPassword = (_: any, value: string) => {
        if (value && value !== form.getFieldValue("hashPassword")) {
            return Promise.reject(translate('The two passwords that you entered do not match'));
        }
        return Promise.resolve();
    };

    const handleSubmit = (values: any) => {
        const request: IBackOfficeRegisterRequest = {
            userName: values.userName,
            email: loginType === LoginTypeEnum.Email ? values.email : '',
            loginType: loginType,
            phoneNumber: loginType === LoginTypeEnum.PhoneNumber ? values.phoneNumber : '',
            hashPassword: MD5(values.hashPassword).toString().toLowerCase(),
        };
        registerMutate(request);
    };

    // 切換註冊類型時重置表單
    const handleTabChange = (type: string) => {
        form.resetFields();
        setLoginType(type as LoginTypeEnum);
    };

    // 根據註冊類型自動聚焦對應輸入框
    useEffect(() => {
        if (loginType === LoginTypeEnum.Email) {
            emailInputRef.current?.focus();
        } else {
            phoneInputRef.current?.focus();
        }
    }, [loginType]);

    useEffect(() => {
        setIsHideAnnouncement(true);
        return () => setIsHideAnnouncement(false);
    }, []);

    return (
        <div className={`flex justify-center  items-center`} >
            <div className="bg-white  p-8 rounded-lg shadow-[0_-10px_50px_-12px_rgba(0,0,0,0.25),0_10px_50px_-15px_rgba(0,0,0,0.3)] w-full max-w-md" >
                <Flex justify="center" align="middle" className="mb-6">
                    <Image
                        src={DomigoLogo}
                        width={100}
                        height={100}
                        preview={false}
                    />
                </Flex>
                <Tabs
                    activeKey={loginType}
                    onChange={handleTabChange}
                    centered
                    items={[
                        {
                            key: LoginTypeEnum.Email,
                            label: translate('Email Register'),
                        },
                        {
                            key: LoginTypeEnum.PhoneNumber,
                            label: translate('Phone Register'),
                        },
                    ]}
                />

                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    className="mt-6"
                >
                    <Form.Item
                        name="userName"
                        rules={[
                            { required: true, message: translate('is required') },
                            { whitespace: true, message: translate('is required') }
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined />}
                            placeholder={translate('UserName')}
                            autoComplete="off"
                        />
                    </Form.Item>

                    {loginType === LoginTypeEnum.Email ? (
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: translate('is required') },
                                {
                                    pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                                    message: translate("Must be a valid email format"),
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder={translate('Email')}
                                ref={emailInputRef}
                                autoComplete="off"
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="phoneNumber"
                            rules={[
                                { required: true, message: translate('is required') },
                                {
                                    pattern: /^09[0-9]{8}$/,
                                    message: translate("Must be a valid phone number format"),
                                },
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder={translate('PhoneNumber')}
                                ref={phoneInputRef}
                                autoComplete="off"
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        name="hashPassword"
                        rules={[
                            { required: true, message: translate('is required') },
                            {
                                pattern: /^.{8,24}$/,
                                message: translate('Password must be between 8 and 24 characters')
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder={translate('Password')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="confirmPassword"
                        rules={[
                            { required: true, message: translate('is required') },
                            { validator: validateConfirmPassword }
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder={translate('Confirm password')}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            className="w-full"
                        >
                            {translate('Register')}
                        </Button>
                    </Form.Item>
                </Form>

                <Flex justify="center" className="mt-4">
                    <span className="text-gray-600">
                        {translate('Already have an account') + '?'}
                        <Button
                            type="link"
                            onClick={() => navigate(`/login`)}
                            className="ml-2"
                        >
                            {translate('Login now')}
                        </Button>
                    </span>
                </Flex>
            </div>
        </div>
    );
};

export default RegisterPage; 