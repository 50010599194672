import { IAddBackOfficeMerchantThirdPartyPlatformServiceSettingRequest, IUpdateBackOfficeMerchantThirdPartyPlatformServiceSettingRequest } from "@/interfaces/Requests/Requests";
import { IExecuteRequest } from "@/interfaces/Requests/ThirdPartyPlatform";
import { IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel } from "@/interfaces/Responses/ThirdPartyLogistic";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useGetBackOfficeMerchantThirdPartyPlatformServiceSettingViewModelsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IBackOfficeMerchantThirdPartyPlatformServiceSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/v1/ThirdPartyPlatformService/GetBackOfficeMerchantThirdPartyPlatformServiceSettingViewModels`, {
    signal: signal
});

export const useAddBackOfficeMerchantThirdPartyPlatformServiceSettingApi = (params: IAddBackOfficeMerchantThirdPartyPlatformServiceSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/ThirdPartyPlatformService/AddBackOfficeMerchantThirdPartyPlatformServiceSetting`, {
    method: 'POST',
    body: params,
});

export const useUpdateBackOfficeMerchantThirdPartyPlatformServiceSettingApi = (params: IUpdateBackOfficeMerchantThirdPartyPlatformServiceSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/ThirdPartyPlatformService/UpdateBackOfficeMerchantThirdPartyPlatformServiceSetting`, {
    method: 'POST',
    body: params,
});

export const useSendGroupMessageApi = (request: IExecuteRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/ThirdPartyPlatformService/Execute`,
    {
        method: 'POST',
        body: request,
    });