import JSONBig from 'json-bigint';
import { INavBarOption, IPortalQueryItemByMerchantRequest } from "../interfaces/templatesInterfaces";

export class UrlHelper {
    pageFormPost(url: string, params: { [key: string]: string }, target: string = '_self') {
        params = { ...params, 'ApiUrl': url }
        const queryString = this.createQueryString(params);
        url = `pageFormPost?${queryString}`;
        window.open(url, target);
    }

    openInPopup(url: string, params: { [key: string]: string }) {
        const queryString = this.createQueryString(params);
        url = `${url}?${queryString}`;
        const windowName = 'newPopup';
        const windowSize = 'width=800,height=600';
        window.open(url, windowName, windowSize);
    }

    openRedirect(url: string, params: { [key: string]: string }) {
        const queryString = this.createQueryString(params);
        url = `${url}?${queryString}`;
        window.open(url, '_self');
    }

    openQRCode(url: string, params: { [key: string]: string }) {
        const data = params['Data'];
        const qrCodeUrl = `qrcode?data=${encodeURIComponent(data)}`;
        window.open(qrCodeUrl, '_self');
    }

    openHtml(url: string, params: { [key: string]: string }) {
        const data = params['Data'];
        const htmlUrl = `html?data=${encodeURIComponent(data)}`;
        window.open(htmlUrl, '_self');
    }

    createQueryString(params: { [key: string]: string }) {
        return Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');
    }

    getItemSearchQuery = (dto: INavBarOption, merchantId: BigInt): string => {
        var request: IPortalQueryItemByMerchantRequest = {
            merchantId: merchantId,
            categoryIds: dto.categoryIds,
            itemIds: dto.itemIds,
            itemTagIds: dto.itemTagIds,
        }
        return encodeURIComponent(JSONBig.stringify(request));
    };

    getItemSearchQueryByRequest = (request: IPortalQueryItemByMerchantRequest, merchantId: BigInt): string => {
        return encodeURIComponent(JSONBig.stringify(request));
    };
}