import React, { useContext } from 'react';
import { useGetMerchantUpgradeOrderInvoiceSettingApi, useAlterMerchantUpgradeOrderInvoiceSettingApi } from '@/lib/api/merchants';
import { Form, Input, Select, Button, Drawer, message, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { IAlterMerchantUpgradeOrderInvoiceSettingRequest } from '@/interfaces/Requests/Merchant';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';

const { Option } = Select;

interface MerchantUpgradeOrderInvoiceProps {
    orderId?: string;
}

// 修改發票類型的介面
interface InvoiceSettings {
    invoiceType: 'personal-electronic' | 'company-electronic' | 'donate';
    taxNumber?: string;
    invoiceTitle?: string;
    email: string;
    carrierNumber?: string;  // 新增個人電子載具欄位
}

const MerchantUpgradeOrderInvoice: React.FC<MerchantUpgradeOrderInvoiceProps> = ({ orderId }) => {
    const [form] = Form.useForm();
    const [drawerVisible, setDrawerVisible] = React.useState(false);
    const [invoiceType, setInvoiceType] = React.useState<'personal-electronic' | 'company-electronic' | 'donate'>('donate');
    const { merchantPortalOptionSettingMutation } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    // 修改為使用 useMutation 獲取發票設置數據
    const { mutate: fetchInvoiceSettings, isLoading: isLoadingData, error, data } = useMutation({
        mutationFn: () => useGetMerchantUpgradeOrderInvoiceSettingApi(),
        onError: (error) => {
            message.error('載入失敗，請稍後重試');
            console.error('Fetch failed:', error);
        }
    });

    // 在組件加載時獲取數據
    React.useEffect(() => {
        fetchInvoiceSettings();
    }, [fetchInvoiceSettings]);

    // 當數據加載完成後，更新表單值
    React.useEffect(() => {
        if (data) {
            const formValues = {
                invoiceTitle: data.result?.invoiceTitle || '',
                taxNumber: data.result?.taxNumber || '',
                invoiceType: data.result?.invoiceValues?.invoiceType || 'donate',
                email: data.result?.invoiceValues?.email || '',
                carrierNumber: data.result?.invoiceValues?.carrierNumber || '',
            };
            form.setFieldsValue(formValues);
            setInvoiceType(formValues.invoiceType as 'personal-electronic' | 'company-electronic' | 'donate');
        }
    }, [data, form]);

    // 更新發票設置的 mutation
    const { mutate, isLoading: isUpdating } = useMutation({
        mutationFn: (request: IAlterMerchantUpgradeOrderInvoiceSettingRequest) => useAlterMerchantUpgradeOrderInvoiceSettingApi(request),
        onSuccess: () => {
            message.success('發票設置已更新');
            fetchInvoiceSettings(); // 保存成功後重新獲取數據
        },
        onError: (error) => {
            message.error('更新失敗，請稍後重試');
            console.error('Update failed:', error);
        }
    });

    // 處理表單提交
    const handleSubmit = (values: InvoiceSettings) => {
        const invoiceValues: Record<string, string> = {
            invoiceType: values.invoiceType,
        };

        // 將其他欄位放入 invoiceValues
        if (values.email) {
            invoiceValues['email'] = values.email;
        }

        if (values.carrierNumber) {
            invoiceValues['carrierNumber'] = values.carrierNumber;
        }

        mutate({
            invoiceTitle: values.invoiceTitle || '',
            taxNumber: values.taxNumber || '',
            invoiceValues: invoiceValues,
            invoiceCountry: merchantPortalOptionSettingMutation.data?.result?.country || '',
        });
    };

    // 修改處理發票類型變更的邏輯
    const handleInvoiceTypeChange = (value: 'personal-electronic' | 'company-electronic' | 'donate') => {
        setInvoiceType(value);
        // 當切換到捐贈或個人電子發票時，清空公司相關欄位
        if (value === 'donate' || value === 'personal-electronic') {
            form.setFieldsValue({
                taxNumber: '',
                invoiceTitle: '',
                email: '',
            });
        }
        // 當切換到公司電子發票時，清空載具號碼
        if (value === 'company-electronic') {
            form.setFieldsValue({
                carrierNumber: '',
                email: '',
            });
        }
    };

    // 處理 Drawer 開關
    const showDrawer = () => {
        setDrawerVisible(true);
        fetchInvoiceSettings();
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    if (isLoadingData) {
        return <LoadingOutlined style={{ fontSize: 24 }} spin />;
    }

    if (error) {
        return <div>載入失敗，請重試</div>;
    }

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                發票設置
            </Button>

            <Drawer
                title="發票設置"
                placement="right"
                onClose={closeDrawer}
                open={drawerVisible}
                width={400}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => {
                        handleSubmit(values);
                        closeDrawer();
                    }}
                >
                    <Form.Item
                        label="發票類型"
                        name="invoiceType"
                        rules={[{ required: true, message: '請選擇發票類型' }]}
                    >
                        <Select onChange={handleInvoiceTypeChange}>
                            <Option value="donate">捐贈發票</Option>
                            <Option value="personal-electronic">個人電子發票</Option>
                            <Option value="company-electronic">公司電子發票</Option>
                        </Select>
                    </Form.Item>

                    {invoiceType === 'personal-electronic' && (
                        <>
                            <Form.Item
                                label="個人電子載具"
                                name="carrierNumber"
                                rules={[
                                    { required: true, message: '請輸入個人電子載具號碼' },
                                    { pattern: /^\/{1}[0-9A-Z]{7}$/, message: '請輸入正確格式的載具號碼（例如：/ABC1234）' }
                                ]}
                            >
                                <Input placeholder="請輸入個人電子載具號碼" />
                            </Form.Item>

                            <Form.Item
                                label="電子郵件"
                                name="email"
                                rules={[
                                    { required: true, message: '請輸入電子郵件' },
                                    { type: 'email', message: '請輸入有效的電子郵件地址' }
                                ]}
                            >
                                <Input placeholder="請輸入電子郵件" />
                            </Form.Item>
                        </>
                    )}

                    {invoiceType === 'company-electronic' && (
                        <>
                            <Form.Item
                                label="統一編號"
                                name="taxNumber"
                                rules={[
                                    { required: true, message: '請輸入統一編號' },
                                    { len: 8, message: '統一編號必須為8位數字' }
                                ]}
                            >
                                <Input placeholder="請輸入統一編號" />
                            </Form.Item>

                            <Form.Item
                                label="發票抬頭"
                                name="invoiceTitle"
                                rules={[{ required: true, message: '請輸入發票抬頭' }]}
                            >
                                <Input placeholder="請輸入發票抬頭" />
                            </Form.Item>

                            <Form.Item
                                label="電子郵件"
                                name="email"
                                rules={[
                                    { required: true, message: '請輸入電子郵件' },
                                    { type: 'email', message: '請輸入有效的電子郵件地址' }
                                ]}
                            >
                                <Input placeholder="請輸入電子郵件" />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item>
                        <Row justify="center">
                            <Button type="primary" htmlType="submit" loading={isUpdating}>
                                保存設置
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default MerchantUpgradeOrderInvoice;
