import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Card, Flex, Select, Typography } from 'antd';
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './DisclaimerPage.css'; // 引入自定义的 CSS 文件

const { Title, Paragraph } = Typography;

const DisclaimerPage: React.FC = () => {
    const navigate = useNavigate();
    const { isHideNavBar, isLogin, setIsHideAnnouncement } = useContext(GlobalContext);
    const { language, translate, supportedLanguages, seti18nLanguage } = useContext(TranslationContext);
    const [platformName, setPlatformName] = React.useState<string>('Domigo');
    const languaguesSelectorUI = (): React.ReactNode => {
        const uI = supportedLanguages.map((lang) => (
            <Select.Option key={lang} value={lang}>
                {translate(lang)}
            </Select.Option>
        ));
        return (
            <Select
                style={{ width: 'auto' }}
                defaultValue={language}
                onChange={(value: string) => seti18nLanguage(value)}
            >
                {uI}
            </Select>
        );
    };
    const disclaimerContentZhTW = (
        <>
            <Title level={1} className="title-center">免責聲明</Title>
            <Paragraph className="title-center">歡迎來到 {platformName} 。在您使用本平台服務之前，請仔細閱讀以下免責聲明。</Paragraph>

            <Title level={2} className="title-center">服務變更</Title>
            <Paragraph className="title-center">{platformName} 保留於任何時點，不經通知隨時修改或暫時或永久停止繼續提供本服務（或其任一部分）的權利。您同意 {platformName} 對於您或是任何第三方對於任何修改、暫停或停止繼續提供本服務不負任何責任。</Paragraph>

            <Title level={2} className="title-center">終止</Title>
            <Paragraph className="title-center">您同意 {platformName} 得依其判斷因任何理由，包含但不限於一定期間未使用、法院或政府機關命令、本服務無法繼續或服務內容實質變更、無法預期之技術或安全因素或問題、您所為詐欺或違法行為、未依約支付費用，或其他 {platformName} 認為您已經違反本服務條款的明文規定，而終止或限制您使用帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除。</Paragraph>
            <Paragraph className="title-center">您並同意 {platformName} 亦得依其自行之考量，於通知或未通知之情形下，隨時終止或限制您使用本服務或其任何部分。您承認並同意前開終止或限制， {platformName} 得立即關閉、刪除或限制存取您的帳號及您帳號中全部或部分相關資料及檔案，及停止本服務全部或部分之使用。此外，您同意若本服務之使用被終止或限制時， {platformName} 對您或任何第三人均不承擔責任。</Paragraph>

            <Title level={2} className="title-center">一般條款、管轄法院</Title>
            <Paragraph className="title-center">本服務條款構成您與 {platformName} 就您使用本服務之完整合意，取代您先前與 {platformName} 間有關本服務所為之任何約定。您於使用或購買特定 {platformName} 服務、關係企業服務、第三方內容或軟體時，可能亦須適用額外條款或條件。</Paragraph>
            <Paragraph className="title-center">本服務條款之解釋與適用，以及與本服務條款有關的爭議，除法律另有規定者外，均應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院。{platformName} 未行使或執行本服務條款任何權利或規定，不構成前開權利或規定之棄權。若任何本服務條款規定，經有管轄權之法院認定無效，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本服務條款之其他規定仍應完全有效。</Paragraph>
            <Paragraph className="title-center">您同意您的 {platformName} 帳號及您購買的服務係不可轉讓，且您 {platformName} 帳號或帳號中內容之任何權利，於您註銷帳號時即立刻終止。您的帳號將被終止，且其所有內容將被永久刪除。本服務條款之標題僅係為方便起見，不具任何法律或契約效果。若有任何違反本服務條款之行為，請立即與 {platformName} 客服聯絡。</Paragraph>
            <Paragraph className="title-center">為維護會員完整的服務，您及消費者之會員資料，得合於法令規定範圍內，供 {platformName} 處理及利用。{platformName} 對於本協議內容擁有最終解釋權和修訂權。因應電子商務瞬息萬變，為確保本協議之內容不論在任何時刻，皆能符合當地行政機關及市場需求，{platformName} 有權適時修改本協議之條款，修改之內容需以考量您與 {platformName} 雙方之利益為原則，您需隨時注意相關公告及修正。</Paragraph>

            <Title level={2} className="title-center">負責</Title>
            <Paragraph className="title-center">因您經由本服務致侵害其他人任何權利所衍生之損害時，與 {platformName} 無涉。</Paragraph>

            <Title level={2} className="title-center">智慧財產權的保護</Title>
            <Paragraph className="title-center">{platformName} 所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計、會員內容等，均由 {platformName} 或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、公開傳輸、公開演出、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。</Paragraph>
            <Paragraph className="title-center">若您欲引用或轉載前述軟體、程式或網站內容，除明確為法律所許可者外，必須依法取得 {platformName} 或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對 {platformName} 負損害賠償責任。{platformName} 及其關係企業為行銷宣傳本服務，就本服務相關之商品或服務名稱、圖樣等（以下稱「{platformName} 商標」），依其註冊或使用之狀態，受商標法及公平交易法等之保護，未經 {platformName} 事前書面同意，您同意不以任何方式使用 {platformName} 商標、服務標章、公司名稱及任何智慧財產。</Paragraph>

            <Title level={2} className="title-center">著作權侵害處理</Title>
            <Paragraph className="title-center">{platformName} 尊重他人著作權，亦要求我們的使用者同樣尊重他人著作權，您瞭解並同意我們將依據「{platformName} 著作權保護政策」保護著作權並處理著作權侵害之事宜。若您認為 {platformName} 網站中之任何網頁內容或網友使用 {platformName} 服務已侵害您的著作權，請您詳閱「{platformName} 著作權保護政策」後填寫「著作權侵害通知書」，並請依該通知書所載，提供相關資料及聲明並經簽名或蓋章後傳真或寄送予 {platformName} 。</Paragraph>
            <Paragraph className="title-center">您使用本服務若有三次涉及侵權情事者，{platformName} 將終止您全部或部分服務。又若您所涉及侵權情事嚴重者，{platformName} 亦得暫停或終止您全部或部分服務。</Paragraph>

            <Title level={2} className="title-center">規範</Title>
            <Paragraph className="title-center">用戶在使用本平台時，不得上架與公司所在地國家違法之產品及項目，以及出售購買人國家違法之產品及項目。任何違反此規範的行為將導致帳號被凍結或永久封禁，並可能承擔法律責任。</Paragraph>
            <Paragraph className="title-center">本平台嚴格禁止以下產品和項目的上架與銷售：</Paragraph>
            <ul className="title-center">
                <li>違禁藥物和毒品</li>
                <li>假冒偽劣產品</li>
                <li>盜版軟件和媒體</li>
                <li>非法武器和彈藥</li>
                <li>受保護的野生動物及其製品</li>
                <li>其他任何違反國家法律和國際法的產品及項目</li>
            </ul>
        </>
    );
    const disclaimerContentZhCN = (
        <>
            <Title level={1} className="title-center">免责声明</Title>
            <Paragraph className="title-center">欢迎来到 {platformName}。在您使用本平台服务之前，请仔细阅读以下免责声明。</Paragraph>

            <Title level={2} className="title-center">服务变更</Title>
            <Paragraph className="title-center">{platformName} 保留于任何时点，不经通知随时修改或暂时或永久停止继续提供本服务（或其任一部分）的权利。您同意 {platformName} 对于您或是任何第三方对于任何修改、暂停或停止继续提供本服务不负任何责任。</Paragraph>

            <Title level={2} className="title-center">终止</Title>
            <Paragraph className="title-center">您同意 {platformName} 得依其判断因任何理由，包含但不限于一定期间未使用、法院或政府机关命令、本服务无法继续或服务内容实质变更、无法预期之技术或安全因素或问题、您所为诈欺或违法行为、未依约支付费用，或其他 {platformName} 认为您已经违反本服务条款的明文规定，而终止或限制您使用账号（或其任何部分）或本服务之使用，并将本服务内任何"会员内容"加以移除并删除。</Paragraph>
            <Paragraph className="title-center">您并同意 {platformName} 亦得依其自行之考量，于通知或未通知之情形下，随时终止或限制您使用本服务或其任何部分。您承认并同意前开终止或限制， {platformName} 得立即关闭、删除或限制存取您的账号及您账号中全部或部分相关资料及档案，及停止本服务全部或部分之使用。此外，您同意若本服务之使用被终止或限制时， {platformName} 对您或任何第三人均不承担责任。</Paragraph>

            <Title level={2} className="title-center">一般条款、管辖法院</Title>
            <Paragraph className="title-center">本服务条款构成您与 {platformName} 就您使用本服务之完整合意，取代您先前与 {platformName} 间有关本服务所为之任何约定。您于使用或购买特定 {platformName} 服务、关系企业服务、第三方内容或软件时，可能亦须适用额外条款或条件。</Paragraph>
            <Paragraph className="title-center">本服务条款之解释与适用，以及与本服务条款有关的争议，除法律另有规定者外，均应依照中华民国法律予以处理，并以台湾台北地方法院为第一审管辖法院。{platformName} 未行使或执行本服务条款任何权利或规定，不构成前开权利或规定之放弃。若任何本服务条款规定，经有管辖权之法院认定无效，当事人仍同意法院应努力使当事人于前开规定所表达之真意生效，且本服务条款之其他规定仍应完全有效。</Paragraph>

            <Title level={2} className="title-center">责任</Title>
            <Paragraph className="title-center">因您经由本服务致侵害其他人任何权利所衍生之损害时，与 {platformName} 无涉。</Paragraph>

            <Title level={2} className="title-center">知识产权的保护</Title>
            <Paragraph className="title-center">{platformName} 所使用之软件或程序、网站上所有内容，包括但不限于著作、图片、档案、信息、资料、网站架构、网站画面的安排、网页设计、会员内容等，均由 {platformName} 或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、营业秘密与专有技术等。任何人不得擅自使用、修改、重制、公开播送、公开传输、公开演出、改作、散布、发行、公开发表、进行还原工程、解编或反向组译。</Paragraph>

            <Title level={2} className="title-center">规范</Title>
            <Paragraph className="title-center">用户在使用本平台时，不得上架与公司所在地国家违法之产品及项目，以及出售购买人国家违法之产品及项目。任何违反此规范的行为将导致账号被冻结或永久封禁，并可能承担法律责任。</Paragraph>
            <Paragraph className="title-center">本平台严格禁止以下产品和项目的上架与销售：</Paragraph>
            <ul className="title-center">
                <li>违禁药物和毒品</li>
                <li>假冒伪劣产品</li>
                <li>盗版软件和媒体</li>
                <li>非法武器和弹药</li>
                <li>受保护的野生动物及其制品</li>
                <li>其他任何违反国家法律和国际法的产品及项目</li>
            </ul>
        </>
    );
    const disclaimerContentEn = (
        <>
            <Title level={1} className="title-center">Disclaimer</Title>
            <Paragraph className="title-center">Welcome to {platformName}. Before using our platform services, please read the following disclaimer carefully.</Paragraph>

            <Title level={2} className="title-center">Service Changes</Title>
            <Paragraph className="title-center">{platformName} reserves the right to modify or temporarily or permanently discontinue the service (or any part thereof) at any time without notice. You agree that {platformName} shall not be liable to you or any third party for any modification, suspension, or discontinuance of the service.</Paragraph>

            <Title level={2} className="title-center">Termination</Title>
            <Paragraph className="title-center">You agree that {platformName} may, at its sole discretion, for any reason, including but not limited to inactivity, court or government orders, inability to continue the service or substantial changes in service content, unforeseen technical or security issues or problems, fraudulent or illegal activities, non-payment of fees, or any other reason deemed to be a violation of the express provisions of the Terms of Service, terminate or restrict your use of the account (or any part thereof) or the use of the service, and remove and delete any "member content" within the service.</Paragraph>
            <Paragraph className="title-center">You also agree that {platformName} may, at its sole discretion, terminate or restrict your use of the service or any part thereof at any time, with or without notice. You acknowledge and agree that such termination or restriction may include disabling or deleting your account and all related information and files, and/or barring any further access to such files or the service. Furthermore, you agree that {platformName} shall not be liable to you or any third party for any termination or restriction of your access to the service.</Paragraph>

            <Title level={2} className="title-center">General Terms, Jurisdiction</Title>
            <Paragraph className="title-center">These Terms of Service constitute the entire agreement between you and {platformName} regarding your use of the service, superseding any prior agreements between you and {platformName} concerning the service. Your use of specific {platformName} services, affiliated services, third-party content, or software may be subject to additional terms and conditions.</Paragraph>
            <Paragraph className="title-center">The interpretation and application of these Terms of Service and any disputes related to them shall be governed by the laws of the Republic of China (Taiwan), and the Taipei District Court in Taiwan shall have jurisdiction over the first instance. The failure of {platformName} to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of these Terms of Service remain in full force and effect.</Paragraph>
            <Paragraph className="title-center">You agree that your {platformName} account and the services you purchase are non-transferable, and any rights to your {platformName} account or contents within your account terminate upon your death. Upon receipt of a copy of a death certificate, your account may be terminated, and all contents within your account deleted. The section titles in these Terms of Service are for convenience only and have no legal or contractual effect. If you become aware of any violations of these Terms of Service, please contact {platformName} customer service immediately.</Paragraph>
            <Paragraph className="title-center">To maintain the integrity of the service, your member data and consumer member data may be processed and utilized by {platformName} in accordance with the law. {platformName} reserves the right to make the final interpretation and revision of this agreement. To ensure that the contents of this agreement meet local administrative and market requirements at any time, {platformName} has the right to modify the terms of this agreement in a timely manner, considering the interests of both you and {platformName}. Please pay attention to relevant announcements and revisions, as continued use of the service signifies acceptance of the modified terms.</Paragraph>

            <Title level={2} className="title-center">Liability</Title>
            <Paragraph className="title-center">Any damage caused by your use of the service that infringes on the rights of others is not related to {platformName}.</Paragraph>

            <Title level={2} className="title-center">Intellectual Property Protection</Title>
            <Paragraph className="title-center">{platformName} software or programs, and all content on the website, including but not limited to works, images, files, information, materials, website structure, website layout design, member content, etc., are all owned by {platformName} or other rights holders under the law, including but not limited to trademark rights, patent rights, copyrights, trade secrets, and proprietary technologies. No one may use, modify, reproduce, publicly broadcast, publicly transmit, publicly perform, adapt, distribute, publish, reverse engineer, decompile, or disassemble them without authorization.</Paragraph>
            <Paragraph className="title-center">If you wish to quote or reprint the aforementioned software, programs, or website content, you must obtain prior written consent from {platformName} or other rights holders in accordance with the law. Respect for intellectual property rights is an obligation you must comply with. If you violate this, you shall be liable for damages to {platformName}. {platformName} and its affiliates may use the names, trademarks, logos, and other related products or service names related to this service for marketing and promotional purposes (hereinafter referred to as "{platformName} Trademarks"). These are protected by trademark law and the Fair Trade Act, and you agree not to use {platformName} Trademarks, service marks, company names, or any intellectual property in any way without prior written consent from {platformName}.</Paragraph>

            <Title level={2} className="title-center">Copyright Infringement Handling</Title>
            <Paragraph className="title-center">{platformName} respects the copyrights of others and requires our users to do the same. You understand and agree that we will protect copyrights and handle copyright infringement matters in accordance with the "{platformName} Copyright Protection Policy." If you believe that any webpage content on the {platformName} website or user content using the {platformName} service infringes your copyright, please read the "{platformName} Copyright Protection Policy" and fill out the "Copyright Infringement Notice" and provide the relevant information and declarations as described, and sign or stamp it before faxing or sending it to {platformName}.</Paragraph>
            <Paragraph className="title-center">If you have three instances of infringement while using the service, {platformName} will terminate all or part of your service. If the infringement is serious, {platformName} may also suspend or terminate all or part of your service.</Paragraph>

            <Title level={2} className="title-center">Regulations</Title>
            <Paragraph className="title-center">Users are prohibited from listing products and items that are illegal in the company's country of operation and the buyer's country. Any violation of this regulation will result in account suspension or permanent ban and may result in legal liability.</Paragraph>
            <Paragraph className="title-center">The platform strictly prohibits the listing and sale of the following products and items:</Paragraph>
            <ul className="title-center">
                <li>Illegal drugs and narcotics</li>
                <li>Counterfeit and pirated products</li>
                <li>Unauthorized software and media</li>
                <li>Illegal weapons and ammunition</li>
                <li>Protected wildlife and products derived from them</li>
                <li>Any other products and items that violate national or international laws</li>
            </ul>
        </>
    );

    useEffect(() => {
        setIsHideAnnouncement(true);
        return () => {
            setIsHideAnnouncement(false);
        };
    }, []);

    return (
        <>
            {
                isHideNavBar || !isLogin && (
                    <Flex justify="center" align="center" style={{ margin: '10px' }}>
                        <Flex justify="center" align="center">
                            {
                                languaguesSelectorUI()
                            }
                        </Flex>
                    </Flex>
                )
            }
            <Flex justify="center">
                <Card style={{ width: '50%', margin: '20px' }}>
                    <Typography>
                        {language === 'zh_TW' ? disclaimerContentZhTW :
                            language === 'zh_CN' ? disclaimerContentZhCN :
                                disclaimerContentEn}
                    </Typography>
                </Card>
            </Flex>

            <Flex justify="center" align="center">
                <Button
                    onClick={() => {
                        navigate('/');
                    }}>
                    {translate('Back to home page')}
                </Button>
            </Flex>
        </>
    );
};

export default DisclaimerPage;
