import { IDeleteCategoryRequest } from "@/interfaces/Requests/Category";
import { IAlterCategoryRequest } from "@/interfaces/Requests/Category";
import { IAddCategoryRequest } from "@/interfaces/Requests/Category";
import { ICategory, IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

// 获取商家分类列表
export const useGetMerchantCategoriesApi = (merchantId: BigInt, signal?: AbortSignal) =>
    useAPI<IOperationResultT<ICategory[]>>(`${process.env.BASE_API_URL}/api/v1/Category/GetMerchantCategories?merchantId=${merchantId}`, {
        signal
    });

// 新增分类
export const useAddCategoryApi = (request: IAddCategoryRequest) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Category/AddCategroy`, {
        method: 'POST',
        body: request
    });

// 修改分类
export const useAlterCategoryApi = (request: IAlterCategoryRequest) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Category/AlterCategroy`, {
        method: 'POST',
        body: request
    });

// 删除分类
export const useDeleteCategoryApi = (request: IDeleteCategoryRequest) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Category/DeleteCategory`, {
        method: 'POST',
        body: request
    });