import { OrderStatus } from "@/enums/Enums";
import { IGetBackOfficeOrderViewModelRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeOrderViewModel, IOrder, IOrderOperationHistory } from "@/interfaces/Responses/Responses";
import { useGetOrderOperationHistoriesApi } from "@/lib/api/orders";
import { useGetBackOfficeOrderViewModelApi } from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { TimeHelper } from "@/Templates/helpers/TimeHelper";
import { IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import { Card, Col, Descriptions, Divider, Image, List, Row, Tag, Timeline, Typography, Button, Flex } from "antd";
import dayjs from 'dayjs';
import React, { useContext, useEffect, useImperativeHandle, useState, useRef } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import InvoiceOrder from "./InvoiceOrder";
import LogisticOrder from "./LogisticOrder";
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import PickingList from './PickingList';
const { Text } = Typography;

export interface IOrderDetailProps {
    order: IOrder | undefined;
}

export interface IOrderDetailRef {
    onOpen: () => void;
    onClose: () => void;
}

const OrderDetail = React.forwardRef((props: IOrderDetailProps, ref: React.ForwardedRef<IOrderDetailRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const { data: backOfficeOrderViewModelFromApi, mutate } = useMutation(async (request: IGetBackOfficeOrderViewModelRequest) => await useGetBackOfficeOrderViewModelApi(request));
    const { data: getOrderOperationHistoriesFromApi, mutate: useGetOrderOperationHistoriesApiMutate } = useMutation(async (orderId: BigInt) => await useGetOrderOperationHistoriesApi(orderId));
    const [backOfficeOrderViewModel, setBackOfficeOrderViewModel] = useState<IOperationResultT<IBackOfficeOrderViewModel> | undefined>(undefined);
    const [orderHistory, setOrderHistory] = useState<IOrderOperationHistory[]>([]);
    const pickListRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({ contentRef: pickListRef });

    const OrderDetailDisplay = (backOfficeOrderViewModel: IOperationResultT<IBackOfficeOrderViewModel>) => {
        const order = backOfficeOrderViewModel.result;
        if (!order) return null;

        const color = getStatusColor(order.status);

        return (
            <div style={{ padding: '20px' }}>
                <Card
                    title={translate('Order Details')}
                    extra={(
                        <>
                            <Button
                                icon={<PrinterOutlined />}
                                type="primary"
                                onClick={() => handlePrint()}
                                style={{ marginRight: 8 }}>
                                {translate('Print Picking List')}
                            </Button>
                            <Tag color={color}>{translate(order.status)}</Tag>
                            <Tag color={order.isPaid ? 'green' : 'red'}>{translate(order.isPaid ? 'Paid' : 'Unpaid')}</Tag>
                        </>
                    )}
                    bordered
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Descriptions column={1} size="small">
                                {
                                    !order.isNoRegistOrder && (
                                        <Descriptions.Item label={translate('Member ID')}>
                                            <Link to={`/memberManage?memberId=${order.memberId}`} style={{ color: 'blue' }}>
                                                {order.memberId.toString()}
                                            </Link>
                                        </Descriptions.Item>
                                    )
                                    ||
                                    <Descriptions.Item>
                                        {translate('Anonymous Order')}
                                    </Descriptions.Item>
                                }
                                <Descriptions.Item label={translate('CreatedTime')}>
                                    {TimeHelper.formatUtcStringToLocal(order.createdTime)}
                                </Descriptions.Item>
                                {
                                    order.status === OrderStatus.Cancel || order.status === OrderStatus.RefundDone &&
                                    <Descriptions.Item label={translate('Current Has Refilled Stock Amount')}>
                                        <Tag color={order.hasRefilledStockAmount ? 'blue' : 'red'}>
                                            {order.hasRefilledStockAmount ? translate('Yes') : translate('No')}
                                        </Tag>
                                    </Descriptions.Item>
                                }
                            </Descriptions>
                        </Col>
                        <Col xs={24} md={12}>
                            <Descriptions column={1} size="small">
                                <Descriptions.Item label={`${translate('Item')} ${translate('Count')}`}>
                                    {order.items.reduce((acc, item) => acc + item.buyAmount, 0)}
                                </Descriptions.Item>
                                <Descriptions.Item label={translate('Total final price')}>{order.totalFinalPrice}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        {
                            order.note && (
                                <Col xs={24} md={12}>
                                    <Descriptions column={1} size="small">
                                        <Descriptions.Item label={translate('Note')}>
                                            {order.note}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            )
                        }
                    </Row>

                    <Divider>{translate('Items')}</Divider>
                    <List
                        itemLayout="vertical"
                        dataSource={order.items}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <Card>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} md={8}>
                                            <Image src={item.itemIcon} alt={item.itemName} />
                                        </Col>
                                        <Col xs={24} md={16}>
                                            <Descriptions column={1} size="small">
                                                <Descriptions.Item label={translate('ItemName')}>{item.itemName}</Descriptions.Item>
                                                <Descriptions.Item label={translate('Specification')}>{item.itemSpecName}</Descriptions.Item>
                                                <Descriptions.Item label={translate('Buy amount')}>{item.buyAmount}</Descriptions.Item>
                                                <Descriptions.Item label={translate('Sell price')}>{item.itemSpecPrice}</Descriptions.Item>
                                                <Descriptions.Item label={translate('Total price')}>
                                                    {item.buyAmount * item.itemSpecPrice}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    {order.orderDiscontHistory && (
                        <>
                            <Divider>{translate('Order Discount')}</Divider>
                            <Descriptions column={1} size="small">
                                <Descriptions.Item label={translate('Discount Name')}>
                                    {order.orderDiscontHistory.discountName}
                                </Descriptions.Item>
                                <Descriptions.Item label={translate('Discount Amount')}>
                                    {order.orderDiscontHistory.disccountAmount}
                                </Descriptions.Item>
                                <Descriptions.Item label={translate('Discount Description')}>
                                    {order.orderDiscontHistory.discountDescription}
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    )}
                </Card>



                {order.status !== OrderStatus.WaitForPaid && (
                    <>
                        <Divider />
                        <div style={{ marginTop: '20px' }}>
                            <LogisticOrder order={order} />
                        </div>
                    </>
                )}

                {
                    order.status !== OrderStatus.WaitForPaid &&
                    <>
                        <Divider />
                        <div style={{ marginTop: '20px' }}>
                            <InvoiceOrder order={order} />
                        </div>
                    </>
                }

                <Divider />

                <Card title={translate('Order Status History')} style={{ marginTop: '20px' }}>
                    {renderTimeline()}
                </Card>
            </div>
        );
    };

    const getStatusColor = (status: OrderStatus): string => {
        switch (status) {
            case OrderStatus.WaitForPaid:
                return 'orange';
            case OrderStatus.Paid:
                return 'green';
            case OrderStatus.WaitForMerchantConfirm:
            case OrderStatus.WaitForShipment:
                return 'blue';
            case OrderStatus.DuringShipment:
            case OrderStatus.WaitForBuyerPickup:
                return 'cyan';
            case OrderStatus.Done:
                return 'green';
            case OrderStatus.ShipmentDispute:
            case OrderStatus.BuyerNotPickup:
            case OrderStatus.ReturnToLogisticsCenter:
                return 'yellow';
            case OrderStatus.BuyerApplyRefund:
            case OrderStatus.WaitForMerchantConfirmRefundApplication:
            case OrderStatus.WaitForReturnShipment:
            case OrderStatus.DuringReturnShipment:
            case OrderStatus.WaitForMerchantPickupRefund:
            case OrderStatus.WaitForMerchantConfirmRefundItems:
            case OrderStatus.WaitForMerchantRefundAmount:
                return 'purple';
            case OrderStatus.ReturnShipmentDispute:
            case OrderStatus.MerchantNotPickup:
            case OrderStatus.RefundUnderDispute:
                return 'red';
            case OrderStatus.RefundDone:
                return 'green';
            case OrderStatus.Cancel:
            case OrderStatus.Unknow:
            default:
                return 'gray';
        }
    };

    const getStatusChangeHistory = () => {
        return orderHistory
            .filter(history =>
                history.description.startsWith('Order status change') ||
                history.description.startsWith('Order payment status changed') ||
                history.description.startsWith('Order has refilled stock amount:'))
            .map(history => {
                let match;
                let type;
                let status;
                if (history.description.startsWith('Order status change')) {
                    match = history.description.match(/Order status change(?:d)? to (\w+)(?:\.|) Reason: (.*)/);
                    type = 'status';
                    status = match ? match[1] : 'Unknown';
                } else if (history.description.startsWith('Order payment status changed')) {
                    match = history.description.match(/Order payment status changed to (\w+)\. Reason: (.*)/);
                    type = 'payment';
                    status = match ? match[1].toLowerCase() === 'true' : false;
                } else {
                    match = history.description.match(/Order has refilled stock amount: (\w+)\. Reason: (.*)/);
                    type = 'stock';
                    status = match ? match[1].toLowerCase() === 'true' : false;
                }
                return {
                    type: type,
                    status: status,
                    time: dayjs(history.createdTime).format('YYYY-MM-DD HH:mm:ss'),
                    description: history.description,
                    operator: history.operater,
                    reason: match ? match[2] : ''
                };
            })
            .sort((a, b) => dayjs(b.time).diff(dayjs(a.time)));
    };

    const renderTimeline = () => {
        const statusHistory = getStatusChangeHistory();
        return (
            <Timeline>
                {statusHistory.map((item, index) => (
                    <Timeline.Item
                        key={index}
                        color={getTimelineItemColor(item)}
                    >
                        <p>
                            {getTimelineItemTitle(item)}
                            {' - '}{item.time}
                        </p>
                        <p>{translate('Operator')}: {translate(item.operator)}</p>
                        {item.reason && <p>{translate('Reason')}: {translate(item.reason)}</p>}
                    </Timeline.Item>
                ))}
            </Timeline>
        );
    };

    const getTimelineItemColor = (item: any) => {
        switch (item.type) {
            case 'status':
                return getStatusColor(item.status as OrderStatus);
            case 'payment':
                return item.status ? 'green' : 'red';
            case 'stock':
                return 'blue';
            default:
                return 'gray';
        }
    };

    const getTimelineItemTitle = (item: any) => {
        switch (item.type) {
            case 'status':
                return (
                    <>
                        {translate('Order Status')}: <Tag color={getStatusColor(item.status as OrderStatus)}>{translate(item.status)}</Tag>
                    </>
                );
            case 'payment':
                return (
                    <>
                        {translate('IsPaid')}: <Tag color={item.status ? 'green' : 'red'}>{item.status ? translate('Paid') : translate('Unpaid')}</Tag>
                    </>
                );
            case 'stock':
                return (
                    <>
                        {translate('Current Has Refilled Stock Amount')}: <Tag color="blue">{item.status ? translate('Yes') : translate('No')}</Tag>
                    </>
                );
            default:
                return translate('Unknown Event');
        }
    };

    useEffect(() => {
        if (backOfficeOrderViewModelFromApi)
            setBackOfficeOrderViewModel(backOfficeOrderViewModelFromApi);
    }, [backOfficeOrderViewModelFromApi]);

    useEffect(() => {
        if (getOrderOperationHistoriesFromApi && getOrderOperationHistoriesFromApi.isSuccess) {
            setOrderHistory(getOrderOperationHistoriesFromApi.result || []);
        }
    }, [getOrderOperationHistoriesFromApi]);

    useImperativeHandle(ref, () => ({
        onOpen: () => {
            if (props.order?.id && merchantId) {
                var request: IGetBackOfficeOrderViewModelRequest = {
                    orderId: props.order.id.toString(),
                };
                mutate(request);
                useGetOrderOperationHistoriesApiMutate(props.order.id);
            }
        },
        onClose: () => {
            setBackOfficeOrderViewModel(undefined);
        },
    }));

    if (!backOfficeOrderViewModel || !backOfficeOrderViewModel.isSuccess) {
        return <LoadingComponent />;
    }

    return (
        backOfficeOrderViewModel.result &&
        <>
            {OrderDetailDisplay(backOfficeOrderViewModel)}
            <div style={{ display: 'none' }}>
                {backOfficeOrderViewModel.result &&
                    <PickingList
                        ref={pickListRef}
                        order={backOfficeOrderViewModel.result}
                        translate={translate}
                    />
                }
            </div>
        </>
    )
});

export default OrderDetail;