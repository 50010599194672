import { LogisticOrderType } from "@/enums/ThirdPartyLogistic";
import { IAddMerchantThirdPartyLogisticSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyLogisticSettingViewModel } from "@/interfaces/Responses/Responses";
import { useAddMerchantThirdPartyLogisticSettingApi, useGetThirdPartyLogisticsApi } from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { LogisticOrderLimitType } from "@/Templates/enums/templateEnums";
import { Button, Empty, Form, Input, Row, Select, Tag, Typography } from "antd";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";
import InformationPopover from "./Popovers/InformationPopver";

const { Title } = Typography;

export interface IAddLogisticSettingProps {
    ownRecords: IBackOfficeMerchantThirdPartyLogisticSettingViewModel[] | undefined;
    onRefresh: () => void;
    onClose: () => void;
}

export interface IAddLogisticSettingRef {
    refresh: () => void;
}

const AddLogistic = React.forwardRef((props: IAddLogisticSettingProps, ref: React.ForwardedRef<IAddLogisticSettingRef | undefined>) => {
    const [form] = Form.useForm();
    const { merchantId, messageApi, merchantPortalOptionSettingMutation } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    const [selectedLimitType, setSelectedLimitType] = useState<LogisticOrderLimitType | undefined>();
    const [selectedOrderType, setSelectedOrderType] = useState<LogisticOrderType | undefined>();
    const [selectedMainType, setSelectedMainType] = useState<string | undefined>();
    const [selectedTplId, setSelectedTplId] = useState<string | undefined>();

    const { data: tplsData, mutate: fetchTpls, isLoading } = useMutation(
        async () => await useGetThirdPartyLogisticsApi(),
        {
            onSuccess: (response) => {
                if (!response.isSuccess) {
                    messageApi.error(translate(response.message || 'Operation failed'));
                } else {
                    setDefaultLimitType(response.result || []);
                }
            }
        }
    );

    const { mutate: addSetting } = useMutation(
        async (request: IAddMerchantThirdPartyLogisticSettingRequest) =>
            await useAddMerchantThirdPartyLogisticSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    props.onRefresh();
                    props.onClose();
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const avaliableTpls = tplsData?.result?.filter(tpl =>
        tpl.supportedCountry === merchantPortalOptionSettingMutation.data?.result?.country &&
        !props.ownRecords?.find(record => record.thirdPartySettingId.toString() === tpl.id.toString())
    ) || [];

    const filteredTpls = avaliableTpls.filter(tpl =>
        tpl.logisticsMainType === selectedMainType &&
        (selectedLimitType === undefined || tpl.logisticOrderLimitType === selectedLimitType) &&
        (
            selectedOrderType === undefined ||
            (selectedOrderType === LogisticOrderType.Forward && tpl.isSupportedForwardFlow) ||
            (selectedOrderType === LogisticOrderType.Reverse && tpl.isSupportedReverseFlow)
        )
    );

    const logisticsOrderLimitTypes = [...new Set(avaliableTpls.map(tpl => tpl.logisticOrderLimitType))];
    const logisticsMainTypes = [...new Set(avaliableTpls.map(tpl => tpl.logisticsMainType))];

    const setDefaultLimitType = (tpls: any[]) => {
        const availableLimitTypes = [...new Set(tpls.map(tpl => tpl.logisticOrderLimitType))];
        if (availableLimitTypes.length > 0) {
            const defaultLimitType = availableLimitTypes[0];
            setSelectedLimitType(defaultLimitType);
            form.setFieldsValue({ limitType: defaultLimitType });
        }
    };

    useEffect(() => {
        fetchTpls();
    }, []);

    useEffect(() => {
        if (tplsData?.result) {
            setDefaultLimitType(tplsData.result);
        }
    }, [tplsData]);

    useEffect(() => {
        setSelectedTplId(undefined);
        form.setFieldsValue({ selectedTpl: undefined });
    }, [selectedLimitType, selectedOrderType, selectedMainType]);

    useImperativeHandle(ref, () => ({
        refresh: () => {
            form.resetFields();
            setSelectedLimitType(undefined);
            setSelectedOrderType(undefined);
            setSelectedMainType(undefined);
            setSelectedTplId(undefined);
            fetchTpls();
        }
    }));

    const handleLimitTypeClick = (limitType: LogisticOrderLimitType) => {
        setSelectedLimitType(limitType);
        form.setFieldsValue({ limitType });
    };

    const handleSubmit = () => {
        const selectedTpl = filteredTpls.find(tpl => tpl.id.toString() === selectedTplId);
        if (!selectedTpl || !merchantId) return;

        const formValues = form.getFieldsValue();
        const setting = selectedTpl.merchantFillFieldsForBasicInfo.reduce((acc, field) => {
            acc[field] = formValues[field];
            return acc;
        }, {} as Record<string, string>);

        const request: IAddMerchantThirdPartyLogisticSettingRequest = {
            settingId: selectedTpl.id,
            setting
        };
        addSetting(request);
    };

    if (isLoading) return <LoadingComponent />;

    if (avaliableTpls.length === 0) {
        return (
            <Row justify='center'>
                <Empty />
            </Row>
        );
    }

    return (
        <>
            <Row justify='center'>
                <Title level={4}>
                    {translate(`Available`)} {translate(`Logistic`)}
                </Title>
            </Row>
            <Row justify='center' gutter={[16, 16]}>
                {logisticsOrderLimitTypes.map(limitType => (
                    <Button
                        key={limitType}
                        type={selectedLimitType === limitType ? "primary" : "default"}
                        onClick={() => handleLimitTypeClick(limitType)}
                    >
                        {translate(limitType === LogisticOrderLimitType.None ? 'Normal' : limitType)}
                    </Button>
                ))}
            </Row>
            <Form form={form} layout="vertical" onFinish={handleSubmit} style={{ marginTop: 16 }}>
                <Form.Item name="orderType" label={`${translate('Select')} ${translate('Type')}`} initialValue={null}>
                    <Select
                        onChange={(value: LogisticOrderType | null) => {
                            setSelectedOrderType(value === null ? undefined : value);
                        }}>
                        <Select.Option key={'null'} value={null}>
                            {translate('All')}
                        </Select.Option>
                        {Object.entries(LogisticOrderType).map(([key, value]) => (
                            <Select.Option key={key} value={value}>
                                {translate(value.toString(), 'Logistic')}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="mainType" label={`${translate('Select')} ${translate('Logistic Main Type')}`}>
                    <Select
                        allowClear
                        onChange={(value: string) => setSelectedMainType(value)}>
                        {logisticsMainTypes.map(mainType => (
                            <Select.Option key={mainType} value={mainType}>
                                {translate(mainType)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {selectedMainType && (
                    <Form.Item name="selectedTpl" label={`${translate('Select')} ${translate('Logistic')}`}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option?.children ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                            }
                            onChange={(value: string) => setSelectedTplId(value)}
                        >
                            {filteredTpls.map(tpl => (
                                <Select.Option key={tpl.id.toString()} value={tpl.id.toString()}>
                                    {tpl.providerName}
                                    {
                                        tpl.isSupportedForwardFlow && (
                                            <Tag color="blue" style={{ marginLeft: 4 }}>{translate('Forward', 'Logistic')}</Tag>
                                        )
                                    }
                                    {
                                        tpl.isSupportedReverseFlow && (
                                            <Tag color="green" style={{ marginLeft: 4 }}>{translate('Reverse', 'Logistic')}</Tag>
                                        )
                                    }
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                {selectedTplId && (
                    <Row justify="center" style={{ marginTop: 8 }}>
                        <InformationPopover providerName={filteredTpls.find(tpl => tpl.id.toString() === selectedTplId)?.providerName || ''} />
                    </Row>
                )}
                {filteredTpls.length > 0 && filteredTpls.find(tpl => tpl.id.toString() === selectedTplId)?.merchantFillFieldsForBasicInfo.map((field) => (
                    <Form.Item
                        key={field}
                        name={field}
                        label={translate(field)}
                    >
                        <Input />
                    </Form.Item>
                ))}
                {
                    selectedTplId &&
                    <Row justify='center'>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {translate('Add')}
                            </Button>
                        </Form.Item>
                    </Row>
                }
            </Form>
        </>
    );
});

export default AddLogistic;