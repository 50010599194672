import { IAlterMerchantThirdPartyInvoiceSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceSetting } from "@/interfaces/Responses/ThirdPartyInvoice";
import { useAlterMerchantThirdPartyInvoiceSettingApi, useGetThirdPartyInvoiceSettingApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { Button, Col, Form, Input, Row } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";

export interface IEditInvoiceProps {
    record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel;
}
export interface IEditInvoiceRef {
}

const EditInvoice = React.forwardRef((props: IEditInvoiceProps, ref: React.ForwardedRef<IEditInvoiceRef | undefined>) => {
    const [form] = Form.useForm();
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [tpis, setTpis] = useState<IThirdPartyInvoiceSetting | undefined>(undefined);
    const [merchantSettings, setMerchantSettings] = useState<Record<string, string>>({});

    const { mutate: fetchSettings } = useMutation(
        async (settingId: BigInt) => await useGetThirdPartyInvoiceSettingApi(settingId),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    setTpis(response.result);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const { mutate: alterSettings, isLoading } = useMutation(
        async (request: IAlterMerchantThirdPartyInvoiceSettingRequest) => await useAlterMerchantThirdPartyInvoiceSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    useEffect(() => {
        fetchSettings(props.record.thirdPartySettingId);
    }, [props.record]);

    useEffect(() => {
        if (tpis && props.record && props.record.merchantSettings) {
            const initialValues = tpis.merchantFillFieldsForBasicInfo.reduce((acc: Record<string, string>, field) => {
                acc[field] = props.record.merchantSettings?.[field] || '';
                return acc;
            }, {});
            setMerchantSettings(initialValues);
            form.setFieldsValue(initialValues);
        }
    }, [tpis, props.record, form]);

    const handleInputChange = (field: string, value: string) => {
        setMerchantSettings(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = () => {
        if (!merchantId || !props.record) return;

        const request: IAlterMerchantThirdPartyInvoiceSettingRequest = {
            settingId: props.record.thirdPartySettingId,
            setting: merchantSettings,
            isDisabled: props.record.isDisable,
        };
        alterSettings(request);
    };

    if (!tpis || !props.record) return <LoadingComponent />;

    return (
        <div>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {tpis.merchantFillFieldsForBasicInfo.map((key) => (
                    <Form.Item
                        key={key}
                        name={key}
                        label={translate(key)}
                    >
                        <Row justify="space-between">
                            <Col span={20}>
                                <Input
                                    value={merchantSettings[key] || ''}
                                    onChange={(e) => handleInputChange(key, e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                ))}
                <Row justify="center">
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {translate('Save')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
});
export default EditInvoice;