import { Button, Flex, Form, Modal, Tabs, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { IGetMemberInfoViewModelByMerchantRequest } from "@/interfaces/Requests/Requests";
import { IMemberInfoViewModel, IPaginationResponse } from "@/interfaces/Responses/Responses";
import { useGetMemberInfoViewModelByMerchantApi } from "@/lib/api/members";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import type { ActionType, ProColumns, ProFormInstance } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import MemberListTab from "./Components/MemberListTab";
import MemberLevelTab from "./Components/MemberConsumptionLevelTab";
import MemberConsumptionLevelTab from "./Components/MemberConsumptionLevelTab";

const { Text } = Typography;
const { TabPane } = Tabs;

const MemberManagePage: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { messageApi, merchantId, deviceType } = useContext(GlobalContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const actionRef = useRef<ActionType>();
    const formRef = useRef<ProFormInstance>();
    const abortController = useRef<AbortController | null>(null);
    const navigate = useNavigate();

    const [memberInfoVMs, setMemberInfoVMs] = useState<IMemberInfoViewModel[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedMember, setSelectedMember] = useState<IMemberInfoViewModel | undefined>(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState<string>(
        searchParams.get('tab') || 'memberList'
    );

    const initialQueryRequest: IGetMemberInfoViewModelByMerchantRequest = {
        memberId: searchParams.get('memberId') || '',
        userName: searchParams.get('userName') || '',
        email: searchParams.get('email') || '',
        phoneNumber: searchParams.get('phoneNumber') || '',
        page: Number(searchParams.get('page')) || 1,
        pageSize: Number(searchParams.get('pageSize')) || 10,
    };

    const [queryRequest, setQueryRequest] = useState<IGetMemberInfoViewModelByMerchantRequest>(initialQueryRequest);

    const { mutate, isLoading } = useMutation(
        async (request: IGetMemberInfoViewModelByMerchantRequest) =>
            await useGetMemberInfoViewModelByMerchantApi(request, abortController.current?.signal),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    const paginatedResult = response.result as IPaginationResponse<IMemberInfoViewModel[]>;
                    setMemberInfoVMs(paginatedResult.data);
                    setTotalCount(paginatedResult.totalCount);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    useEffect(() => {
        // 执行初始查询
        mutate(initialQueryRequest);
    }, []);

    const updateQueryStringWithoutSearch = (newParams: Partial<IGetMemberInfoViewModelByMerchantRequest>) => {
        const updatedParams = { ...queryRequest, ...newParams };
        const newSearchParams = new URLSearchParams();
        Object.entries(updatedParams).forEach(([key, value]) => {
            if (value !== undefined && value !== '') {
                newSearchParams.set(key, value.toString());
            }
        });
        setSearchParams(newSearchParams);
    };

    const handleSearch = () => {
        const formValues = form.getFieldsValue();
        const searchRequest: IGetMemberInfoViewModelByMerchantRequest = {
            ...formValues,
            page: 1,
            pageSize: formValues.pageSize || 10
        };
        setQueryRequest(searchRequest);
        updateQueryStringWithoutSearch(searchRequest);
        mutate(searchRequest);
    };

    const handleTableChange = (pagination: any) => {
        const newRequest = {
            ...queryRequest,
            page: pagination.current,
            pageSize: pagination.pageSize
        };
        setQueryRequest(newRequest);
        updateQueryStringWithoutSearch(newRequest);
        mutate(newRequest);
    };

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current?.abort();
        }
    }, []);

    // 處理 Tab 切換
    const handleTabChange = (newTab: string) => {
        setActiveTab(newTab);
        // 更新 URL 參數，保留其他已有的參數
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('tab', newTab);
        setSearchParams(newSearchParams);
    };

    // 初始化時從 URL 讀取參數
    useEffect(() => {
        const tabFromUrl = searchParams.get('tab');
        if (tabFromUrl && ['memberList', 'memberLevel'].includes(tabFromUrl)) {
            setActiveTab(tabFromUrl);
        }
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Tabs
                activeKey={activeTab}
                destroyInactiveTabPane={true}
                onChange={handleTabChange}
                items={[
                    {
                        key: 'memberList',
                        label: translate('Member List Management'),
                        children: <MemberListTab
                            memberInfoVMs={memberInfoVMs}
                            totalCount={totalCount}
                            queryRequest={queryRequest}
                            initialQueryRequest={initialQueryRequest}
                            isLoading={isLoading}
                            expandedRowKeys={expandedRowKeys}
                            actionRef={actionRef}
                            formRef={formRef}
                            handleSearch={handleSearch}
                            handleTableChange={handleTableChange}
                            updateQueryStringWithoutSearch={updateQueryStringWithoutSearch}
                            setExpandedRowKeys={setExpandedRowKeys}
                        />
                    },
                    {
                        key: 'memberConsumptionLevel',
                        label: translate('Member Consumption Level Management'),
                        children: <MemberConsumptionLevelTab />
                    }
                ]}
            />
        </div>
    );
};

export default MemberManagePage;