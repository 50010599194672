import { MD5 } from 'crypto-js';

export class SignHelper {
    async generateDynamicTimeSignAsync(text: string, dateTimeOffset: Date): Promise<string> {
        // 格式化日期為 yyyy/MM/dd HH:mm
        const year = dateTimeOffset.getUTCFullYear();
        const month = String(dateTimeOffset.getUTCMonth() + 1).padStart(2, '0');
        const day = String(dateTimeOffset.getUTCDate()).padStart(2, '0');
        const hours = String(dateTimeOffset.getUTCHours()).padStart(2, '0');
        const minutes = String(dateTimeOffset.getUTCMinutes()).padStart(2, '0');
        const dynamicTypeString = `${year}/${month}/${day} ${hours}:${minutes}`;
        var signText = text + dynamicTypeString;
        console.log(signText);
        return this.encryptByMD5(signText).toLocaleLowerCase();
    }

    encryptByMD5(input: string): string {
        return MD5(input).toString();
    }

    /**
    * 接收物件，按鍵名升序排序，生成 key=value&key=value 格式的字符串並加密
    * @param obj 需要排序並加密的物件
    * @returns 返回加密後的MD5字符串
    */
    generateSortedObjectText(obj: object): string {
        // 1. 先將物件的 key 排序
        const sortedKeys = Object.keys(obj).sort();

        // 2. 用 key=value&key=value 的格式生成字符串
        const sortedString = sortedKeys
            .map((key) => `${key.toLowerCase()}=${obj[key]}`)
            .join('&');

        // 3. 返回排序後的字符串
        return sortedString;
    }
}