import { ImageUseageType } from "@/enums/Enums";
import { useUploadImageApi } from '@/lib/api/images';
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { IItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Button, Flex, Form, GetProp, Input, Tag, Tooltip, Upload, UploadFile, UploadProps } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";

export interface BasicInformationProps {
    previewOnly?: boolean;
    onIsAllValid: (isAllValid: boolean) => void;
}

export interface IBasicInformationRef {
    validate: () => void;
}

const BasicInformation = React.forwardRef((props: BasicInformationProps, ref: React.ForwardedRef<IBasicInformationRef | undefined>) => {
    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
    const [form] = Form.useForm();
    const {
        itemViewModel,
        setItemViewModel,
        coverImages,
        setCoverImages,
    } = useContext(ItemContext);
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const imageCountLimit = 6;
    const { generateHelper, merchantId } = useContext(GlobalContext);
    const { messageApi } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const [tagInput, setTagInput] = useState<string>('');

    const getBase64 = (file: FileType): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const upLoadImageMutation = useMutation(async (request: FormData) => await useUploadImageApi(request));
    const customRequest = async ({ file, onSuccess, onError }: any) => {
        try {
            const files = Array.isArray(file) ? file : [file];
            const uploadPromises = files.map(async (singleFile) => {
                const formData = new FormData();
                formData.append('UseageType', ImageUseageType.ItemCover);
                formData.append('file', singleFile);
                return await upLoadImageMutation.mutateAsync(formData);
            });

            const responses = await Promise.all(uploadPromises);

            const successfulUploads = responses
                .filter(response => response.isSuccess && response.result)
                .map(response => response.result)
                .filter((url): url is string => url !== undefined && url !== null);

            if (successfulUploads.length > 0) {
                setCoverImages(prev => [...prev, ...successfulUploads]);
                // 創建符合 UploadFile 類型的文件列表項目
                const newFileList: UploadFile<any>[] = successfulUploads.map(url => ({
                    uid: url,
                    name: url.split('/').pop() || url,
                    status: 'done' as const, // 明確指定為 UploadFileStatus
                    url: url,
                    type: 'image/jpeg', // 添加文件類型
                    size: 0, // 添加文件大小
                }));
                setFileList(prev => [...prev, ...newFileList]);
                onSuccess();
            }
        } catch (error) {
            messageApi.error('Upload failed');
            onError(error);
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const handleTagDelete = (removedTag: string) => {
        setItemViewModel({
            ...itemViewModel,
            itemTags: itemViewModel.itemTags.filter(tag => tag.name !== removedTag),
        });
    };

    const handleTagAdd = () => {
        if (tagInput) {
            setTagInput('');
            if (itemViewModel.itemTags.find(tag => tag.name === tagInput)) {
                return;
            }
            setItemViewModel({
                ...itemViewModel,
                itemTags: [...itemViewModel.itemTags, {
                    merchantId: merchantId!,
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: tagInput,
                    isDeleted: false,
                }],
            });
        }
    };

    const handleUploadError = (error: Error) => {
        messageApi.error('Upload failed');
    };

    useEffect(() => {
        if (itemViewModel === undefined)
            return;
        // 初始化表單字段
        form.setFieldsValue({
            itemName: itemViewModel.name,
            itemTitle: itemViewModel.title,
            itemBriefly: itemViewModel.briefly,
        });
    }, [itemViewModel]);

    useEffect(() => {
        setFileList(coverImages.map(image => ({
            uid: image,
            name: image,
            status: 'done',
            url: image,
        })));
    }, [coverImages]);

    useImperativeHandle(ref, () => ({
        // Method to validate the form
        validate: async () => {
            try {
                const result = await form.validateFields();
                var isOk = result.errorFields === undefined;
                props.onIsAllValid(isOk);
                // If there are no error fields, return true
                props.onIsAllValid(true);
                return isOk;
            } catch (errorInfo: any) {
                // If there are error fields, return false
                props.onIsAllValid(errorInfo.errorFields.length === 0);
                return errorInfo.errorFields.length === 0;
            }
        },
    }));

    async function onValueChange(changeValues: any, value: any) {
        var final: IItemViewModel = {
            ...itemViewModel,
            id: itemViewModel.id || generateHelper.getSnowflakeIdBigInt(), // new Item will have a new id
            coverImages: [...itemViewModel!.coverImages],
            name: value.itemName,
            title: value.itemTitle,
            briefly: value.itemBriefly,
            itemTags: itemViewModel.itemTags || [],
            isDelete: false,
            description: itemViewModel.description || '',
            createdDate: itemViewModel.createdDate || '',
            alterDate: itemViewModel.alterDate || '',
            isOnShelf: itemViewModel.isOnShelf || false,
        };
        setItemViewModel(final);

        try {
            var result = await form.validateFields();
            //if has result.errorFields property then it is not ok
            var isOk = result.errorFields === undefined;
            props.onIsAllValid(isOk);
        } catch (errorInfo: any) {
            props.onIsAllValid(errorInfo.errorFields.length === 0);
        }
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(coverImages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setCoverImages(items);
        setFileList(items.map((image, index) => ({
            uid: `${image}-${index}`,
            name: image.split('/').pop() || image,
            status: 'done' as const,
            url: image,
        })));
    };

    const renderUploadArea = () => (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction="horizontal" >
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                    >
                        {fileList.map((file, index) => (
                            <Draggable
                                key={file.uid}
                                draggableId={file.uid}
                                index={index}
                                isDragDisabled={props.previewOnly}
                            >
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                            marginRight: '10px',
                                        }}
                                    >
                                        <Upload
                                            multiple
                                            listType="picture-card"
                                            fileList={[file]}
                                            customRequest={customRequest}
                                            disabled={props.previewOnly}
                                            onRemove={() => {
                                                setCoverImages(prev => prev.filter(item => item !== file.url));
                                                setFileList(prev => prev.filter(item => item.uid !== file.uid));
                                                return true;
                                            }}
                                        >
                                        </Upload>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        {!props.previewOnly && coverImages.length < imageCountLimit && (
                            <Upload
                                multiple
                                listType="picture-card"
                                customRequest={customRequest}
                                showUploadList={false}
                            >
                                {uploadButton}
                            </Upload>
                        )}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

    return (
        <>
            <Form style={{ width: '80%' }} form={form} onValuesChange={onValueChange}>
                <Flex justify="center">
                    <Title level={3}>
                        {translate('Basic Information')}
                    </Title>
                </Flex>
                <Flex style={{ margin: '10px' }}>
                    <Tooltip title={
                        i18nLanguage === 'zh_TW'
                            ? '圖片最佳比例為1:1，解析度800*800像素（或以上）'
                            : 'The default ratio is 1:1 and the resolution of 800*800px (or above) is optimal for uploaded images.'
                    }>
                        {renderUploadArea()}
                    </Tooltip>
                </Flex>
                {/* Add item tags input */}
                <Flex style={{ width: '100%', marginTop: 10, marginBottom: 10 }} justify={"center"} align={"middle"} wrap="wrap">
                    {itemViewModel.itemTags.map((tag, index) => (
                        <Tag
                            key={tag.name}
                            closable={!props.previewOnly}
                            onClose={() => {
                                handleTagDelete(tag.name)
                            }}>
                            {tag.name}
                        </Tag>
                    ))}
                </Flex>
                <Form.Item
                    hidden={props.previewOnly}
                    label={translate('Item tags')}>
                    <Input
                        style={{ width: 100 }}
                        placeholder={translate('Tags')}
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        onPressEnter={handleTagAdd}
                    />
                    <Button style={{ marginLeft: '10px' }} onClick={handleTagAdd} disabled={!tagInput} type="primary">{translate('Add')}</Button>
                </Form.Item>

                {/* ItemName */}
                <Form.Item
                    name="itemName"
                    rules={[{ required: true, message: translate('is required') }]}
                    label={translate('Item name')}>
                    <Input
                        disabled={props.previewOnly} />
                </Form.Item>
                {/* ItemTitle */}
                <Form.Item
                    name="itemTitle"
                    label={translate('Home page title')}>
                    <Input
                        disabled={props.previewOnly} />
                </Form.Item>
                {/* ItemBriefly 
                <Form.Item
                    name="itemBriefly"
                    rules={[{ required: true, message: translate('is required') }]}
                    label={translate('Item briefly')}>
                    <Input
                        disabled={props.previewOnly} />
                </Form.Item>
                */}
            </Form>
        </>
    )
});

export default BasicInformation;
