import { Button, Flex, Form, Input, Modal, Popconfirm, Select, message } from "antd"
import { useMutation, useQuery } from "react-query"
import {
    useGetMerchantCategoriesApi,
    useAddCategoryApi,
    useAlterCategoryApi,
    useDeleteCategoryApi
} from "@/lib/api/category"
import { GlobalContext } from "@/lib/contexts/GlobalContext"
import { TranslationContext } from "@/lib/contexts/TranslationContext"
import { ICategory } from "@/Templates/interfaces/templatesInterfaces"
import { useState, useRef, useContext, useEffect } from "react"
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table"
import { useNavigate } from 'react-router-dom'
import { useGetQueryOptionsByMerchantIdApi } from "@/Templates/lib/apis"

const CategoriesManage: React.FC = () => {
    const { translate } = useContext(TranslationContext)
    const { merchantId, messageApi } = useContext(GlobalContext)
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [editingCategory, setEditingCategory] = useState<ICategory | null>(null)
    const actionRef = useRef<ActionType>()
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const navigate = useNavigate();

    // 获取标签选项
    const {
        mutate: fetchTags,
        data: queryOptions,
        isLoading: tagsLoading
    } = useMutation(
        () => useGetQueryOptionsByMerchantIdApi(merchantId!),
        {
            onError: () => {
                messageApi.error(translate('Failed to load tags'));
            }
        }
    );

    // 添加和修改分類的mutation
    const { mutateAsync: addCategory, isLoading: isAdding } = useMutation(useAddCategoryApi);
    const { mutateAsync: alterCategory, isLoading: isEditing } = useMutation(useAlterCategoryApi);

    // 初始化数据
    useEffect(() => {
        if (merchantId) {
            fetchTags();
        }
    }, [merchantId]);

    // 处理操作成功
    const handleOperationSuccess = () => {
        handleRefresh();
        messageApi.success(translate('Operation success'));
    };

    // 处理删除
    const handleDelete = async (id: BigInt) => {
        try {
            await useDeleteCategoryApi({
                merchantId: merchantId!,
                categoryId: id
            });
            handleOperationSuccess();
        } catch (error) {
            messageApi.error(translate('Delete failed'));
        }
    };

    // 處理分類名稱點擊
    const handleCategoryClick = (category: ICategory) => {
        navigate(`/itemManage?tab=item-list&categoryIds=${category.id}`);
    };

    // 處理標籤點擊
    const handleTagClick = (tagId: string) => {
        navigate(`/itemManage?tab=item-list&extraItemTagIds=${tagId}`);
    };

    // 表格列配置
    const columns: ProColumns<ICategory>[] = [
        {
            title: translate('Name'),
            dataIndex: 'name',
            align: 'center',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCategoryClick(record);
                    }}>
                    {text}
                </Button>
            )
        },
        {
            title: translate('Action'),
            valueType: 'option',
            render: (_, record) => [
                <Flex className="w-full justify-center gap-2" gap={8}>
                    <a key="edit" onClick={() => handleEdit(record)}>{translate('Edit')}</a>
                    <Popconfirm
                        key="delete"
                        title={translate('Confirm delete?')}
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <a style={{ color: 'red' }}>{translate('Delete')}</a>
                    </Popconfirm>
                </Flex>
            ],
            align: 'center'
        }
    ]

    // 表单提交处理
    const handleSubmit = async (values: any) => {
        const request = {
            ...values,
            merchantId: merchantId!,
            itemTagIds: values.itemTagIds?.map((id: string) => BigInt(id)) || []
        }

        try {
            if (editingCategory) {
                await alterCategory({ ...request, categoryId: editingCategory.id })
            } else {
                await addCategory(request)
            }
            handleOperationSuccess()
            actionRef.current?.reload()
            setIsModalVisible(false)
        } catch (error) {
            messageApi.error(translate('Operation failed'))
        }
    }

    // 打开编辑模态框
    const handleEdit = (category: ICategory) => {
        setEditingCategory(category)
        form.setFieldsValue({
            ...category,
            itemTagIds: category.itemTagIds?.map(id => id.toString())
        })
        setIsModalVisible(true)
    }

    // 处理刷新
    const handleRefresh = () => {
        fetchTags();
    };

    return (
        <Flex vertical style={{ padding: 24 }}>
            <ProTable
                actionRef={actionRef}
                columns={columns}
                dataSource={queryOptions?.result?.categories}
                rowKey={(record) => record.id.toString()}
                expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpandedRowsChange: (expandedRows) => {
                        setExpandedRowKeys(expandedRows as string[]);
                    },
                    expandedRowRender: (record) => (
                        <Flex vertical gap={8} style={{ padding: '12px 24px' }}>
                            <div>
                                <strong>{translate('Tags')}:</strong>{' '}
                                {record.itemTagIds?.map(id => {
                                    const tag = queryOptions?.result?.itemTags?.find(t => t.id.toString() === id.toString());
                                    return tag ? (
                                        <Button
                                            key={id.toString()}
                                            type="link"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleTagClick(id.toString());
                                            }}
                                            style={{ paddingLeft: 0, paddingRight: 8 }}
                                        >
                                            {tag.name}
                                        </Button>
                                    ) : null;
                                })}
                            </div>
                        </Flex>
                    ),
                }}
                onRow={(record) => ({
                    onClick: () => {
                        if (!expandedRowKeys.includes(record.id.toString())) {
                            // 如果未展開，則展開該個項目
                            setExpandedRowKeys([...expandedRowKeys, record.id.toString()]);
                        }
                        else {
                            // 如果已經展開，則收起該個項目
                            setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.id.toString()));
                        }
                    },
                    style: { cursor: 'pointer' }
                })}
                toolBarRender={() => [
                    <Button
                        key="add"
                        type="primary"
                        onClick={() => {
                            setEditingCategory(null)
                            form.resetFields()
                            setIsModalVisible(true)
                        }}
                    >
                        {translate('Add Category')}
                    </Button>
                ]}
                loading={tagsLoading}
                search={false}
            />

            <Modal
                title={editingCategory ? translate('Edit Category') : translate('Add Category')}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={form.submit}
                confirmLoading={isAdding || isEditing}
                destroyOnClose
            >
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item
                        name="name"
                        label={translate('Name')}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="itemTagIds"
                        label={translate('Tags')}
                    >
                        <Select
                            mode="multiple"
                            options={queryOptions?.result?.itemTags?.map(tag => ({
                                label: tag.name,
                                value: tag.id.toString()
                            })) || []}
                            placeholder={translate('Select tags')}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Flex>
    )
}

export default CategoriesManage
