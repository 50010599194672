import { IMerchant } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Card, Col, Drawer, Flex, Row, Select, Tag, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ApplyNewMerchant from "./ApplyNewMerchant";
import MerchantInfomationPopover from "./MerchantInfomationPopover";
import MerchantUpgradeOrder, { IMerchantUpgradeOrderRef } from "./MerchantUpgradeOrder";
import MerchantUpgradeOrderInvoice from "./MerchantUpgradeOrderInvoice";

export interface IMerchantInfosProp {
    merchants: IMerchant[] | undefined;
}

const MerchantInfos: React.FC<IMerchantInfosProp> = ({ }) => {
    const { Title } = Typography;
    const {
        merchantId,
        setIsPageLoading,
        accessibleMerchants,
        setMerchantIdAggregate,
        isSetMerchantIdAggregateLoading,
        messageApi,
        portalLink,
        tryGetPortalLinkLoading,
        switchBackOfficeMerchant
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const merchantUpgradeOrderRef = useRef<IMerchantUpgradeOrderRef>();
    const [visibleForUpgrade, setVisibleForUpgrade] = useState(false);
    const safeMerchants = accessibleMerchants || [];
    const selectedMerchant = safeMerchants.find(x => x.id.toString() === merchantId?.toString());
    const handleChange = (value: string) => {
        setMerchantIdAggregate(BigInt(value));
    };
    const renderExpireTime = (expireTime: string) => {
        const expireDate = new Date(expireTime);
        const isExpired = expireDate < new Date();

        // 格式化日期時間
        const formattedDate = expireDate.toLocaleString('zh-CN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).replace(/\//g, '-');

        // 計算剩餘天數
        const today = new Date();
        const diffTime = expireDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return (
            <span>
                {isExpired ? (
                    <>
                        <span style={{ color: 'red' }}>{formattedDate}</span>
                        <Tag color="red">{translate('Expired')}</Tag>
                    </>
                ) : (
                    <span>
                        {formattedDate}
                        <Tag color="blue" style={{ marginLeft: '8px' }}>
                            {translate('Remaining')}: {diffDays} {translate('Days')}
                        </Tag>
                    </span>
                )}
            </span>
        );
    };
    useEffect(() => {
        if (merchantId === undefined && safeMerchants.length > 0) {
            setMerchantIdAggregate(safeMerchants[0].id);
            messageApi.warning(translate('auto switch to first own merchant'));
        }
    }, [merchantId, safeMerchants])
    return (
        <>
            <Row style={{ margin: '20px' }}>
                {safeMerchants && (
                    <Col span={24}>
                        <Flex justify="center" align="center">
                            <Title level={3}>{translate('Merchants')}</Title>
                        </Flex>
                        <Flex justify="center" align="center">
                            <MerchantInfomationPopover />
                            {safeMerchants.length > 0 && (
                                <Select
                                    style={{ width: 200 }}
                                    value={merchantId ? merchantId.toString() : null}
                                    onChange={handleChange}
                                    placeholder={translate('Select') + ' ' + translate('Merchant')}
                                >
                                    {safeMerchants.map(merchant => (
                                        <Select.Option key={merchant.id.toString()} value={merchant.id.toString()}>
                                            {merchant.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                            <ApplyNewMerchant accessibleMerchants={safeMerchants} />
                        </Flex>
                    </Col>
                )}
                <Col style={{ marginTop: '30px' }} span={24}>
                    <Flex justify="center">
                        {selectedMerchant && (
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Card loading={isSetMerchantIdAggregateLoading}>
                                    <p>{translate('Merchant ID')}: {selectedMerchant.id.toString()}</p>
                                    <p>
                                        {portalLink && (
                                            <a href={`${portalLink}/`} target="_blank" rel="noopener noreferrer">
                                                {translate('Visit portal website')}
                                            </a>
                                        )}
                                    </p>
                                    <p>
                                        {translate('Country')}: {translate(`Country.${selectedMerchant.country}`)}
                                    </p>
                                    <p>
                                        {translate('Website Plan')}: {translate(`MerchantWebsiteUpgradeLevel.${selectedMerchant.merchantWebsiteUpgradeLevel}`)}
                                    </p>
                                    <p>
                                        {translate('Expire Time')}: {renderExpireTime(selectedMerchant.upgradeExpiredTime)}
                                    </p>
                                    <p>{translate('Banned')}: {selectedMerchant.isBanned ? translate('Yes') : translate('No')}</p>
                                    <Flex justify="center">
                                        <Button
                                            onClick={() => setVisibleForUpgrade(true)}
                                            disabled={selectedMerchant === undefined}>
                                            {translate('Subscribe')}
                                        </Button>
                                    </Flex>
                                    <Drawer
                                        destroyOnClose={true}
                                        title={
                                            <Flex justify="space-between" align="center">
                                                <Title level={5} style={{ margin: 0 }}>{translate('Subscribe')}</Title>
                                                <MerchantUpgradeOrderInvoice />
                                            </Flex>
                                        }
                                        placement="right"
                                        width={500}
                                        open={visibleForUpgrade}
                                        onClose={() => {
                                            setVisibleForUpgrade(false);
                                        }}
                                    >
                                        <MerchantUpgradeOrder
                                            merchant={selectedMerchant ? safeMerchants.find(m => m.id.toString() === selectedMerchant.id.toString()) : undefined}
                                            ref={merchantUpgradeOrderRef}
                                        />
                                    </Drawer>
                                </Card>
                            </Col>
                        )}
                    </Flex>
                </Col>
            </Row>
        </>
    );
}

export default MerchantInfos;
