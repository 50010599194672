export enum ThirdPartyInvoiceQueryStatusResponseType {
    /**
     * Update the status of the order and front end will refresh the page
     */
    Normal = 'Normal',

    /**
     * Redirect to the third party logistic website with Data
     */
    Redirect = 'Redirect',
}


export enum InvoviceOrderType {
    /** 一般 */
    Normal = 'Normal',

    /** 折讓 （退貨） */
    Allowance = 'Allowance',
}

export enum InvoiceOrderStatus {
    /** 處理中 */
    Pending = 'Pending',

    /** 創立中 */
    Creating = 'Creating',

    /** 已建立 */
    Created = 'Created',

    /** 取消中 */
    Cancelling = 'Cancelling',

    /** 已取消 */
    Cancel = 'Cancel',

    /** 重新建立中 */
    Reissuing = 'Reissuing',

    /** 已重新建立 */
    Reissued = 'Reissued',

    /** 作廢中 */
    Invaliding = 'Invaliding',

    /** 作廢 */
    Invalid = 'Invalid',

    /** 未找到 */
    NotFound = 'NotFound',

    /** 未知錯誤 */
    UnknowError = 'UnknowError',
}
