import logo from '@/assets/Domigo-Logo-removebg-preview.png';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType } from '@/Templates/enums/templateEnums';
import { ConfigProvider, Flex, Spin } from 'antd';
import { Locale } from 'antd/lib/locale';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import zhTW from 'antd/locale/zh_TW';
import React, { useCallback, useContext, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../lib/contexts/GlobalContext';
import AppFooter from './Components/AppFooter';
import MyRoutes from './Components/MyRoutes';
import NavBar from './Components/NavBar';
if (process.env.NODE_ENV === 'development') {
  //if development directly import fonts.css
  import('@/Templates/assets/fonts/fonts.css');
}

const App: React.FC = () => {
  const { messageApi, setNavBarHeight, isLogin, isPageLoading, setDeviceType } = useContext(GlobalContext);
  const { i18nLanguage } = useContext(TranslationContext);
  const [locale, setLocale] = React.useState<Locale>(enUS);

  // 偵測裝置類型
  const detectDeviceType = useCallback(() => {
    const viewportWidth = window.visualViewport?.width || window.innerWidth;
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);

    if (viewportWidth <= 768 || isMobile || isIOS) {
      setDeviceType(DeviceType.Mobile);
    } else if (viewportWidth <= 1024 || isTablet || isAndroid) {
      setDeviceType(DeviceType.LapTop);
    } else {
      setDeviceType(DeviceType.Desktop);
    }
  }, [setDeviceType]);

  useEffect(() => {
    // 初始化裝置類型偵測
    detectDeviceType();

    // 監聽視窗大小和方向變化
    const handleResize = () => detectDeviceType();
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // 清理事件監聽
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [detectDeviceType]);

  useEffect(() => {
    switch (i18nLanguage) {
      case 'zh_TW':
        setLocale(zhTW);
        break;
      case 'zh_CN':
        setLocale(zhCN);
        break;
      default:
        setLocale(enUS);
        break;
    }
  }, [i18nLanguage]);

  const importHelmet = () => {
    if (process.env.NODE_ENV === 'development') {
      return <Helmet>
        <meta charSet="UTF-8" />
        <link rel="icon" type="image/*" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>MicroShop BackOffice</title>
      </Helmet>
    }
    else {
      //if production load fonts.css from cdn and nginx
      return <Helmet>
        <meta charSet="UTF-8" />
        <link rel="icon" type="image/*" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>MicroShop BackOffice</title>

        {/* 预加载字体文件 */}
        <link rel="preload" href="https://cdn.jsdelivr.net/gh/max32002/bakudaifont@1.48/webfont/Bakudai-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="https://cdn.jsdelivr.net/gh/max32002/masafont@2.1/webfont/MasaFont-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/assets/fonts/SourceHanSerifTC-VF.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" href="/assets/fonts/SourceHanSerifSC-VF.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />

        {/* 加载字体CSS */}
        <link rel="stylesheet" href="/assets/fonts/fonts.css" />
      </Helmet>
    }
  };
  return (
    <>
      {importHelmet()}
      <ConfigProvider locale={locale}>
        {isLogin && <NavBar />}
        {/* Page Loading Cover */}
        <Flex id="pageLoadingCover" hidden={!isPageLoading} justify="center" align="center" style={{ zIndex: 9999, position: "fixed", height: '100vh', width: '100vw', backgroundColor: 'whitesmoke', opacity: 0.5 }}>
          <Spin >
            <div id="loading"></div>
          </Spin>
        </Flex>
        <div style={{ minHeight: '90vh', margin: '0 auto' }}>
          <MyRoutes />
        </div>
        <AppFooter></AppFooter>
      </ConfigProvider>
    </>
  );
};

export default App;