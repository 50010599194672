import { IAlterMerchantThirdPartyPaymentSettingRequest } from '@/interfaces/Requests/Requests';
import { IBackOfficeMerchantThirdPartyPaymentSettingViewModel } from '@/interfaces/Responses/Responses';
import { IThirdPartyPaymentSetting } from '@/interfaces/Responses/ThirdPartyPayment';
import { useAlterMerchantThirdPartyPaymentSettingApi, useGetThirdPartyPaymentSettingApi } from '@/lib/api/thirdPartyPayments';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { Button, Col, Form, Input, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation } from 'react-query';

export interface IEditPaymentSettingProps {
    record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel;
}

export interface IEditPaymentSettingRef {

}

const EditPayment = React.forwardRef((props: IEditPaymentSettingProps, ref: React.ForwardedRef<IEditPaymentSettingRef | undefined>) => {
    const [form] = Form.useForm();
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [tpps, setTpps] = useState<IThirdPartyPaymentSetting | undefined>(undefined);
    const [formValues, setFormValues] = useState<Record<string, string>>({});

    const { mutate: fetchSettings } = useMutation(async (settingId: BigInt) => await useGetThirdPartyPaymentSettingApi(settingId), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setTpps(response.result);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: alterSettings, isLoading } = useMutation(async (request: IAlterMerchantThirdPartyPaymentSettingRequest) => await useAlterMerchantThirdPartyPaymentSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    useEffect(() => {
        fetchSettings(props.record.thirdPartySettingId);
    }, [props.record]);

    useEffect(() => {
        if (tpps && props.record && props.record.merchantSettings) {
            const initialValues = tpps.merchantFillFieldsForBasicInfo.reduce((acc: Record<string, string>, field) => {
                acc[field] = props.record.merchantSettings?.[field] || '';
                return acc;
            }, {});
            setFormValues(initialValues);
            form.setFieldsValue(initialValues);
        }
    }, [tpps, props.record, form]);

    const handleInputChange = (field: string, value: string) => {
        setFormValues(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = () => {
        if (!merchantId || !props.record) return;

        const request: IAlterMerchantThirdPartyPaymentSettingRequest = {
            settingId: props.record.thirdPartySettingId,
            setting: formValues,
            isDisabled: props.record.isDisable,
        };
        alterSettings(request);
    };

    if (!tpps || !props.record) return <LoadingComponent></LoadingComponent>;

    return (
        <div>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {tpps.merchantFillFieldsForBasicInfo.map((key) => (
                    <Form.Item
                        key={key}
                        name={key}
                        label={translate(key)}
                    >
                        <Row justify="space-between">
                            <Col span={20}>
                                <Input
                                    value={formValues[key] || ''}
                                    onChange={(e) => handleInputChange(key, e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form.Item>
                ))}
                <Row justify="center">
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {translate('Save')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
});

export default EditPayment;