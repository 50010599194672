import { InvoiceOrderStatus } from "@/enums/ThirdPartyInvoice";
import { IQueryThirdPartyOrderStatusRequest } from "@/interfaces/Requests/Requests";
import { IInvalidateThirdPartyOrderRequest } from "@/interfaces/Requests/ThirdPartyInvoice";
import { IThirdPartyInvoiceOrderViewModel } from "@/interfaces/Responses/ThirdPartyInvoice";
import { useInvalidateThirdPartyOrderApi, useQueryInvoiceOrderStatusApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { ISO3166_1Alpha2 } from "@/Templates/enums/templateEnums";
import { UrlHelper } from "@/Templates/lib/UrlHelper";
import { Button, Empty, Flex, Tag, Typography } from "antd";
import { useContext } from "react";
import { useMutation } from "react-query";
import TaiwanInvoiceField from "./TaiwanInvoiceField";

const { Text } = Typography;

export interface IOrderDetailProps {
    thirdPartyOrder: IThirdPartyInvoiceOrderViewModel | null;
    onRefresh: () => void;
}

const OrderDetail = (props: IOrderDetailProps) => {
    const { merchantPortalOptionSettingMutation, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const urlHelper = new UrlHelper();

    // Query invoice order status
    const { mutate: queryInvoiceOrderStatus, isLoading: isQueryingStatus } = useMutation(
        async (request: IQueryThirdPartyOrderStatusRequest) => await useQueryInvoiceOrderStatusApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result?.status) {
                props.thirdPartyOrder!.status = response.result?.status;
            }
        }
    });

    const invalidateThirdPartyOrderMutation = useMutation(async (reqeust: IInvalidateThirdPartyOrderRequest) => await useInvalidateThirdPartyOrderApi(reqeust), {
        onSuccess: (response, request) => {
            if (response.isSuccess) {
                messageApi.success(translate("Operation success"));
            }
            else
                messageApi.error(translate(response.message || 'Operation failed'));
        },
        onError: (error: any) => {
            messageApi.error(translate(error.message || 'Operation failed'));
        },
        onSettled: (response, error, request) => {
            queryInvoiceOrderStatus(request);
        }
    });

    if (!props.thirdPartyOrder) {
        return <Empty />;
    }

    const getInvoiceOrderStatusColor = (status: string) => {
        switch (status) {
            case 'Created':
            case 'DuringShipping':
            case 'ArrivalWaitForPickup':
                return 'blue';
            case 'Done':
                return 'green';
            default:
                return 'red';
        }
    };

    const renderField = (label: string, value: React.ReactNode) => (
        <Flex align="center" gap="middle">
            <Text type="secondary">
                {label}:
            </Text>
            <Text>{value}</Text>
        </Flex >
    );

    const renderActions = () => {
        if (!props.thirdPartyOrder) return null;

        return (
            <Flex justify="center" gap="large" style={{ marginTop: 16 }}>
                {
                    <Button
                        type="primary"
                        loading={isQueryingStatus}
                        onClick={() => queryInvoiceOrderStatus({ thirdPartyOrderId: props.thirdPartyOrder!.id })}
                    >
                        {`${translate('Query')} ${translate('Status')}`}
                    </Button>
                }

                {props.thirdPartyOrder.isSuccessCreateFromProvider &&
                    props.thirdPartyOrder.status === InvoiceOrderStatus.Created && (
                        <Button
                            danger
                            loading={invalidateThirdPartyOrderMutation.isLoading}
                            onClick={() => invalidateThirdPartyOrderMutation.mutate({
                                thirdPartyOrderId: props.thirdPartyOrder!.id,
                                thirdPartySettingId: props.thirdPartyOrder!.thirdPartySettingId
                            })}
                        >
                            {translate('Invoice.Invalidate')}
                        </Button>
                    )}
            </Flex>
        );
    };

    return (
        <Flex vertical gap="small" style={{ width: '100%', padding: '24px' }}>
            {renderField(`${translate('ThirdParty Order ID')}:`, props.thirdPartyOrder.id.toString())}

            {renderField(translate('Provider Name'), props.thirdPartyOrder.providerName)}

            {props.thirdPartyOrder.providerOrderId &&
                renderField(translate('Invoice.ProviderOrderId'), props.thirdPartyOrder.providerOrderId)}

            {props.thirdPartyOrder.values['InvoiceType'] &&
                renderField(translate('Invoice.InvoiceType'),
                    translate(`${props.thirdPartyOrder.values['InvoiceType']}`))
            }

            {renderField(translate('Type'), translate(`${props.thirdPartyOrder.type}`))}

            {renderField(translate('Is Success Create From Provider'),
                <Text style={{ color: props.thirdPartyOrder.isSuccessCreateFromProvider ? 'inherit' : 'red' }}>
                    {translate(`${props.thirdPartyOrder.isSuccessCreateFromProvider}`)}
                </Text>
            )}

            {renderField(translate('Status'),
                <Tag color={getInvoiceOrderStatusColor(props.thirdPartyOrder.status)}>
                    {translate(`Invoice.${props.thirdPartyOrder.status}`)}
                </Tag>
            )}

            {props.thirdPartyOrder.status === InvoiceOrderStatus.Created &&
                props.thirdPartyOrder.values['InvoiceType'] === 'Donate' && (
                    <Flex justify="center" style={{ width: '100%' }}>
                        <Tag color="green">{translate('Donated')}</Tag>
                    </Flex>
                )}

            {renderField(translate('CreatedTime'),
                new Date(props.thirdPartyOrder.createdDate).toLocaleString()
            )}

            {renderField(translate('UpdatedTime'),
                new Date(props.thirdPartyOrder.updatedDate).toLocaleString()
            )}

            {merchantPortalOptionSettingMutation.data?.result?.country === ISO3166_1Alpha2.TW && (
                <TaiwanInvoiceField thirdPartyOrder={props.thirdPartyOrder} />
            )}

            {renderActions()}
        </Flex>
    );
};

export default OrderDetail;
