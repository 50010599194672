import { IAlterItemIsOnShelfRequest, IDeleteItemRequest } from '@/interfaces/Requests/Requests';
import { useAlterItemIsOnShelfApi, useDeleteItemApi, useGetItemsByMerchantApi } from '@/lib/api/items';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { IItemViewModel } from '@/Templates/interfaces/templatesInterfaces';
import type { ProColumns, ProFormInstance } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Button, Empty, Flex, Image, Modal, Select, Space, Switch, Tooltip, InputNumber, Radio, Tabs } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { IBackOfficeItemViewModel } from '@/interfaces/Responses/Responses';
import { IAlterItemSpecStockAmountRequest } from '@/interfaces/Requests/Requests';
import { useAddItemSpecStockApi, useReduceItemSpecStockApi, useSetItemSpecStockApi } from '@/lib/api/items';
import { EditOutlined } from '@ant-design/icons';
import ItemList from './components/ItemList';
import CategoriesManage from './components/categoriesManage';

const TAB_KEYS = {
    ITEM_LIST: 'item-list',
    CATEGORIES_MANAGE: 'categories-manage',
    UNDER_DEVELOPMENT: 'under-development',
}

const ItemManagePage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { translate } = useContext(TranslationContext);
    const [activeKey, setActiveKey] = useState(TAB_KEYS.ITEM_LIST);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = params.get('tab') || TAB_KEYS.ITEM_LIST;
        setActiveKey(tab);
    }, [location.search]);

    const handleTabChange = (key: string) => {
        navigate(`?tab=${key}`);
        setActiveKey(key);
    };

    return (
        <>
            <Tabs
                destroyInactiveTabPane
                activeKey={activeKey}
                onChange={handleTabChange}
                centered
                tabBarStyle={{ justifyContent: 'center' }}
                items={[
                    {
                        key: TAB_KEYS.ITEM_LIST,
                        label: translate('Item List'),
                        children: <ItemList />,
                    },
                    {
                        key: TAB_KEYS.CATEGORIES_MANAGE,
                        label: translate('Categories Manage'),
                        children: <CategoriesManage />,
                    },
                ]}
            />
        </>
    )
}

export default ItemManagePage;