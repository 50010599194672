import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { IItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { useContext, useEffect, useState } from "react";
import ItemDetail from "./ItemDetail";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
export interface IPreviewItemProps {
}
const PreviewItem: React.FC<IPreviewItemProps> = () => {
    const {
        isLogin,
        generateHelper,
        deviceType,
        merchantId,
        messageApi,
        notificationApi,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
    } = useContext(GlobalContext);
    const {
        translate,
        i18nLanguage
    } = useContext(TranslationContext);
    const {
        itemViewModel,
        itemSpecs,
        coverImages } = useContext(ItemContext);
    const navigate = useNavigate();
    const [item, setItem] = useState<IItemViewModel>({
        coverImages: [],
        itemTags: [],
        id: BigInt(0),
        name: '',
        title: '',
        briefly: '',
        description: '',
        createdDate: '',
        alterDate: '',
        isOnShelf: false,
        isDelete: false,
        minPrice: 0,
        maxPrice: 0,
        isSoldOut: false
    });
    useEffect(() => {
        setItem({
            ...item,
            name: itemViewModel.name,
            title: itemViewModel.title,
            briefly: itemViewModel.briefly,
            description: itemViewModel.description,
            coverImages: coverImages,
            itemTags: itemViewModel.itemTags,
        });
    }, []);

    return (
        <div>
            <ItemDetail
                isLogin={isLogin}
                tryGetSessionId={() => {
                    return generateHelper.getSnowflakeIdBigInt();
                }}
                item={item}
                itemSpecs={itemSpecs}
                navBarHeight={0}
                navBarGap={0}
                deviceType={deviceType}
                BASE_PATH={""}
                merchantId={merchantId!}
                i18nLanguage={i18nLanguage}
                translate={translate}
                merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
                merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
                messageApi={messageApi}
                notificationApi={notificationApi}
                navigate={navigate}
            />
        </div>
    );
};

export default PreviewItem;
