import { OrderStatus } from '@/enums/Enums';
import { LogisticOrderType } from '@/enums/ThirdPartyLogistic';
import { IBatchOrderCancelRequest, IBatchOrderRequest } from '@/interfaces/Requests/Requests';
import { IOrder } from '@/interfaces/Responses/Responses';
import { useBatchOrderCancelApi, useBatchOrderConfirmApi } from '@/lib/api/orders';
import { useBatchCreateThirdPartyLogisticOrderApi, useBatchQueryThirdPartyOrderStatusApi } from '@/lib/api/thirdPartyLogistics';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { UrlHelper } from '@/Templates/lib/UrlHelper';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, Dropdown, MenuProps, Modal, Row, Space, Typography } from 'antd';
import JSONBig from 'json-bigint';
import React, { useCallback, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface BatchOperationProps {
    disabled: boolean;
    selectedRows: IOrder[];
    onSuccess: () => void;
}
/**
 * 批量操作組件
 */
const BatchOperation: React.FC<BatchOperationProps> = ({
    disabled,
    selectedRows,
    onSuccess
}) => {
    const { translate } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const { confirm } = Modal;
    const urlHelper = new UrlHelper();
    const pickListRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({ contentRef: pickListRef });

    // 檢查是否可以創建物流訂單
    const canCreateLogisticOrder = selectedRows.every(
        order => order.status === OrderStatus.WaitForShipment
    );

    // 檢查是否可以查詢物流狀態
    const canQueryLogisticStatus = selectedRows.some(order => [
        OrderStatus.DuringShipment,
        OrderStatus.ShipmentDispute,
        OrderStatus.WaitForBuyerPickup,
        OrderStatus.BuyerNotPickup,
        OrderStatus.ReturnToLogisticsCenter,
        OrderStatus.BuyerApplyRefund,
        OrderStatus.WaitForMerchantConfirmRefundApplication,
        OrderStatus.WaitForReturnShipment,
        OrderStatus.DuringReturnShipment,
        OrderStatus.ReturnShipmentDispute,
        OrderStatus.WaitForMerchantPickupRefund,
        OrderStatus.MerchantNotPickup,
        OrderStatus.WaitForMerchantConfirmRefundItems,
        OrderStatus.WaitForMerchantRefundAmount,
        OrderStatus.RefundDone,
        OrderStatus.RefundUnderDispute
    ].includes(order.status));

    /**
     * 處理批量確認訂單
     */
    const handleBatchConfirm = useCallback(async () => {
        confirm({
            title: translate('Please confirm again'),
            icon: <ExclamationCircleTwoTone twoToneColor="red" />,
            content: translate('Are you sure to confirm these orders?'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                const request: IBatchOrderRequest = {
                    orderIds: selectedRows.map(row => row.id)
                };

                try {
                    const response = await useBatchOrderConfirmApi(request);
                    if (response.isSuccess) {
                        messageApi.success(translate('Orders confirmed successfully'));
                        onSuccess();
                    } else {
                        messageApi.error(translate(response.message || 'Failed to confirm orders'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while confirming orders'));
                }
            }
        });
    }, [selectedRows, translate, messageApi, onSuccess]);

    /**
     * 處理批量取消訂單
     */
    const handleBatchCancel = useCallback(async () => {
        confirm({
            title: translate('Please confirm again'),
            icon: <ExclamationCircleTwoTone twoToneColor="red" />,
            content: translate('Are you sure to cancel these orders?'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                const request: IBatchOrderCancelRequest = {
                    orderIds: selectedRows.map(row => row.id),
                    reason: translate('Batch Cancel')
                };

                try {
                    const response = await useBatchOrderCancelApi(request);
                    if (response.isSuccess) {
                        messageApi.success(translate('Orders cancelled successfully'));
                        onSuccess();
                    } else {
                        messageApi.error(translate(response.message || 'Failed to cancel orders'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while cancelling orders'));
                }
            }
        });
    }, [selectedRows, translate, messageApi, onSuccess]);

    /**
     * 處理批量創建物流訂單
     */
    const handleBatchCreateLogisticOrder = useCallback(async () => {
        confirm({
            title: translate('Please confirm again'),
            icon: <ExclamationCircleTwoTone twoToneColor="blue" />,
            content: translate('Are you sure to create logistic orders?'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                try {
                    const response = await useBatchCreateThirdPartyLogisticOrderApi({
                        requests: selectedRows.map(row => {
                            return {
                                orderId: row.id,
                                logisticOrderType: LogisticOrderType.Forward,
                                frontEndValues: {

                                },
                                settingId: BigInt(0)
                            }
                        })
                    });

                    if (response.isSuccess) {
                        messageApi.success(translate('Logistic orders created successfully'));
                        onSuccess();

                        // 處理每個物流訂單的回應
                        response.result?.forEach(result => {
                            const data = JSONBig.parse(result.data || '{}');
                            if (result.url) {
                                urlHelper.pageFormPost(result.url, data);
                            }
                        });
                    } else {
                        messageApi.error(translate(response.message || 'Failed to create logistic orders'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while creating logistic orders'));
                }
            }
        });
    }, [selectedRows, translate, messageApi, onSuccess]);

    /**
     * 處理批量查詢物流狀態
     */
    const handleBatchQueryLogisticStatus = useCallback(async () => {
        try {
            const response = await useBatchQueryThirdPartyOrderStatusApi({
                thirdPartyOrderIds: selectedRows.map(row => row.id)
            });

            if (response.isSuccess) {
                messageApi.success(translate('Query successful'));
                onSuccess();

                // 處理每個查詢結果
                response.result?.forEach(result => {
                    const data = JSONBig.parse(result.data || '{}');
                    if (result.url) {
                        urlHelper.pageFormPost(result.url, data);
                    }
                });
            } else {
                messageApi.error(translate(response.message || 'Failed to query logistic status'));
            }
        } catch (error) {
            messageApi.error(translate('An error occurred while querying logistic status'));
        }
    }, [selectedRows, translate, messageApi, onSuccess]);

    // 批量操作菜單
    const menu: MenuProps = {
        items: [
            {
                key: 'print',
                label: translate('Batch Print Picking List'),
                icon: <PrinterOutlined />,
                onClick: () => handlePrint()
            },
            {
                key: 'confirm',
                label: translate('Batch Confirm'),
                disabled: !canCreateLogisticOrder,
                onClick: handleBatchConfirm
            },
            {
                key: 'cancel',
                label: translate('Batch Cancel'),
                danger: true,
                onClick: handleBatchCancel
            }
        ]
    };

    return (
        <>
            <Dropdown menu={menu} disabled={disabled}>
                <Button type="primary">
                    <Space>
                        {translate('Batch Operations')}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>

            {/* 隱藏的批次撿貨單打印內容 */}
            <div style={{ display: 'none' }}>
                <div ref={pickListRef}>
                    {selectedRows.map((order, index) => (
                        <React.Fragment key={order.id.toString()}>
                            {index > 0 && <div style={{ pageBreakBefore: 'always' }} />}
                            <BatchPickList
                                order={order}
                                translate={translate}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </>
    );
};

// 批次撿貨單組件
const BatchPickList: React.FC<{
    order: IOrder,
    translate: (key: string) => string
}> = ({ order, translate }) => {
    const currentDate = dayjs().format('YYYY/MM/DD HH:mm');

    return (
        <div style={{ padding: '20px', backgroundColor: 'white' }}>
            {/* 標題區域 */}
            <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
                <Col>
                    <h2 style={{ margin: 0 }}>{translate('Picking list')}</h2>
                </Col>
                <Col>
                    <Typography.Text>{translate('Order ID')}: {order.id.toString()}</Typography.Text>
                </Col>
                <Col>
                    <Typography.Text>{translate('Date')}: {currentDate}</Typography.Text>
                </Col>
            </Row>
            <Divider />

            {/* 表格標題 */}
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                    <tr style={{ borderBottom: '2px solid #000' }}>
                        <th style={{ padding: '8px', textAlign: 'left', width: '15%' }}>{translate('Item Name')}</th>
                        <th style={{ padding: '8px', textAlign: 'left', width: '10%' }}>{translate('Spec Name')}</th>
                        <th style={{ padding: '8px', textAlign: 'left', width: '15%' }}>{translate('Buy Amount')}</th>
                        <th style={{ padding: '8px', textAlign: 'left', width: '10%' }}>{translate('Picked')}</th>
                    </tr>
                </thead>
                <tbody>
                    {order.items?.map((item, index) => (
                        <tr key={index} style={{ borderBottom: '1px dotted #ccc' }}>
                            <td style={{ padding: '8px' }}>
                                {item.itemName || '-'}
                            </td>
                            <td style={{ padding: '8px' }}>
                                {item.itemSpecName}
                            </td>
                            <td style={{ padding: '8px' }}>
                                {item.buyAmount}
                            </td>
                            <td style={{ padding: '8px' }}>
                                <div style={{
                                    width: '20px',
                                    height: '20px',
                                    border: '1px solid #000',
                                    display: 'inline-block'
                                }}></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Divider />

            {/* 訂單備註 */}
            {order.note && (
                <div>
                    <h3>{translate('Notes For the Buyer')}</h3>
                    <p>{order.note}</p>
                    <Divider />
                </div>
            )}

            {/* 訂單概況 */}
            <Row style={{ marginBottom: '20px' }}>
                <Col span={24}>
                    <Typography.Text>{translate('Total Specification Count')}: {order.items?.length}</Typography.Text>
                    <Typography.Text style={{ marginLeft: '20px' }}>
                        {translate('Total Item Count')}: {order.items?.reduce((acc, item) => acc + item.buyAmount, 0)}
                    </Typography.Text>
                </Col>
            </Row>
        </div>
    );
};

export default BatchOperation; 