import JSONBig from 'json-bigint';
import { MerchantPortalStyleShopComponents } from "../enums/templateEnums";

export const GlobalComponents = [
    MerchantPortalStyleShopComponents.NavBar,
    MerchantPortalStyleShopComponents.FullPageLoading,
    MerchantPortalStyleShopComponents.Footer,
];

// 定義可用的元件類型
export const AVAILABLE_DYNAMIC_COMPONENTS = (translate: (key: string) => string) => [
    { label: translate('HtmlContentField'), value: 'HtmlContentField', icon: '🎯' },
    { label: translate('Marquee'), value: 'Marquee', icon: '📜' },
    { label: translate('ContactUsPage'), value: 'ContactUs', icon: '📞' },
    { label: translate('PrivacyPolicyPage'), value: 'PrivacyPolicy', icon: '🔒' },
    { label: translate('ItemList'), value: 'ItemList', icon: '🛍️' },
    { label: translate('LandingPage'), value: 'LandingPage', icon: '🏙️' },
    { label: translate('TermsOfServicePage'), value: 'TermsOfService', icon: '' },
    { label: translate('RegisterPage'), value: 'RegisterPage', icon: '👤' },
    { label: translate('Banner'), value: 'Banner', icon: '🏙️' }
];

export const UploadMediaWithLinkDefaultValue = JSONBig.stringify([{ mediaUrl: 'https://cdn.domigo-digital.com/normal-images/Domigo-Banner.jpg', link: '', useLink: false }]);
