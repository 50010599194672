import { DeviceType } from '@/Templates/enums/templateEnums';
import styled from 'styled-components';

interface PreviewContainerProps {
  containerwidth: number;
  previewwidth: string;
  previewdevicetype: DeviceType;
  windowdevicetype: DeviceType;
}

const PreviewContainer = styled.div<PreviewContainerProps>`
  border: 1px solid #ccc;
  overflow-y: auto;
  overflow-x: hidden; 
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: ${props => `${props.previewwidth}`};
  max-width: ${props => props.windowdevicetype === DeviceType.Desktop ? '100%' : '90%'};
  margin: 0 auto;
  position: relative;
  height: 75vh;
  max-height: 80vh;

  & > div {
    width: ${props => props.previewwidth};
    height: 100%;
    transform-origin: top center;
    transition: transform 0.3s ease;
    position: absolute;
    top: 0;
    left: 50%;
  }

  ${props => {
    const previewWidth = props.previewwidth;
    const previewWidthNumber = parseInt(previewWidth.replace('px', ''));
    // 計算縮放比例
    const scale = Math.min(props.containerwidth / previewWidthNumber, 1);
    return `
      & > div {
        transform: translateX(-50%) scale(${scale});
      }
    `;
  }}

  /* 定義自定義容器查詢 */
  container-type: inline-size;
  container-name: preview-container;

  /* 添加內部容器以確保內容可以水平滾動 */
  & > div {
    min-width: 100%;
    width: ${props => props.previewwidth};
  }

  /* 自定義滾動條樣式 */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default PreviewContainer; 