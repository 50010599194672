import { CurrencyCode } from "@/enums/CurrecnyCode";
import { IItemSpecDto } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType, LogisticOrderLimitType } from "@/Templates/enums/templateEnums";
import {
    DeleteOutlined,
    DownOutlined,
    MenuOutlined,
    PlusOutlined,
    QuestionCircleTwoTone,
    UpOutlined,
} from "@ant-design/icons";
import type { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    Button,
    Col,
    Collapse,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Popover,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Typography,
    Tag,
    Tabs,
    Divider,
    Flex,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import React, { useContext, useEffect, useState } from "react";

const { TextArea } = Input;

export interface SpecsFormProps {
    previewOnly: boolean;
    onIsAllValid: (isAllValid: boolean) => void;
}

// Row 組件定義（用於 DnD 拖拽）
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
}

const RowDragSort: React.FC<Readonly<RowProps>> = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
        useSortable({
            id: props["data-row-key"],
        });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: "move",
        ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const SpecsForm: React.FC<SpecsFormProps> = ({ previewOnly, onIsAllValid }) => {
    const [form] = Form.useForm();
    const [unfilledSpecs, setUnfilledSpecs] = useState<number[]>([]);

    const { deviceType, messageApi } = useContext(GlobalContext);
    const { itemSpecs, setItemSpecs } = useContext(ItemContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);

    // ---- Quick Specs ----
    const [quickSpecs, setQuickSpecs] = useState([
        {
            key: Date.now(),
            name: translate("Size"),
            options: [translate("Large"), translate("Small")]
        },
        {
            key: Date.now() + 1,
            name: translate("Material"),
            options: []
        },
    ]);
    const [editingCell, setEditingCell] = useState<string>();

    // 用來控制哪些 row 是展開的
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

    // 修改 activeTab 的初始值，根據 itemSpecs 長度決定
    const [activeTab, setActiveTab] = useState<string>(() => {
        // 如果有多個規格，預設顯示多規格 Tab
        return itemSpecs.length > 1 ? 'multiple' : 'single';
    });

    // 添加 useEffect 來監聽 itemSpecs 的變化
    useEffect(() => {
        // 當 itemSpecs 有多個規格時，自動切換到多規格 Tab
        if (itemSpecs.length > 1 && activeTab === 'single') {
            setActiveTab('multiple');
        }
    }, [itemSpecs.length]);

    // 保存單規格和多規格的狀態
    const [singleSpecData, setSingleSpecData] = useState<IItemSpecDto | null>(null);
    const [multipleSpecsData, setMultipleSpecsData] = useState<IItemSpecDto[]>([]);

    // 提示文案
    const getSystemManageTooltip = () => {
        return i18nLanguage === "zh_TW"
            ? "由系統協助管理庫存"
            : "Inventory management assisted by the system";
    };
    const getQuickSpecTooltip = () => {
        return i18nLanguage === "zh_TW"
            ? "快速產生規格可以幫助你快速建立多個相關的產品規格。例如，你可以設「顏色」為一個選項，然後添加「紅色」、「藍色」、「綠色」作為子選項。"
            : "Quick spec generation helps you quickly create multiple related product specifications. For example, you can set 'Color' as an option and add 'Red', 'Blue', 'Green' as sub-options.";
    };
    const getLogisticOrderLimitTooltip = () => {
        return i18nLanguage === "zh_TW"
            ? "該商品的出貨物流限制，舉例若為冷凍出貨則僅能選擇冷凍出貨物流，以及會限制買家購物車僅能相限制的商品選項一起成立訂單"
            : "This is the shipping logistics restriction for the item. For example, if it's frozen shipping, only frozen shipping logistics can be selected, and it will limit the buyer's shopping cart to only include items with the same restriction in one order.";
    };

    // 產生隨機 BigInt ID
    const generateRandomBigInt = () => BigInt(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));

    // 驗證表單
    const validateFormFields = async () => {
        try {
            await form.validateFields();
            setUnfilledSpecs([]);
            onIsAllValid(true);
            return true;
        } catch (e: any) {
            const errorIndexes: number[] = [];
            if (e.errorFields) {
                e.errorFields.forEach((field: any) => {
                    const index = parseInt(field.name[1]);
                    if (!isNaN(index) && !errorIndexes.includes(index)) {
                        errorIndexes.push(index);
                    }
                });
            }
            setUnfilledSpecs(errorIndexes);
            onIsAllValid(false);
            return false;
        }
    };

    // 小延遲驗證
    const delayedValidation = () => {
        setTimeout(validateFormFields, 0);
    };

    // 添加 createNewSpec 函數
    const createNewSpec = (): IItemSpecDto => {
        return {
            id: generateRandomBigInt(),
            name: "",
            currency: CurrencyCode.TWD,
            sellPrice: 0,
            fixedPrice: 0,
            costPrice: 0,
            isManageStockAmount: false,
            stockAmount: 0,
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            customizeItemNo: "",
            customizeManufacturerNo: "",
            note: "",
            createdDate: "",
            alterDate: "",
            isDeleted: false,
            logisticOrderLimitType: LogisticOrderLimitType.None,
            sortIndex: itemSpecs.length,
        };
    };

    // 修改 handleAddNewSpec 函數
    const handleAddNewSpec = () => {
        const newSpecs = [...itemSpecs, createNewSpec()];
        setItemSpecs(newSpecs);
        setMultipleSpecsData(newSpecs);
    };

    const handleDeleteQuickSpec = (key: number) => {
        setQuickSpecs((prev) => prev.filter((spec) => spec.key !== key));
    };

    const handleDeleteItemSpec = (id: BigInt) => {
        if (itemSpecs.length <= 1) {
            messageApi.error(translate("At least one spec is required"));
            return;
        }
        setItemSpecs((prev) => prev.filter((spec) => spec.id !== id));
    };

    // 表單數值變化
    const handleFormValuesChange = async (
        changedValues: any,
        allValues: { specs: IItemSpecDto[] }
    ) => {
        const newSpecs = [...itemSpecs];

        if (changedValues.specs) {
            changedValues.specs.forEach((changedSpec: any, index: number) => {
                if (changedSpec) {
                    // 處理庫存為空或被刪除的情況
                    if (changedSpec.stockAmount === null || changedSpec.stockAmount === undefined || changedSpec.stockAmount === '') {
                        changedSpec.stockAmount = 0;
                    }

                    newSpecs[index] = {
                        ...newSpecs[index],
                        ...changedSpec,
                    };
                }
            });
        }
        setItemSpecs(newSpecs);
        setMultipleSpecsData(newSpecs);
        delayedValidation();
    };

    // QuickSpecs 相關
    const handleQuickSpecCellEdit = (
        value: string,
        record: any,
        dataIndex: string,
        optionIndex: number | null = null
    ) => {
        const newData = [...quickSpecs];
        const idx = newData.findIndex((item) => record.key === item.key);

        if (idx > -1) {
            const item = newData[idx];
            if (dataIndex === "options" && optionIndex !== null) {
                item[dataIndex][optionIndex] = value;
            } else {
                (item as any)[dataIndex] = value;
            }
            setQuickSpecs(newData);
        }
        setEditingCell("");
    };
    const addQuickSpec = () => {
        const newSpec = {
            key: Date.now(),
            name: "",
            options: [""],
        };
        setQuickSpecs([...quickSpecs, newSpec]);
    };
    const addQuickSpecOption = (record: any) => {
        const newData = [...quickSpecs];
        const idx = newData.findIndex((item) => item.key === record.key);
        if (idx > -1 && newData[idx].options.length < 5) {
            newData[idx].options.push("");
            setQuickSpecs(newData);
        }
    };
    const generateCombinations = (specs: { name: string; options: string[] }[]) => {
        // 過濾掉空選項
        const validSpecs = specs.filter(spec =>
            spec.name.trim() !== "" &&
            spec.options.some(opt => opt.trim() !== "")
        );

        // 如果沒有有效的規格，返回空數組
        if (validSpecs.length === 0) return [];

        // 處理單一規格的情況
        if (validSpecs.length === 1) {
            const spec = validSpecs[0];
            return spec.options
                .filter(option => option.trim() !== "")
                .map(option => [`${spec.name}: ${option}`]);
        }

        // 處理多規格的情況
        const combine = (arr: any[][]): any[][] => {
            if (arr.length === 0) return [[]];
            const restCombinations = combine(arr.slice(1));
            return arr[0].flatMap((d) => restCombinations.map((c) => [d, ...c]));
        };

        const optionsArray = validSpecs.map((spec) =>
            spec.options
                .filter((option) => option.trim() !== "")
                .map((option) => `${spec.name}: ${option}`)
        );

        return combine(optionsArray);
    };
    const handleGenerateSpecs = () => {
        const combinations = generateCombinations(quickSpecs);

        // 如果沒有生成任何組合，直接返回
        if (combinations.length === 0) return;

        const newSpecs = combinations
            .map((combination) => {
                // 對於單一規格，combination 是單個元素的數組
                const specName = Array.isArray(combination) ? combination.join(" - ") : combination;

                // 檢查是否已存在相同名稱的規格
                const existingSpec = itemSpecs.find((spec) => spec.name === specName);
                if (existingSpec) return null;

                return {
                    id: generateRandomBigInt(),
                    name: specName,
                    currency: CurrencyCode.TWD,
                    sellPrice: 0,
                    fixedPrice: 0,
                    costPrice: 0,
                    isManageStockAmount: false,
                    stockAmount: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    weight: 0,
                    customizeItemNo: "",
                    customizeManufacturerNo: "",
                    note: "",
                    createdDate: "",
                    alterDate: "",
                    isDeleted: false,
                    logisticOrderLimitType: LogisticOrderLimitType.None,
                    sortIndex: itemSpecs.length,
                } as IItemSpecDto;
            })
            .filter((spec) => spec !== null);

        if (newSpecs.length > 0) {
            setItemSpecs([...itemSpecs, ...(newSpecs as IItemSpecDto[])]);
            setMultipleSpecsData([...itemSpecs, ...(newSpecs as IItemSpecDto[])]);
            delayedValidation();
        }
    };

    // 初始化
    useEffect(() => {
        form.setFieldsValue({ specs: itemSpecs });
        delayedValidation();
        if (itemSpecs.length > 0) {
            if (activeTab === 'single') {
                setSingleSpecData(itemSpecs[0]);
            } else {
                setMultipleSpecsData(itemSpecs);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemSpecs]);

    // QuickSpecs 的欄位
    const quickSpecsColumns = [
        {
            title: "",
            key: "delete",
            width: "auto",
            render: (_: any, record: any) => (
                <Popconfirm
                    title={translate("Are you sure to delete this spec?")}
                    onConfirm={() => handleDeleteQuickSpec(record.key)}
                    okText={translate("Yes")}
                    cancelText={translate("No")}
                >
                    <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
                </Popconfirm>
            ),
        },
        {
            title: (
                <span>
                    <Popover
                        trigger="hover"
                        content={getQuickSpecTooltip()}
                        overlayStyle={{
                            maxWidth: deviceType === DeviceType.Mobile ? "300px" : "400px",
                        }}
                    >
                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                    </Popover>
                    {translate("Options")}
                </span>
            ),
            dataIndex: "name",
            key: "name",
            render: (text: string, record: any) => (
                <Input
                    value={text}
                    onChange={(e) => handleQuickSpecCellEdit(e.target.value, record, "name")}
                    onFocus={() => setEditingCell(`${record.key}-name`)}
                    autoFocus={editingCell === `${record.key}-name`}
                    placeholder={translate("e.g., Pattern")}
                />
            ),
        },
        {
            title: translate("Sub options"),
            dataIndex: "options",
            key: "options",
            render: (options: string[], record: any) => (
                <>
                    {options.map((option, index) => (
                        <Input
                            key={index}
                            value={option}
                            style={{ width: 100, marginRight: 8 }}
                            onChange={(e) => handleQuickSpecCellEdit(e.target.value, record, "options", index)}
                            onFocus={() => setEditingCell(`${record.key}-option-${index}`)}
                            autoFocus={editingCell === `${record.key}-option-${index}`}
                            placeholder={translate("e.g., Duck")}
                        />
                    ))}
                    {options.length < 5 && (
                        <Button size="small" onClick={() => addQuickSpecOption(record)}>
                            {translate("Add")}
                        </Button>
                    )}
                </>
            ),
        },
    ];

    /**
     * 只顯示「排序、規格名稱、刪除按鈕」在主表格
     * 其他欄位放進 expandedRowRender
     */
    const specColumns: ColumnsType<IItemSpecDto> = [
        {
            title: <Flex justify="center" align="center">{translate("Sort")}</Flex>,
            dataIndex: "sort",
            width: 80,
            fixed: "left",
            render: (_: any, __: IItemSpecDto, index: number) => (
                <Typography.Text>
                    {index + 1}
                    <MenuOutlined style={{ marginLeft: 8, cursor: "grab", color: "#999" }} />
                </Typography.Text>
            ),
        },
        {
            title: translate("SpecName"),
            dataIndex: "name",
            width: 250,
            key: "name",
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Space>
                    {!previewOnly && (
                        <Popconfirm
                            title={translate("Are you sure to delete this spec?")}
                            onConfirm={() => handleDeleteItemSpec(record.id)}
                            okText={translate("Yes")}
                            cancelText={translate("No")}
                        >
                            <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
                        </Popconfirm>
                    )}
                    <Form.Item
                        name={["specs", index, "name"]}
                        rules={[{ required: true, message: translate("is required") }]}
                        style={{ margin: 0, width: "100%" }}
                    >
                        <Input disabled={previewOnly} placeholder={translate("SpecName")} />
                    </Form.Item>
                </Space>
            ),
            align: "center",
        },
    ];

    // 拖拽用
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        })
    );

    const handleDragStart = (_: DragStartEvent) => {
        // 拖曳開始時，先全部收起
        setExpandedKeys([]);
    };

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            const oldIndex = itemSpecs.findIndex((i) => i.id.toString() === active.id);
            const newIndex = itemSpecs.findIndex((i) => i.id.toString() === over?.id);

            const newSpecs = arrayMove(itemSpecs, oldIndex, newIndex);
            setItemSpecs(newSpecs);
            setMultipleSpecsData(newSpecs);
        }
    };

    /**
     * 這裡重點：使用 Row、Col 進行排版
     * - 定價、售價同一行
     * - 系統管理、庫存同一行
     * - 物流訂單限制一整行
     * - 長、寬 & 高、重量各自一行（桌面），在手機 (xs) 時會佔同一行
     * - 客製化商品編號、客製化廠商編號、備註 各自一行
     */
    const expandedRowRender = (record: IItemSpecDto, index: number) => {
        return (
            <div style={{ background: "#fafafa", padding: 16 }}>
                <Row gutter={[16, 16]}>
                    {/* 定價、售價 */}
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translate("Fixed Price")}
                            name={["specs", index, "fixedPrice"]}
                            rules={[
                                { required: true, message: translate("is required") },
                                {
                                    validator: (_, value) => {
                                        if (value < 0) {
                                            return Promise.reject(translate("cannot be negative"));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translate("Sell Price")}
                            name={["specs", index, "sellPrice"]}
                            rules={[
                                { required: true, message: translate("is required") },
                                {
                                    validator: (_, value) => {
                                        if (value < 0) {
                                            return Promise.reject(translate("cannot be negative"));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* 系統管理、庫存 */}
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={
                                <span>
                                    <Popover content={getSystemManageTooltip()} trigger="hover">
                                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                                    </Popover>
                                    {translate("System Manage")}
                                </span>
                            }
                            name={["specs", index, "isManageStockAmount"]}
                            valuePropName="checked"
                        >
                            <Switch disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label={translate("Stock Amount")} name={["specs", index, "stockAmount"]}>
                            <InputNumber
                                min={0}
                                disabled={previewOnly || !record.isManageStockAmount}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* 物流訂單限制 */}
                    <Col xs={24}>
                        <Form.Item
                            label={
                                <span>
                                    <Popover
                                        trigger="hover"
                                        content={getLogisticOrderLimitTooltip()}
                                        overlayStyle={{
                                            maxWidth: deviceType === DeviceType.Mobile ? "300px" : "400px",
                                        }}
                                    >
                                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                                    </Popover>
                                    {translate("Logistic Order Limit")}
                                </span>
                            }
                            name={["specs", index, "logisticOrderLimitType"]}
                        >
                            <Select disabled={previewOnly} style={{ width: "100%" }}>
                                {Object.entries(LogisticOrderLimitType).map(([k, v]) => (
                                    <Select.Option key={k} value={k}>
                                        {translate(v)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* 長、寬、(分行) 高、重量；在手機 (xs) 時同一行，共四個 Col，各占 xs=6 */}
                <Row gutter={[16, 16]}>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Length")} (cm)`}
                            name={["specs", index, "length"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Width")} (cm)`}
                            name={["specs", index, "width"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Height")} (cm)`}
                            name={["specs", index, "height"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Weight")} (kg)`}
                            name={["specs", index, "weight"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* 客製化商品編號 */}
                    <Col xs={24}>
                        <Form.Item
                            label={translate("Customize Item No")}
                            name={["specs", index, "customizeItemNo"]}
                        >
                            <Input disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* 客製化廠商編號 */}
                    <Col xs={24}>
                        <Form.Item
                            label={translate("Customize Manufacturer No")}
                            name={["specs", index, "customizeManufacturerNo"]}
                        >
                            <Input disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* 備註 */}
                    <Col xs={24}>
                        <Form.Item label={translate("Note")} name={["specs", index, "note"]}>
                            <TextArea disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    };

    // 4. 自訂展開圖示 (DownOutlined / UpOutlined)
    const customExpandIcon: any = (props: any) => {
        const { expanded, onExpand, record } = props;
        return expanded ? (
            <UpOutlined
                style={{ fontSize: 14, marginRight: 8, cursor: "pointer" }}
                onClick={(e) => {
                    e.stopPropagation(); // 避免點擊圖示也觸發 onRow
                    onExpand(record, e);
                }}
            />
        ) : (
            <DownOutlined
                style={{ fontSize: 14, marginRight: 8, cursor: "pointer" }}
                onClick={(e) => {
                    e.stopPropagation();
                    onExpand(record, e);
                }}
            />
        );
    };

    // 當前行是否展開
    const isRowExpanded = (record: IItemSpecDto) => expandedKeys.includes(record.id.toString());

    // 切換展開 / 收起
    const toggleExpand = (record: IItemSpecDto) => {
        const key = record.id.toString();
        if (isRowExpanded(record)) {
            setExpandedKeys(expandedKeys.filter((k) => k !== key));
        } else {
            setExpandedKeys([...expandedKeys, key]);
        }
    };

    // onExpand callback
    const onExpandRow = (expanded: boolean, record: IItemSpecDto) => {
        const key = record.id.toString();
        if (!expanded) {
            // 收起
            setExpandedKeys(expandedKeys.filter((k) => k !== key));
        } else {
            // 展開
            setExpandedKeys([...expandedKeys, key]);
        }
    };

    // 1.點擊整行 => toggleExpand，但若點擊到 <input> 或 <button> 不要 toggleExpand
    const handleRowClick = (record: IItemSpecDto, e: React.MouseEvent) => {
        const tagName = (e.target as HTMLElement).tagName.toLowerCase();
        if (
            tagName === "input" ||
            tagName === "textarea" ||
            tagName === "button" ||
            (e.target as HTMLElement).closest(".ant-input") ||
            (e.target as HTMLElement).closest(".ant-select-selector") ||
            (e.target as HTMLElement).closest(".ant-btn") ||
            (e.target as HTMLElement).closest(".ant-popconfirm") ||
            (e.target as HTMLElement).closest(".ant-popover")
        ) {
            return; // 直接 return 不做展開/收起
        }
        toggleExpand(record);
    };

    const tableExpandable: TableProps<IItemSpecDto>["expandable"] = {
        columnTitle: <Flex justify="center" align="center">{translate("Expand Details")}</Flex>,
        expandedRowRender,
        expandedRowKeys: expandedKeys,
        onExpand: onExpandRow,
        expandIcon: customExpandIcon, // 自訂展開圖示
        rowExpandable: () => true,
    };

    // 多規格表單渲染 (修改後的版本)
    const renderMultipleSpecsForm = () => {
        return (
            <>
                <div style={{ marginBottom: 20 }}>
                    <Typography.Title level={5}>
                        <Popover
                            trigger="hover"
                            content={getQuickSpecTooltip()}
                            overlayStyle={{
                                maxWidth: deviceType === DeviceType.Mobile ? "300px" : "400px",
                            }}
                        >
                            <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                        </Popover>
                        {translate("Quick generate specs")}
                    </Typography.Title>

                    {quickSpecs.map((spec, specIndex) => (
                        <div key={spec.key} style={{ marginBottom: 16 }}>
                            <Row gutter={[16, 16]} align="middle">
                                <Col span={6}>
                                    <Input
                                        placeholder={translate("e.g., Color")}
                                        value={spec.name}
                                        onChange={(e) => {
                                            handleSpecNameChange(e.target.value, specIndex, spec);
                                        }}
                                    />
                                </Col>
                                <Col span={17}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder={spec.name ? translate("Press Enter to add option") : translate("Please enter spec name first")}
                                        value={spec.options}
                                        onChange={(values) => {
                                            // 如果規格名稱為空，不允許添加標籤
                                            if (!spec.name.trim()) {
                                                return;
                                            }
                                            handleQuickSpecOptionsChange(values, specIndex, spec);
                                        }}
                                        onKeyDown={(e) => {
                                            // 如果規格名稱為空，阻止所有輸入
                                            if (!spec.name.trim()) {
                                                e.preventDefault();
                                                return;
                                            }
                                            if (e.key === ' ') {
                                                e.preventDefault();
                                            }
                                            if (spec.options.length >= 7 && e.key !== 'Backspace' && e.key !== 'Delete') {
                                                e.preventDefault();
                                            }
                                        }}
                                        disabled={!spec.name.trim()} // 如果規格名稱為空，禁用輸入
                                        dropdownStyle={{ display: 'none' }}
                                        removeIcon={<DeleteOutlined style={{ color: 'red' }} />}
                                        tagRender={(props) => {
                                            const { label, closable, onClose } = props;
                                            return (
                                                <Tag
                                                    closable={closable}
                                                    onClose={(e) => {
                                                        e.preventDefault();
                                                        onClose();
                                                    }}
                                                    style={{ marginRight: 3 }}
                                                >
                                                    {label}
                                                </Tag>
                                            );
                                        }}
                                    />
                                </Col>
                                {quickSpecs.length > 1 && (
                                    <Col span={1} style={{ textAlign: 'right' }}>
                                        <Button
                                            type="text"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => {
                                                setQuickSpecs(quickSpecs.filter((_, index) => index !== specIndex));
                                                handleGenerateSpecs();
                                            }}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    ))}

                    <Space style={{ marginBottom: 20 }}>
                        {quickSpecs.length < 3 && (
                            <Button
                                onClick={() => setQuickSpecs([...quickSpecs, { key: Date.now(), name: "", options: [] }])}
                                icon={<PlusOutlined />}
                            >
                                {translate("Add spec")}
                            </Button>
                        )}
                    </Space>
                </div>

                <Divider />

                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                    onDragStart={handleDragStart}
                    onDragEnd={onDragEnd}
                >
                    <SortableContext
                        items={itemSpecs.map((i) => i.id.toString())}
                        strategy={verticalListSortingStrategy}
                    >
                        <Table<IItemSpecDto>
                            components={{
                                body: { row: RowDragSort },
                            }}
                            columns={specColumns}
                            dataSource={itemSpecs}
                            rowKey={(record) => record.id.toString()}
                            pagination={false}
                            style={{ marginTop: 20 }}
                            bordered
                            expandable={tableExpandable}
                            onRow={(record) => ({
                                onClick: (e) => handleRowClick(record, e),
                            })}
                        />
                    </SortableContext>
                </DndContext>

                {!previewOnly && (
                    <Button
                        onClick={handleAddNewSpec}
                        type="dashed"
                        style={{ marginTop: 16, width: "100%" }}
                        icon={<PlusOutlined />}
                    >
                        {translate("New spec")}
                    </Button>
                )}
            </>
        );
    };

    // Tab 切換處理
    const handleTabChange = (key: string) => {
        if (key === "single") {
            // 保存多規格數據
            setMultipleSpecsData([...itemSpecs]);

            // 使用單規格數據，並將名稱設為"無規格"
            const specToUse = {
                ...(singleSpecData || itemSpecs[0] || {
                    id: generateRandomBigInt(),
                    currency: CurrencyCode.TWD,
                    sellPrice: 0,
                    fixedPrice: 0,
                    isManageStockAmount: false,
                    stockAmount: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    weight: 0,
                    customizeItemNo: "",
                    customizeManufacturerNo: "",
                    note: "",
                    createdDate: "",
                    alterDate: "",
                    isDeleted: false,
                    logisticOrderLimitType: LogisticOrderLimitType.None,
                    sortIndex: 0,
                }),
                name: translate("No Spec") // 強制設置名稱為"無規格"
            };

            setItemSpecs([specToUse]);
        } else {
            // 保存單規格數據
            setSingleSpecData(itemSpecs[0]);

            // 恢復多規格數據
            if (multipleSpecsData.length > 0) {
                setItemSpecs(multipleSpecsData);
            }
        }
        setActiveTab(key);
    };

    // 單規格表單渲染
    const renderSingleSpecForm = () => {
        const singleSpec = {
            ...(itemSpecs[0] || {
                id: generateRandomBigInt(),
                currency: CurrencyCode.TWD,
                sellPrice: 0,
                fixedPrice: 0,
                isManageStockAmount: false,
                stockAmount: 0,
                length: 0,
                width: 0,
                height: 0,
                weight: 0,
                customizeItemNo: "",
                customizeManufacturerNo: "",
                note: "",
                createdDate: "",
                alterDate: "",
                isDeleted: false,
                logisticOrderLimitType: LogisticOrderLimitType.None,
                sortIndex: 0,
            }),
            name: translate("No Spec") // 強制設置名稱為"無規格"
        };

        return (
            <div style={{ padding: '20px 0' }}>
                <Row gutter={[16, 16]}>
                    {/* 定價、售價 */}
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translate("Fixed Price")}
                            name={["specs", 0, "fixedPrice"]}
                            rules={[
                                { required: true, message: translate("is required") },
                                {
                                    validator: (_, value) => {
                                        if (value < 0) {
                                            return Promise.reject(translate("cannot be negative"));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={translate("Sell Price")}
                            name={["specs", 0, "sellPrice"]}
                            rules={[
                                { required: true, message: translate("is required") },
                                {
                                    validator: (_, value) => {
                                        if (value < 0) {
                                            return Promise.reject(translate("cannot be negative"));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* 系統管理、庫存 */}
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={
                                <span>
                                    <Popover content={getSystemManageTooltip()} trigger="hover">
                                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                                    </Popover>
                                    {translate("System Manage")}
                                </span>
                            }
                            name={["specs", 0, "isManageStockAmount"]}
                            valuePropName="checked"
                        >
                            <Switch disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label={translate("Stock Amount")} name={["specs", 0, "stockAmount"]}>
                            <InputNumber
                                min={0}
                                disabled={previewOnly || !singleSpec.isManageStockAmount}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* 物流訂單限制 */}
                    <Col xs={24}>
                        <Form.Item
                            label={
                                <span>
                                    <Popover
                                        trigger="hover"
                                        content={getLogisticOrderLimitTooltip()}
                                        overlayStyle={{
                                            maxWidth: deviceType === DeviceType.Mobile ? "300px" : "400px",
                                        }}
                                    >
                                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                                    </Popover>
                                    {translate("Logistic Order Limit")}
                                </span>
                            }
                            name={["specs", 0, "logisticOrderLimitType"]}
                        >
                            <Select disabled={previewOnly} style={{ width: "100%" }}>
                                {Object.entries(LogisticOrderLimitType).map(([k, v]) => (
                                    <Select.Option key={k} value={k}>
                                        {translate(v)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Length")} (cm)`}
                            name={["specs", 0, "length"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Width")} (cm)`}
                            name={["specs", 0, "width"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Height")} (cm)`}
                            name={["specs", 0, "height"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={12} md={12}>
                        <Form.Item
                            label={`${translate("Weight")} (kg)`}
                            name={["specs", 0, "weight"]}
                            rules={[{ required: true, message: translate("is required") }]}
                        >
                            <InputNumber min={0} disabled={previewOnly} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Form.Item
                            label={translate("Customize Item No")}
                            name={["specs", 0, "customizeItemNo"]}
                        >
                            <Input disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Form.Item
                            label={translate("Customize Manufacturer No")}
                            name={["specs", 0, "customizeManufacturerNo"]}
                        >
                            <Input disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Form.Item
                            label={translate("Note")}
                            name={["specs", 0, "note"]}
                        >
                            <TextArea disabled={previewOnly} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    };

    // 添加處理規格名稱變更的函數
    const handleSpecNameChange = (value: string, specIndex: number, spec: any) => {
        // 如果有原有的選項，需要先清除相關的規格組合
        if (spec.options.length > 0) {
            const oldSpecName = spec.name;
            setItemSpecs(prevSpecs => {
                return prevSpecs.filter(specItem => {
                    // 檢查是否包含舊的規格名稱
                    const parts = specItem.name.split(' - ');
                    return !parts.some(part => part.startsWith(`${oldSpecName}:`));
                });
            });

            // 同時更新 multipleSpecsData
            setMultipleSpecsData(prevSpecs => {
                return prevSpecs.filter(specItem => {
                    const parts = specItem.name.split(' - ');
                    return !parts.some(part => part.startsWith(`${oldSpecName}:`));
                });
            });
        }

        // 更新規格名稱並清除選項
        const newQuickSpecs = [...quickSpecs];
        newQuickSpecs[specIndex] = {
            ...newQuickSpecs[specIndex],
            name: value,
            options: [] // 清除選項
        };
        setQuickSpecs(newQuickSpecs);
    };

    // 修改 handleQuickSpecOptionsChange 函數，添加 console.log 來幫助調試
    const handleQuickSpecOptionsChange = (values: string[], specIndex: number, spec: any) => {
        console.log('Previous options:', spec.options);
        console.log('New values:', values);

        // 限制最多7個tag
        const limitedValues = values.slice(0, 7);

        // 找出被移除的選項
        const removedOptions = spec.options.filter(opt => !limitedValues.includes(opt));
        console.log('Removed options:', removedOptions);

        // 更新 quickSpecs
        const newQuickSpecs = [...quickSpecs];
        newQuickSpecs[specIndex].options = limitedValues;
        setQuickSpecs(newQuickSpecs);

        // 如果有選項被移除，需要移除相關的規格組合
        if (removedOptions.length > 0) {
            const specName = spec.name;
            setItemSpecs(prevSpecs => {
                const filteredSpecs = prevSpecs.filter(spec => {
                    return !removedOptions.some(removedOption =>
                        spec.name.includes(`${specName}: ${removedOption}`)
                    );
                });
                console.log('Filtered specs:', filteredSpecs);
                return filteredSpecs;
            });

            // 同時更新 multipleSpecsData
            setMultipleSpecsData(prevSpecs => {
                return prevSpecs.filter(spec => {
                    return !removedOptions.some(removedOption =>
                        spec.name.includes(`${specName}: ${removedOption}`)
                    );
                });
            });
        }

        // 如果規格名稱和選項都有值，就自動生成新的組合
        if (spec.name && limitedValues.length > 0) {
            handleGenerateSpecs();
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Form
                style={{ width: "90%" }}
                form={form}
                name="dynamic_spec_form"
                onValuesChange={(changedValues, allValues) => {
                    handleFormValuesChange(changedValues, allValues);
                    // 更新對應的數據存儲
                    if (activeTab === 'single') {
                        setSingleSpecData(allValues.specs[0]);
                    } else {
                        setMultipleSpecsData(allValues.specs);
                    }
                }}
                layout="vertical"
                autoComplete="off"
            >
                <Tabs
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    items={[
                        {
                            key: 'single',
                            label: translate("Single Spec"),
                            children: renderSingleSpecForm(),
                        },
                        {
                            key: 'multiple',
                            label: translate("Multiple Specs"),
                            children: renderMultipleSpecsForm(),
                        },
                    ]}
                />
            </Form>
        </div>
    );
};

export default SpecsForm;
