import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { IDynamicComponent } from '@/Templates/interfaces/templatesInterfaces';
import { TemplateProps } from '@/Templates/TemplateProps';
import dynamic from 'next/dynamic';
import React, { Suspense } from 'react';

interface DynamicPageTemplateProps extends TemplateProps {
    components: IDynamicComponent[];
}

const DynamicPageTemplate: React.FC<DynamicPageTemplateProps> = ({ components, ...props }) => {
    if (!components?.length) {
        return null;
    }

    return (
        <>
            {components.map((component, index) => {
                const ComponentTemplate = dynamic<TemplateProps>(() =>
                    import(`@/Templates/Shop/Components/${component.name}/Template_${component.style}.tsx`),
                    {
                        loading: () => <LoadingComponent />,
                        ssr: false
                    }
                );
                return (
                    <div
                        key={`${component.id.toString()}-${component.name}-${index}`} style={{ width: '100%' }}>
                        <Suspense fallback={<></>}>
                            <ComponentTemplate
                                {...props}
                                config={{ ...component.values }}
                            />
                        </Suspense>
                    </div>
                );
            })}
        </>
    );
};

export default DynamicPageTemplate; 