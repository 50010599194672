import { IQueryThirdPartyOrdersRequest } from "@/interfaces/Requests/Requests";
import { useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi, useQueryThirdPartyOrdersApi } from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import * as antdProForm from '@ant-design/pro-form';
import * as antd from "antd";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";

const OrderList = () => {
    const { merchantId } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const abortController = useRef<AbortController | null>(null);
    const [form] = antd.Form.useForm();
    const [searchParams, setSearchParams] = useState<IQueryThirdPartyOrdersRequest>({
        page: 1,
        pageSize: 10
    });

    const getOrderMutation = useMutation(
        async (request: IQueryThirdPartyOrdersRequest) =>
            await useQueryThirdPartyOrdersApi(request, abortController.current?.signal)
    );

    const getProviderSettings = useMutation(async () => await useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi(abortController.current?.signal));

    useEffect(() => {
        abortController.current = new AbortController();
        getOrderMutation.mutate(searchParams);
        getProviderSettings.mutate();
        return () => {
            abortController.current?.abort();
        };
    }, [searchParams]);

    // 查詢過濾器
    const memoizedQueryFilter = useMemo(() => (
        <antdProForm.QueryFilter
            form={form}
            style={{ border: '1px solid #f0f0f0', borderRadius: '8px', marginBottom: '24px' }}
            onFinish={async (values) => {
                console.log(values);
                const params: IQueryThirdPartyOrdersRequest = {
                    ...searchParams,
                    page: 1,
                    startDate: values.dateRange?.[0]
                        ? new Date(`${values.dateRange[0]} 00:00:00`).toISOString()
                        : undefined,
                    endDate: values.dateRange?.[1]
                        ? new Date(`${values.dateRange[1]} 23:59:59`).toISOString()
                        : undefined,
                };

                values.orderNo && (params.thirdPartyOrderIds = [values.orderNo]);
                getOrderMutation.mutate(params);
            }}
            loading={getOrderMutation.isLoading}
            onReset={() => {
                form.resetFields();
                getOrderMutation.mutate({
                    page: 1,
                    pageSize: 10
                });
            }}
        >
            <antdProForm.ProFormText
                name="orderNo"
                label={translate('ThirdParty Order ID')}
                placeholder={translate('ThirdParty Order ID')}
            />
            <antdProForm.ProFormDateRangePicker
                name="dateRange"
                label={translate('CreatedTime')}
            />
            <antdProForm.ProFormSelect
                name="status"
                label={translate('Status')}
                options={[
                    { label: translate('All'), value: '' },
                    { label: translate('Pending'), value: 'Pending' },
                    { label: translate('Completed'), value: 'Completed' },
                    { label: translate('Cancelled'), value: 'Cancelled' },
                ]}
            />
            {
                (getProviderSettings.data?.result && getProviderSettings.data?.result.length > 0) &&
                <antdProForm.ProFormSelect
                    name="providerIds"
                    mode="multiple"
                    label={translate('Provider')}
                    options={
                        getProviderSettings.data?.result.map(vm => ({ label: vm.providerName, value: vm.thirdPartySettingId.toString() })) || []
                    }
                />
            }
        </antdProForm.QueryFilter>
    ), [form, getOrderMutation.isLoading, translate, searchParams]);

    // 訂單列表
    const memoizedOrderList = useMemo(() => {
        if (getOrderMutation.isLoading) {
            return <antd.Spin size="large" style={{ display: 'flex', justifyContent: 'center', margin: '50px' }} />;
        }

        return (
            <antd.List
                itemLayout="horizontal"
                dataSource={getOrderMutation.data?.result?.data || []}
                pagination={{
                    align: 'center',
                    position: 'bottom',
                    current: searchParams.page,
                    pageSize: searchParams.pageSize,
                    total: getOrderMutation.data?.result?.totalCount || 0,
                    onChange: (page, pageSize) => {
                        setSearchParams(prev => ({
                            ...prev,
                            page,
                            pageSize
                        }));
                    }
                }}
                renderItem={(order) => (
                    <antd.List.Item
                        actions={[
                            <antd.Button key="view" type="link">
                                {translate('View Details')}
                            </antd.Button>
                        ]}
                    >
                        <antd.List.Item.Meta
                            title={
                                <antd.Space>
                                    <span>{translate('Order No')}: {order.id.toString()}</span>
                                </antd.Space>
                            }
                            description={
                                <antd.Space direction="vertical">
                                    <span>{translate('Order Date')}: {new Date(order.createdDate).toLocaleString()}</span>
                                </antd.Space>
                            }
                        />
                    </antd.List.Item>
                )}
            />
        );
    }, [getOrderMutation.data, getOrderMutation.isLoading, searchParams, translate]);

    return (
        <div style={{ padding: '24px' }}>
            {memoizedQueryFilter}
            {memoizedOrderList}
        </div>
    );
};

export default OrderList;